import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { CacheService } from '../../services/cache.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { UserIdleService } from 'angular-user-idle';
import { map } from 'rxjs/operators';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [Config, AuditTrail],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('swalSession', { static: false }) private swalSession: SwalComponent;
  public data: any = {};
  linkMenu: any = {};
  public loadingData: boolean = false;
  errorMessage: string;
  idle: number;
  timeout: number;
  ping: number;
  timeSession: any = '';
  hideBrowserInformation: string = 'false';

  //Change Version below
  version: string = 'V 2.22.1';
  currentYear: any = new Date().getFullYear(); 
  public whatsappNo :any = '';

  constructor(
    private cnf: Config, 
    private auditTrail: AuditTrail,
    private http: HttpClient,
    private cacheService: CacheService,
    private userIdle: UserIdleService, 
    private router: Router, 
    private localService: LocalService) {
  }

  ngOnInit() {
    this.hideBrowserInformation = (this.localService.getJsonValue('hideBrowserInformation') ? this.localService.getJsonValue('hideBrowserInformation') : 'false');
    this.cacheService.get('/assets/config/menu.json', this.loadLinkMenu()).subscribe((res: any) => {
      this.linkMenu = res;
    });
    window.scrollTo(0, 0);
    this.cacheService.get(this.cnf.lang + '/contact', this.loadData()).subscribe((res: any) => {
      this.loadingData = true;
      if (res.status == 100) {
        this.data = JSON.parse(this.cnf.decryptData(res.datas));
        this.whatsappNo = this.data.whatsapp.replace(/ /g, '');
      }
    });
    if (this.cnf.token) {
      // Check token first
      this.loadToken().subscribe((res: any) => {
        if (res.status != 100) {
          this.doLogout();
        }
      });

      // Check user idle
      this.idle = this.userIdle.getConfigValue().idle;
      this.timeout = this.userIdle.getConfigValue().timeout;
      this.ping = this.userIdle.getConfigValue().ping;
      this.cacheService.get(this.cnf.lang + '/user_frontend', this.loadSession()).subscribe((res: any) => {
        this.loadingData = true;
        if (res.status == 100) {
          this.userIdle.stopWatching();
          this.timeSession = res.datas;
          this.idle = this.timeSession;
          this.userIdle.setConfigValues({ idle: this.idle, timeout: this.timeSession - 10, ping: 0 });
          //this.userIdle.idle = this.timeSession;
          //this.userIdle.timeout = this.timeSession - 10;
          this.idle = this.userIdle.getConfigValue().idle;
          //this.userIdle.stopWatching();
          this.userIdle.startWatching();
          this.userIdle.onTimerStart().subscribe(count => {
            if (count == 1) {
              this.swalSession.fire();
            }
          });
          this.userIdle.onTimeout().subscribe(() => {
            this.doLogout();
          });

          this.loadToken().subscribe((res: any) => {
            if (res.status != 100) {
              this.doLogout();
            }
          });
        }
      });
    }
  }

  loadData() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(this.cnf.URLWS + '/contact/frontend/all', paramsObject)
      .pipe(map((response: Response) => response));
  }

  loadSession() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(this.cnf.URLWSNonPublicArea + '/user_frontend/getfrontendtime', paramsObject)
      .pipe(map((response: Response) => response));
  }

  loadToken() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(this.cnf.URLWSNonPublicArea + '/user_frontend/checkToken', paramsObject)
      .pipe(map((response: Response) => response));
  }

  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.swalError.fire();
    }, 500);
  }

  loadLinkMenu() {
    return this.http.get('/assets/config/menu.json')
      .pipe(map((response: Response) => response));
  }

  doLogout() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
    this.http.post(url, paramsObject).subscribe(res => {
      this.auditTrail.saveLog("Home", "Logout");
      this.cnf.clearLocalStorage();
      this.router.navigate(['/']);
      location.reload();
    });
  }

  doResetTime() {
    this.userIdle.resetTimer();
  }

  closeBrowserInformation() {
    this.localService.setJsonValue("hideBrowserInformation", "true");
    this.hideBrowserInformation = "true";
  }
}
