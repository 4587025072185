import { Component, OnInit } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { PaymentService } from '../../services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTRequest } from '../../models/restrequest.model';
import { RESTResponse } from '../../models/restresponse';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-payment-va',
  templateUrl: './payment-va.component.html',
  styleUrls: ['./payment-va.component.scss']
})
export class PaymentVaComponent implements OnInit {
  params: any = '';
  public userId: any = '';
  policyDetailData: any = {};
  loadingPolicy = false;
  loadingRequestCC = false;
  premiumOther: Number = 0;
  premiumBCA: Number = 0;
  stamp: Number = 0;
  adminBCA: Number = 0;
  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private cnf: Config,
    private http: HttpClient
  ) {
    this.route.params.subscribe(params => (this.params = params));
  }
  ngOnInit() {
    this.loadingPolicy = true;
    this.paymentService
      .getPolisDetail(this.params.policyNo)
      .subscribe((response: any) => {
        const res: RESTResponse<any> = response.body;
        if (res.success) {
          this.policyDetailData = res.data[0];

          if (!this.policyDetailData.homeAddress1) {
            this.policyDetailData.homeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.homeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.homeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.homeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.homeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.homeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.homeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.homeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.homePostCode = this.policyDetailData.correspondencePostCode;
          } else if (!this.policyDetailData.officeAddress1) {
            this.policyDetailData.officeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.officeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.officeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.officeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.officeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.officeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.officeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.officeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.officePostCode = this.policyDetailData.correspondencePostCode;
          }

          let d = new Date();
          var g1 = new Date(d.getFullYear(), d.getMonth(), d.getDate());
          // (YYYY, MM, DD) 
          var g2 = new Date(2021, 1, 1);

          if (this.policyDetailData.totalPremium <= 5000000) {
            this.stamp = 0;
            this.adminBCA = 3000;
          } else if (this.policyDetailData.totalPremium > 5000000) {
            this.stamp = 10000;
            this.adminBCA = 13000;
          }


          this.premiumOther = +this.policyDetailData.totalPremium + +this.stamp;
          this.premiumBCA = +this.policyDetailData.totalPremium + +this.adminBCA;
        } else {
          this.router.navigate(['/404']);
        }
      })
      .add(() => {
        this.loadingPolicy = false;
      });
  }
  onRequestCC() {
    this.loadingRequestCC = true;
    setTimeout(() => {
      this.loadingRequestCC = false;
    }, 5000);
  }
}
