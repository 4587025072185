import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { PersonalData } from '../../models/personal-data.model';
import { CustomerService } from '../../services/customer.service';
import { RESTResponse } from '../../models/restresponse';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  providers: [Config, AuditTrail]
})
export class PersonalDataComponent implements OnInit {
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  errorMessage: string = '';
  token: any;
  public data: any = {};
  public userId: any = '';
  attoken: string = '';
  projectName: string = 'personalData';

  personalData: PersonalData = new PersonalData();
  loadingData: boolean = true;
  constructor(
    private cnf: Config,
    private router: Router,
    private http: HttpClient,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private _location: Location,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.auditTrail.saveLog('My Data Personal Infromation', 'Open');
    this.titleService.setTitle(this.cnf.prefixTitle + 'Personal Data' + this.cnf.postfixTitle);

    this.loadingData = true;
    this.customerService.personalDataSubject.subscribe(
      personalData => {
        if (!this.customerService.checkEmpty(personalData)) {
          this.personalData = personalData;
          this.personalDataAssertation();
          this.loadingData = false;
        }
      },
      err => {
        this.loadingData = false;
        console.log('ERR', err);
      }
    );

  }

  /**
   * @desc Load Token
   */
  loadToken() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/checkToken', paramsObject)
      .pipe(map((response: Response) => response));
  }

  loadData() {

    // check token
    this.customerService.getPersonalData(this.userId, this.cnf.token).subscribe((res: any) => {
      if (res.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
      }
      this.loadNotification().subscribe((res: any) => {
        const response: RESTResponse<PersonalData> = res.body;
        if (response.success) {
          this.personalData = response.data;
          this.personalDataAssertation();
        }
      });
    });
    if (this.token === '') {
      this.router.navigate(['/']);
    } else {
      this.loadToken().subscribe((res: any) => {
        // console.log(res);
        if (res.status != 100) {
          this.showErrorMessage(res.message);
          setTimeout(() => {
            this.doLogout();
          }, 500);
        }
      });
    }




  }

  /**
   * @desc show error swal-alert
   * @param message
   */
  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.swalError.fire();
    }, 500);
  }


  /**
 * @desc logout action
 */
  doLogout() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
    this.http.post(url, paramsObject).subscribe(res => {
      this.auditTrail.saveLog('Home', 'Logout');
      this.cnf.clearLocalStorage();
      this.router.navigate(['/']);
      location.reload();
    });
  }

  /**
   * @desc Load Notification
   */
  loadNotification() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/inbox_blast/frontend/notification/' + this.userId, paramsObject)
      .pipe(map((response: Response) => response));
  }

  personalDataAssertation() {
    if (this.personalData.birthday) {
      const date = moment(this.personalData.birthday).isValid();
      if (date) {
        this.personalData.birthday = moment(this.personalData.birthday).format('DD MMM YYYY');
      }
    }
    // if (!this.personalData.homeAddress1) {
    //   this.personalData.homeAddress1 = this.personalData.correspondenceAddress1;
    //   this.personalData.homeAddress2 = this.personalData.correspondenceAddress2;
    //   this.personalData.homeAddress3 = this.personalData.correspondenceAddress3;
    //   this.personalData.homeAddress4 = this.personalData.correspondenceAddress4;
    //   this.personalData.homeAddress5 = this.personalData.correspondenceAddress5;
    //   this.personalData.homeAddress6 = this.personalData.correspondenceAddress6;
    //   this.personalData.homeAddress7 = this.personalData.correspondenceAddress7;
    //   this.personalData.homeState = this.personalData.correspondenceState;
    //   this.personalData.homePostCode = this.personalData.correspondencePostCode;
    // }
    // if (!this.personalData.officeAddress1) {
    //   this.personalData.officeAddress1 = this.personalData.correspondenceAddress1;
    //   this.personalData.officeAddress2 = this.personalData.correspondenceAddress2;
    //   this.personalData.officeAddress3 = this.personalData.correspondenceAddress3;
    //   this.personalData.officeAddress4 = this.personalData.correspondenceAddress4;
    //   this.personalData.officeAddress5 = this.personalData.correspondenceAddress5;
    //   this.personalData.officeAddress6 = this.personalData.correspondenceAddress6;
    //   this.personalData.officeAddress7 = this.personalData.correspondenceAddress7;
    //   this.personalData.officeState = this.personalData.correspondenceState;
    //   this.personalData.officePostCode = this.personalData.correspondencePostCode;
    // }
  }

  backClicked() {
    this._location.back();
  }
}
