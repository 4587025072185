import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as CryptoJS from 'crypto-js';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Contact } from '../../models/contact.model';
import { Marker } from '../../models/marker';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [Config, AuditTrail],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
  @ViewChild('swal', { static: false }) private swal: SwalComponent;

  public loadingButton: boolean = false;
  public data: any = {};
  public loadingData: boolean = false;
  public preview: any = '';
  public token: any = '';
  public contact: Contact;
  public swalData: any = {};
  attoken: string = '';
  projectName: string = 'agent';

  public zoom: number = 15;
  public lat: number;
  public lng: number;
  public markers: Marker[];
  googleRecaptchaKey: string;
  public contactLocation: any = [];

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private http: HttpClient,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.route.queryParams.subscribe(params => {
      this.preview = params['preview'];
      this.token = params['token'];
    });
  }

  ngOnInit() {
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.contact = new Contact({
      name: '',
      email: '',
      category: '',
      phone: '',
      subject: '',
      message: '',
      captcha: '',
      location: ''
    });

    this.loadData().subscribe((res: any) => {
    });

    // get token
    this.cnf.getAttoken().subscribe((res: any) => {
      if (res.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
      }

      this.cacheService.get(this.cnf.lang + '/contact', this.loadData()).subscribe((res: any) => {
        this.loadingData = true;
        if (res.status == 100) {
          this.data = JSON.parse(this.cnf.decryptData(res.datas));
          this.titleService.setTitle(this.cnf.prefixTitle + this.data.title + this.cnf.postfixTitle);

          this.lat = Number(this.data.lat);
          this.lng = Number(this.data.long);
          this.markers = [
            {
              lat: this.data.lat,
              lng: this.data.long
            }
          ];
        }
      });
      this.cacheService
        .get(this.cnf.lang + '/agent_location_data/', this.loadDataAgentLocation())
        .subscribe((res: any) => {
          if (res.status == 100) {
            let decryptData = JSON.parse(this.cnf.decryptData(res.datas));
            this.contactLocation = decryptData[0].agent;
          }
        });
    });
  }

  resolved(captchaResponse: string) {
    this.contact.captcha = captchaResponse;
  }

  /**
   * @desc load required data
   */
  loadData() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let url = this.cnf.URLWS + '/contact';
    if (this.preview) {
      params = params.append('token', this.token);
      url = url + '/preview/' + this.preview;
    } else {
      url = url + '/frontend/all';
    }
    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(url, paramsObject).pipe(map((response: Response) => response));
  }

  /**
   * @desc load required agent data
   */
  loadDataAgentLocation() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('attoken', this.attoken);
    params = params.append('search', '');

    let paramsObject = this.cnf.convertPostData(params);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/agent/data/location', paramsObject)
      .pipe(map((response: Response) => response));
  }

  /**
   * @desc on submit function
   */
  onInternalSubmit(form: any) {
    if (this.contact.category.toLowerCase().indexOf('prod') == -1) {
      this.contact.location = '';
    }
    this.loadingButton = true;

    this.cnf.showRecaptchaBadge();
    this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'contact', captchaToken => {
      this.contact.captcha = captchaToken;

      let data = {
        email: this.contact.email,
        phone: this.contact.phone,
        message: this.contact.message,
        categoryId: this.contact.category,
        subject: this.contact.subject,
        name: this.contact.name,
        captcha: this.contact.captcha,
        location: this.contact.location
      };
      let params = new HttpParams();
      params = params.append('appId', this.cnf.appid);
      params = params.append('appKey', this.cnf.appkey);

      let paramsObject = this.cnf.convertPostData(params, data);

      this.http.post(this.cnf.URLWSNonPublicArea + '/inbox/frontend/contactus', paramsObject).subscribe((res: any) => {
        this.loadingButton = false;

        if (res.status == 100) {
          let message = this.translate.instant('_success_contact_us_send');

          this.swalData = {
            title: 'Success',
            message: message,
            type: 'success'
          };
          this.showSwal();

          form.resetForm();
          this.contact = new Contact({
            name: '',
            email: '',
            category: '',
            phone: '',
            subject: '',
            message: '',
            captcha: '',
            location: ''
          });
        } else {
          let oops = this.translate.instant('_oops');
          let message = this.translate.instant(res.message);
          this.swalData = {
            title: oops,
            message: message,
            type: 'error'
          };
          this.showSwal();
        }
      });
    });
  }

  showSwal() {
    setTimeout(() => {
      this.swal.fire();
    }, 100);
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }
}
