import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';


import { LocalService } from '../../local.service';



@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
  providers: [Config, AuditTrail]
})
export class AgentsComponent implements OnInit {
  @ViewChild('t', {static: false}) tab;

  public search: string = "";
  params: any;
  currentPage: any = 1;
  perPage: any = 15;
  totalPages: any;
  total: any;
  previousPage: any;
  location: any;
  agentLocationData: any = [];
  agentLocationContentData: any = [];
  activeIdString: string = '';
  public loadingData: boolean = false;
  public userId: any = '';

  attoken: string = '';
  projectName: string = 'agent';
  lastSearch: string = '';

  constructor(private router: Router, private localService: LocalService,private route: ActivatedRoute, private cnf: Config, private auditTrail: AuditTrail, private titleService: Title, private http: HttpClient, private cacheService: CacheService) {

  }

  ngOnInit() {
    this.titleService.setTitle(this.cnf.prefixTitle + 'Agents' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');

    this.cnf.getAttoken().subscribe((res: any) => {
      if (res.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
      }

      this.cacheService.get(this.cnf.lang + '/agent_location_data/', this.loadDataAgentLocation()).subscribe((res: any) => {
        if (res.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.datas));
          this.agentLocationData = decryptData[0].agent;

          for (let i in this.agentLocationData) {
            this.agentLocationContentData[this.agentLocationData[i]] = [];
          }

          this.location = this.agentLocationData[0];
          let location = this.location;
          this.loadDataAgentByLocation(location, this.search).subscribe((res: any) => {
            if (res.status == 100) {
              let decryptData = JSON.parse(this.cnf.decryptData(res.datas));

              this.agentLocationContentData[location] = decryptData[0].agent;
              this.total = decryptData[0].total;
              this.totalPages = decryptData[0].total_page;
            }

            this.loadingData = true;
          });
        }
      });
    });
  }

  loadAgentLocationContent(location) {
    if (this.location != location) {
      this.loadingData = false;
      this.previousPage = 1;
      this.currentPage = 1;
      this.location = location;

      this.cnf.getAttoken().subscribe((res: any) => {
        if (res.status == 100) {
          this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
        }
        this.loadDataAgentByLocation(location, this.search).subscribe((res: any) => {
          this.loadingData = true;
          if (res.status == 100) {
            let decryptData = JSON.parse(this.cnf.decryptData(res.datas));

            this.agentLocationContentData[this.location] = decryptData[0].agent;
            this.total = decryptData[0].total;
            this.totalPages = decryptData[0].total_page;
          } else {
            this.agentLocationContentData[this.location] = [];
          }
        });
      });
    }
  }

  loadPage(page: string, location) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.currentPage = page;

      this.agentLocationContentData[location] = [];

      this.loadingData = false;
      this.cnf.getAttoken().subscribe((res: any) => {
        if (res.status == 100) {
          this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
        }
        this.loadDataAgentByLocation(location, this.search).subscribe((res: any) => {
          this.loadingData = true;
          if( res.status == 100 ) {
            let decryptData = JSON.parse(this.cnf.decryptData(res.datas));
  
            this.agentLocationContentData[location] = decryptData[0].agent;
          }
        });
      });
    }
  }

  onSearch() {
    if (this.lastSearch != this.search) {
      this.lastSearch = this.search;
      this.loadingData = false;
      this.previousPage = 1;
      this.currentPage = 1;


      this.cnf.getAttoken().subscribe((res: any) => {
        if (res.status == 100) {
          this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
        }
        this.loadDataAgentLocation().subscribe((res: any) => {
          this.agentLocationData = [];
          if (res.status == 100) {

            let decryptData = JSON.parse(this.cnf.decryptData(res.datas));

            this.agentLocationData = decryptData[0].agent;

            for (let i in this.agentLocationData) {
              this.agentLocationContentData[this.agentLocationData[i]] = [];
            }

            this.location = this.agentLocationData[0];
            let location = this.location;
            this.loadDataAgentByLocation(location, this.search).subscribe((res: any) => {
              this.loadingData = true;
              this.tab.select(location);
              if (res.status == 100) {
                let decryptData = JSON.parse(this.cnf.decryptData(res.datas));

                this.agentLocationContentData[location] = decryptData[0].agent;
                this.total = decryptData[0].total;
                this.totalPages = decryptData[0].total_page;
              }

              this.loadingData = true;
            });
          }
        });
      });
    }
  }

  loadDataAgentByLocation(location, search) {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    
    params = params.append('attoken', this.attoken);
    params = params.append('search', search);

    let data = {
      'page': this.currentPage,
      'per_page': this.perPage
    }
    let paramsObject = this.cnf.convertPostData(params, data);

    return this.http.post(this.cnf.URLWSNonPublicArea + '/agent/data/location/' + location, paramsObject)
      .pipe(map((response: Response) => response));
  }

  loadDataAgentLocation() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    
    params = params.append('search', this.search);
    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(this.cnf.URLWSNonPublicArea + '/agent/data/location', paramsObject)
      .pipe(map((response: Response) => response));
  }
}
