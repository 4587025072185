import { NgbDateFRParserFormatter } from './../../ngb-date-fr-parser-formatter';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RESTRequest } from '../../models/restrequest.model';
import { RESTResponse } from '../../models/restresponse';
import { CustomerService } from '../../services/customer.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ClaimService } from '../../services/claim.service';
import { Customer } from '../../models/customer.model';
import { LocalService } from '../../local.service';
import { PolicyInfoPayment } from '../../models/PolicyInfoPayment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ReqEndorsement } from '../../models/ReqEndorsement';
import { TranslateService } from '@ngx-translate/core';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { eLetter } from '../../models/eLetter';
import { APIService } from '../../services/api.service';
import { async } from '@angular/core/testing';


@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.scss'],
  providers: [Config, AuditTrail, ClaimService, { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class PolicyDetailComponent implements OnInit {
  params: any = '';
  public customerId: any = '';
  page = 1;
  policyDetailData: any = {};
  policyInvestmentData: any = [];
  policyProductData: any = [];
  policyRiderData: any = [];
  policyBeneficiaryData: any = [];
  policyPaymentData: any = [];
  policyTransactionData: any = [];
  policyEStatementData: any = [];
  policyEStatementYearData: any = [];
  policyEStatementTIVData: any = [];
  policyCheckData: any = [];
  policyAgentData: any = {};
  startDate: any = '';
  premiumOther: Number = 0;
  premiumBCA: Number = 0;
  stamp: Number = 0;
  adminBCA: Number = 0;

  loadingProduct = false;
  loadingRider = false;
  loadingIvestment = false;
  loadingBeneficiary = false;
  loadingPaymentHistory = false;
  loadingTransaction = false;
  loadingEstatement = false;
  loadingEstatementYear = false;
  loadingEstatementTiv = false;
  loadingPolicyCheck = false;
  loadingAgent = false;

  loadingPolicy = true;
  loadingEndorsement = false;

  // paging policy payment data
  paymentCurrentPage: any = 1;
  paymentPerPage: any = 10;
  paymentTotalPages: any = 1;
  paymentTotal: any = 0;
  paymentTotalDataPagination: any = 1;
  paymentPreviousPage: any = 1;

  // paging policy transaction data
  transactionCurrentPage: any = 1;
  transactionPerPage: any = 10;
  transactionTotalPages: any = 1;
  transactionTotal: any = 0;
  transactionTotalDataPagination: any = 1;
  transactionPreviousPage: any = 1;

  // paging policy estatement data
  estatementCurrentPage: any = 1;
  estatementPerPage: any = 10;
  estatementTotalPages: any = 1;
  estatementTotal: any = 0;
  estatementTotalDataPagination: any = 1;
  estatementPreviousPage: any = 1;

  // hardcoded is product TROP
  isTROP: boolean = false;

  public loadingData: boolean = false;
  public userId: any = '';

  infoPayment: PolicyInfoPayment = new PolicyInfoPayment();

  //sodiq, 28-09-2021 4:42
  endorsementData: any = [];
  reqEndors: ReqEndorsement = new ReqEndorsement();
  paymentLoading: boolean = false;

  otherLetterData: any = [];
  loadingOtherLetter: boolean = false;

  letterType: string = "";
  otherLetterStartDate: any;
  otherLetterEndDate: any;
  noteLetter: string = "";
  idTypeLetter: any;

  reqLetter: eLetter = new eLetter();
  reqDownload: eLetter = new eLetter();
  reqNewDownload: eLetter = new eLetter();

  tempType: any;
  tempIdLetter: any;

  pageLetter: number = 1;
  pageSizeLetter: number = 10;
  loadingLoadLetter: boolean = false;

  isEndorsement: boolean = false;

  isChangeExpiredCard: boolean = false;

  eLetterList = [
    { 'id': '1', 'name': 'Endorsement', 'type': 'endorsement' },
    { 'id': '350#358', 'name': 'Surat Pemberitahuan Premi', 'type': 'ebao' },
    { 'id': '186#11', 'name': 'Surat Pemberitahuan Sebelum Polis Tidak Aktif', 'type': 'ebao' },
    { 'id': '10005', 'name': 'Surat Gagal Debet', 'type': 'ebao' },
    { 'id': '180#9', 'name': 'Surat Pemberitahuan Polis Tidak Aktif', 'type': 'ebao' },
    // { 'id': '2', 'name': 'Surat Cuti Premi', 'type': 'ebao' },
    { 'id': '3', 'name': 'Surat Pemberitahuan Tenaga Pemasar Tidak Aktif', 'type': 'orphan' },
    { 'id': '4', 'name': 'Surat Perpanjangan Rider', 'type': 'hsr' }
  ];

  //galih 1-maret-2022 2:10
  page1: any = 1;
  pageSize = 10;
  CoiCorList: any = [];
  CashValueList: any = [];

  //daniel
  sumIDR: number = 0;
  sumUSD: number = 0;

  tempMenuCustomer: any = []
  menuCustomerButton: any = []
  menuCustomerTab: any = []

  changeList = [
    { 'id': '/my-data/req-med', 'name': 'Cetak Ulang Kartu Kesehatan' },
    { 'id': '/my-data/req-polis', 'name': 'Cetak Ulang Kartu Polis' },
    { 'id': '/my-data/update-occup:policyno', 'name': 'Ubah Penghasilan & Pekerjaan' },
    { 'id': '/my-data/Document-UnComplete:policyno', 'name': 'Permintaan Kelengkapan Dokumen' },
    { 'id': '/my-data/req-change-bene:policyno', 'name': 'Ubah Penerima Manfaat' },
    { 'id': '/my-data/req-change-topup', 'name': 'Ubah Reguler Top Up' },
    { 'id': '/my-data/change-frequency:policyno', 'name': 'Ubah Frekuensi Pembayaran Premi' },
    { 'id': '/my-data/switching:policyno', 'name': 'Pindah Nilai Investasi' },
    { 'id': '/my-data/premium-redirection:policyno', 'name': 'Ubah Alokasi Investasi' },
    { 'id': '/my-data/update-credit-card:policyno', 'name': 'Ubah Tanggal Kadaluarsa Kartu Kredit' },
    { 'id': '/my-data/update-policy-holder', 'name': 'Ubah Pemegang Polis' }
  ];

  tempChange: string = "";

  isHSR = false;

  constructor(
    private route: ActivatedRoute,
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private router: Router,
    private http: HttpClient,
    private _location: Location,
    private customerService: CustomerService,
    private localService: LocalService,
    private translate: TranslateService,
    private cpSweetalertService: CpSweetalertService,
    private apiService: APIService
  ) {
    this.route.params.subscribe(params => (this.params = params));
  }

  async ngOnInit() {

    this.auditTrail.saveLog('My Data Policy Information', 'Open Policy Detail #' + this.params.policyNo)
    this.titleService.setTitle(this.cnf.prefixTitle + 'Policy Detail #' + this.params.policyNo + this.cnf.postfixTitle)
    this.userId = this.localService.getJsonValue('userid')
    this.loadingPolicy = true
    this.policyDataDetail();

  }

  policyDataDetail() {
    this.policyDetail().subscribe(async (res: any) => {
      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyDetailData = decryptData[0].policyInformation_detail[0];
        this.loadingData = true;

        if (this.policyDetailData.email == null || this.policyDetailData.email == '') {
          this.policyDetailData.email = '-';
        }
        if (this.policyDetailData.mobilePhone == null || this.policyDetailData.mobilePhone == '') {
          this.policyDetailData.mobilePhone = '-';
        }

        if (this.policyDetailData != null) {
          this.reqEndors.customerId = this.userId;
          this.reqEndors.policyCode = this.policyDetailData.policyNo;
          this.reqEndors.name = this.policyDetailData.policyHolderName;
        }

        let d = new Date();
        var g1 = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        // (YYYY, MM, DD) 
        var g2 = new Date(2021, 1, 1);
        if (this.policyDetailData.totalPremium <= 5000000) {
          this.stamp = 0;
          this.adminBCA = 3000;
        } else if (this.policyDetailData.totalPremium > 5000000) {
          this.stamp = 10000;
          this.adminBCA = 13000;
        }

        this.premiumOther = +this.policyDetailData.totalPremium + +this.stamp;
        this.premiumBCA = +this.policyDetailData.totalPremium + +this.adminBCA;

      } else {
        this.router.navigate(['/404']);
      }
    }).add(() => {
      this.loadingPolicy = false
      this.loadPolicyCheck()
    })
  }

  loadPolicyCheck() {
    this.loadingPolicyCheck = true;
    this.customerService.getPolicyCheck(this.params.policyNo).subscribe((res: any) => {
      const response: RESTResponse<any> = res.body;
      this.policyCheckData = response.data;
      this.loadingPolicyCheck = false;
    }).add(() => {
      this.loadingPolicyCheck = false;
      this.loadAgent();
    });
  }

  loadMenuCustomer() {
    this.menuCustomer(this.policyDetailData.productCode).subscribe((res: any) => {
      if (res.status === 100) {
        this.tempMenuCustomer = res.datas;

        for (let menu of this.tempMenuCustomer) {
          if (menu.menuId === 1) {
            menu.url = '/my-data/req-med'
          }
          if (menu.menuId === 2) {
            menu.url = '/my-data/req-polis'
          }
          if (menu.menuId === 3) {
            menu.url = '/my-data/update-occup/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 4) {
            menu.url = '/my-data/Document-UnComplete/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 5) {
            menu.url = '/my-data/change-frequency/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 6) {
            menu.url = '/my-data/switching/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 7) {
            menu.url = '/my-data/premium-redirection/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 8) {
            menu.url = '/my-data/update-policy-holder'
          }
          if (menu.menuId === 9) {
            menu.url = '/my-data/req-change-bene/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 10) {
            menu.url = '/my-data/premium-holiday/' + this.policyDetailData.policyNo
          }
          if (menu.menuId === 11) {
            menu.url = '/my-data/update-credit-card/' + this.policyDetailData.policyNo
            if (this.isChangeExpiredCard) {
              if (menu.isAccess === 'Y') {
                menu.isAccess = 'Y'
              } else {
                menu.isAccess = 'N'
              }
            } else {
              menu.isAccess = 'N'
            }
          }

          if (menu.menuId === 19) {
            if (this.policyCheckData.policyFilePath1) {
              if (menu.isAccess === 'Y') {
                menu.isAccess = 'Y'
              } else {
                menu.isAccess = 'N'
              }
            } else {
              menu.isAccess = 'N'
            }
          }
          if (menu.menuId === 20) {
            if (this.policyAgentData.agentCode) {
              if (menu.isAccess === 'Y') {
                menu.isAccess = 'Y'
              } else {
                menu.isAccess = 'N'
              }
            } else {
              menu.isAccess = 'N'
            }
          }
        }
        this.menuCustomerButton = this.tempMenuCustomer.filter((e: any) => e.types === 'button' && e.isAccess === 'Y')
        this.menuCustomerTab = this.tempMenuCustomer.filter((e: any) => e.types === 'tab' && e.isAccess === 'Y')
      }
    })
  }

  getExpiredCard() {
    this.customerService.getExpiredCard(this.params.policyNo).subscribe((res: any) => {
      if (res.body.data.expiredDateCard != null) {
        this.isChangeExpiredCard = true;
      } else {
        this.isChangeExpiredCard = false;
      }
    }).add(() => {
      this.loadMenuCustomer()
    });
  }

  onPanelChange($event: NgbPanelChangeEvent) {
    const panel = $event.panelId;
    if (panel === 'estatement-panel') {
      if (!this.policyEStatementData.length) {
        this.loadEstatement();
      }
    } else if (panel === 'estatementyear-panel') {
      if (!this.policyEStatementYearData.length) {
        this.loadEstatementYear();
      }
    } else if (panel === 'estatementtiv-panel') {
      if (!this.policyEStatementTIVData.length) {
        this.loadEstatementTiv();
      }
    } else if (panel === 'endorsement-panel') {
      if (!this.endorsementData.length) {
        this.loadEndorsementData();
      }
    } else if (panel === 'cash-value') {
      this.CashValue();
    } else if (panel === 'coi-cor'){
      this.CoiCor()
    }
  }

  loadEstatement() {
    this.loadingEstatement = true;
    this.policyEStatement()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyEStatementData = decryptData[0].policyInformation_payment;
          this.estatementTotal = decryptData[0].total;
          this.estatementTotalDataPagination = decryptData[0].total;
          this.estatementTotalPages = decryptData[0].total_page;
        } else {
          this.estatementTotal = 0;
          this.estatementTotalDataPagination = 1;
          this.estatementTotalPages = 0;
        }
      })
      .add(() => {
        this.loadingEstatement = false;
      });
  }

  loadEstatementYear() {
    this.loadingEstatementYear = true;
    this.policyEStatementYear();
  }

  loadEstatementTiv() {
    this.loadingEstatementTiv = true;
    this.policyEStatementTIV();
  }

  onTabChange($event: NgbTabChangeEvent) {
    const tab = $event.nextId;
    if (tab === 'tab_14') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open Product #' + this.params.policyNo);
      if (!this.policyProductData.length) {
        this.loadProductDetail();
      }
      if (!this.policyRiderData.length) {
        this.loadPolicyRider();
      }
    } else if (tab === 'tab_13') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open Fund #' + this.params.policyNo);
      if (!this.policyInvestmentData.length) {
        this.loadPolicyInvestmentData();
      }
    } else if (tab === 'tab_15') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open Beneficiary #' + this.params.policyNo);
      if (!this.policyBeneficiaryData.length) {
        this.loadBeneficiary();
      }
    } else if (tab === 'tab_16') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open History Payment #' + this.params.policyNo);
      if (!this.policyPaymentData.length) {
        this.loadHistoryPayment();
      }
    } else if (tab === 'tab_20') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open Agen #' + this.params.policyNo);
      if (!this.policyAgentData.length) {
        this.loadTransactionPayment();
      }
    } else if (tab == 'tab_12') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open Policy Detail #' + this.params.policyNo);
    } else if (tab == 'tab_21') {
      this.auditTrail.saveLog('My Data Policy Information', 'Open e_Letter #' + this.params.policyNo);
    }

  }

  loadProductDetail() {
    this.loadingProduct = true;
    this.policyProduct()
      .subscribe((res: any) => {
        const response: RESTResponse<any> = res.body;
        if (response.success) {
          this.policyProductData = response.data;
          for (let i in this.policyProductData) {
            if (this.policyProductData[i].productCode.includes('TROP')) {
              this.isTROP = true;
            }
          }
        }
      })
      .add(() => {
        this.loadingProduct = false;
      });
  }

  loadPolicyRider() {
    this.loadingRider = true;
    this.policyRider()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyRiderData = decryptData[0].policyInformation_prod_rider;
        }
      })
      .add(() => {
        this.loadingRider = false;
      });
  }

  loadPolicyInvestmentData() {
    this.loadingIvestment = true;
    this.policyInvestment()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyInvestmentData = decryptData[0].policyInformation_fund_invest;
        }
      })
      .add(() => {
        this.loadingIvestment = false;
      });
  }

  loadBeneficiary() {
    this.policyBeneficiary()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyBeneficiaryData = decryptData[0].policyInformation_beneficiary;
        }
      })
      .add(() => {
        this.loadingBeneficiary = false;
      });
  }

  loadHistoryPayment() {
    this.loadingPaymentHistory = true;

    this.policyPayment().subscribe(
      (res: any) => {
        const response: RESTResponse<PolicyInfoPayment> = res.body;
        if (response.success) {
          this.policyPaymentData = response.data;
          this.paymentTotal = response.data[0].total;
          this.paymentTotalDataPagination = response.data[0].total;
          this.paymentTotalPages = response.data[0].total_page;
        } else {
          this.paymentTotal = 0;
          this.paymentTotalDataPagination = 1;
          this.paymentTotalPages = 0;
        }
      }
    ).add(() => {
      this.loadingPaymentHistory = false;
    });

  }

  loadTransactionPayment() {
    this.loadingTransaction = true;
    this.policyTransaction()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyTransactionData = decryptData[0].policyInformation_transaction;

          this.transactionTotal = decryptData[0].total;
          this.transactionTotalDataPagination = decryptData[0].total;
          this.transactionTotalPages = decryptData[0].total_page;
        } else {
          this.transactionTotal = 0;
          this.transactionTotalDataPagination = 1;
          this.transactionTotalPages = 0;
        }
      })
      .add(() => {
        this.loadingTransaction = false;
      });
  }

  loadAgent() {
    this.loadingAgent = true;
    this.policyAgent()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyAgentData = decryptData[0].policyInformation_agent[0];
        }
      })
      .add(() => {
        this.loadingAgent = false;
        this.getExpiredCard()
      });
  }

  loadPagePayment(page: string) {
    if (page !== this.paymentPreviousPage) {
      this.paymentPreviousPage = page;
      this.paymentCurrentPage = page;
      // this.policyPaymentData = [];

      this.policyPayment().subscribe(
        (res: any) => {
          this.loadingData = true;
          const response: RESTResponse<PolicyInfoPayment> = res.body;
          if (response.success) {
            this.policyPaymentData = response.data;
            this.paymentTotal = response.data[0].total;
            this.paymentTotalPages = response.data[0].total_page;
          } else {
            this.paymentTotal = 0;
            this.paymentTotalDataPagination = 1;
            this.paymentTotalPages = 0;
          }
        }
      );
    }
  }

  loadEndorsementData() {
    this.loadingEndorsement = true;
    this.customerService.getEndorsement().subscribe((res: any) => {
      if (res.status == 200) {
        if (res.body.data != null && res.body.data.length > 0) {
          this.endorsementData = res.body.data
        }
      }
    })
      .add(() => {
        this.loadingEndorsement = false;
      });
  }

  doSearchPayment() {
    this.auditTrail.saveLog('My Data Policy Information', 'Policy Detail Search Payment #' + this.params.policyNo);
    this.policyPaymentData = [];
    this.paymentCurrentPage = 1;
    this.paymentLoading = true;
    this.policyPayment().subscribe(
      (res: any) => {
        this.loadingData = false;
        const response: RESTResponse<PolicyInfoPayment> = res.body;
        if (response.success) {
          this.policyPaymentData = response.data;
          if (this.policyPaymentData.length > 0) {
            this.paymentTotal = response.data[0].total;
            this.paymentTotalDataPagination = response.data[0].total;
            this.paymentTotalPages = response.data[0].total_page;
          } else {
            this.paymentTotal = 0;
            this.paymentTotalDataPagination = 1;
            this.paymentTotalPages = 0;
          }
        } else {
          this.paymentTotal = 0;
          this.paymentTotalDataPagination = 1;
          this.paymentTotalPages = 0;
        }
        this.paymentLoading = false;
      }
    );

  }

  doSearchEStatement() {
    this.auditTrail.saveLog('My Data Policy Information', 'Policy Detail Search E Statement #' + this.params.policyNo);
    // this.policyEStatementData = [];
    this.estatementCurrentPage = 1;
    this.policyEStatement().subscribe((res: any) => {
      this.loadingData = true;

      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyEStatementData = decryptData[0].policyInformation_payment;
        this.estatementTotal = decryptData[0].total;
        this.estatementTotalPages = decryptData[0].total_page;
      } else {
        this.estatementTotal = 0;
        this.estatementTotalPages = 0;
      }
    });
  }

  loadPageTransaction(page: string) {
    if (page !== this.transactionPreviousPage) {
      this.transactionPreviousPage = page;
      this.transactionCurrentPage = page;
      // this.policyTransactionData = [];

      this.policyTransaction().subscribe((res: any) => {
        this.loadingData = true;
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyTransactionData = decryptData[0].policyInformation_transaction;

          this.transactionTotal = decryptData[0].total;
          this.transactionTotalPages = decryptData[0].total_page;
        } else {
          this.transactionTotal = 0;
          this.transactionTotalPages = 0;
        }
      });
    }
  }

  loadPageEStatement(page: string) {
    if (page !== this.estatementPreviousPage) {
      this.estatementPreviousPage = page;
      this.estatementCurrentPage = page;
      // this.policyEStatementData = [];

      this.policyEStatement().subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyEStatementData = decryptData[0].policyInformation_payment;
          this.loadingData = true;

          this.estatementTotal = decryptData[0].total;
          this.estatementTotalPages = decryptData[0].total_page;
        } else {
          this.estatementTotal = 0;
          this.estatementTotalPages = 0;
        }
      });
    }
  }

  doSearchTransaction() {
    this.auditTrail.saveLog('My Data Policy Information', 'Policy Detail Search Transaction #' + this.params.policyNo);
    // this.policyTransactionData = [];
    this.transactionCurrentPage = 1;

    this.policyTransaction().subscribe((res: any) => {
      this.loadingData = true;
      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyTransactionData = decryptData[0].policyInformation_transaction;

        this.transactionTotal = decryptData[0].total;
        this.transactionTotalDataPagination = decryptData[0].total;
        this.transactionTotalPages = decryptData[0].total_page;
      } else {
        this.transactionTotal = 0;
        this.transactionTotalPages = 0;
        this.transactionTotalDataPagination = 1;
      }
    });
  }

  policyDetail() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + this.params.policyNo);
  }

  menuCustomer(productCode: any) {
    return this.http.get(this.cnf.URLWSNonPublicArea + '/menu_customer/getDetail/' + productCode);
  }

  policyInvestment() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/fund_invest/policy/' + this.params.policyNo);
  }

  policyProduct() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('token', this.cnf.token);

    let req = new RESTRequest<any>();
    req.appid = this.cnf.appid;
    req.appkey = this.cnf.appkey;
    req.data = '';
    req.apptype = 'web';
    req.token = this.cnf.token;
    return this.customerService.getPolicyDetailProduct(req, this.params.policyNo);
  }

  policyRider() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/prod_rider/policy/' + this.params.policyNo);
  }

  policyBeneficiary() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/beneficiary/policy/' + this.params.policyNo);
  }

  policyPayment() {
    let startDate = '';
    if (this.paymentStartDate.day) {
      startDate =
        this.paymentStartDate.year +
        '-' +
        ('0' + this.paymentStartDate.month).slice(-2) +
        '-' +
        ('0' + this.paymentStartDate.day).slice(-2);
    }

    let endDate = '';
    if (this.paymentEndDate.day) {
      endDate =
        this.paymentEndDate.year +
        '-' +
        ('0' + this.paymentEndDate.month).slice(-2) +
        '-' +
        ('0' + this.paymentEndDate.day).slice(-2);
    }

    this.infoPayment.page = this.paymentCurrentPage;
    this.infoPayment.per_page = this.paymentPerPage;
    this.infoPayment.start_date = startDate;
    this.infoPayment.end_date = endDate;

    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('token', this.cnf.token);

    let req = new RESTRequest<PolicyInfoPayment>();
    req.appid = this.cnf.appid;
    req.appkey = this.cnf.appkey;
    req.data = this.infoPayment;
    req.apptype = 'web';
    req.token = this.cnf.token;
    return this.customerService.infoPayment(req, this.params.policyNo);
  }

  policyTransaction() {
    let startDate = '';
    if (this.transactionStartDate.day) {
      startDate =
        this.transactionStartDate.year +
        '-' +
        ('0' + this.transactionStartDate.month).slice(-2) +
        '-' +
        ('0' + this.transactionStartDate.day).slice(-2);
    }

    let endDate = '';
    if (this.transactionEndDate.day) {
      endDate =
        this.transactionEndDate.year +
        '-' +
        ('0' + this.transactionEndDate.month).slice(-2) +
        '-' +
        ('0' + this.transactionEndDate.day).slice(-2);
    }

    let params = new HttpParams();
    params = params.append('page', this.transactionCurrentPage);
    params = params.append('per_page', this.transactionPerPage);
    params = params.append('start_date', startDate);
    params = params.append('end_date', endDate);
    params = params.append('type', this.transactionType);

    let paramsObject = this.cnf.convertPostData(params);

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/transaction/policy/' + this.params.policyNo, paramsObject);
  }

  policyEStatement() {
    let startDate = '';
    if (this.estatementStartDate.day) {
      startDate =
        this.estatementStartDate.year +
        '-' +
        ('0' + this.estatementStartDate.month).slice(-2) +
        '-' +
        ('0' + this.estatementStartDate.day).slice(-2);
    }

    let endDate = '';
    if (this.estatementEndDate.day) {
      endDate =
        this.estatementEndDate.year +
        '-' +
        ('0' + this.estatementEndDate.month).slice(-2) +
        '-' +
        ('0' + this.estatementEndDate.day).slice(-2);
    }

    let params = new HttpParams();

    params = params.append('page', this.estatementCurrentPage);
    params = params.append('per_page', this.estatementPerPage);
    params = params.append('start_date', startDate);
    params = params.append('end_date', endDate);

    let paramsObject = this.cnf.convertPostData(params);
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/estatment/policy/' + this.params.policyNo, paramsObject);
  }

  policyEStatementYear() {
    return this.customerService.getEstatYear(this.params.policyNo).subscribe((res: any) => {
      const response: RESTResponse<any> = res.body;
      this.policyEStatementYearData = response.data;
      this.loadingEstatementYear = false;
    });
  }

  policyEStatementTIV() {
    return this.customerService.getEstatTiv(this.params.policyNo).subscribe((res: any) => {
      const response: RESTResponse<any> = res.body;
      this.policyEStatementTIVData = response.data;
      this.loadingEstatementTiv = false;
    });
  }

  policyCheck() {

  }

  policyAgent() {
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/agent/policy/' + this.params.policyNo);
  }

  backClicked() {
    this._location.back();
  }

  doDownloadEstatementFile(eStatementData: any) {
    this.auditTrail.saveLog(
      'My Data Policy Information',
      'Download E-Statement File Policy Detail #' + this.params.policyNo
    );

    this.customerService.getCustomer(this.userId).subscribe((res: any) => {
      const response: RESTResponse<Customer> = res.body;
      const reqDownload = new eLetter()
      reqDownload.filePath = "";
      reqDownload.password = "";
      reqDownload.docNo = "";
      reqDownload.docId = eStatementData.transactionType;
      reqDownload.policyCode = eStatementData.policyNo;
      reqDownload.type = "ts";
      reqDownload.fileName = "";
      reqDownload.customerId = "" + response.data.customerId;

      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = reqDownload;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.downloadLetterAutomation(req).subscribe((res: any) => {
        if (res.status == 200) {
          if (res.body.data.base64 != null && res.body.data.base64 != "") {
            if (res.body.data.succes) {
              this.downloadPdf(res.body.data.base64, res.body.data.fileName);
            } else {
              this.cpSweetalertService.show('Pernyataan Transaksi tidak ditemukan', 'error');
            }
          } else {
            this.cpSweetalertService.show('Pernyataan Transaksi tidak ditemukan', 'error');
          }
        } else {
          this.cpSweetalertService.show('Pernyataan Transaksi tidak ditemukan', 'error');
        }
      }, (err: any) => {
        this.cpSweetalertService.show('Pernyataan Transaksi tidak ditemukan', 'error');
        console.log(err.message);
      }).add(() => {

      });
    });

  }

  doDownloadPolicy(path: string) {
    this.auditTrail.saveLog(
      'My Data Policy Information',
      'Download Ikhtisar Polis' + this.params.policyNo
    );
    this.customerService.getCustomer(this.userId).subscribe((res: any) => {
      const response: RESTResponse<Customer> = res.body;
      let urlDownload =
        this.cnf.URLCpService +
        '/customer/file_transfer/policyCheck' + path;
      var a = urlDownload;
      if (a.match(/^[a-zA-Z0-9_:\.//-]*$/)) {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = a;
        link.click();
        link.remove();
      } else {
        console.log('not match');
      }
    });


  }

  doDownloadEstatementYearFile(eStatementData: any) {
    this.auditTrail.saveLog(
      'My Data Policy Information',
      'Download E-Statement Year File Policy Detail #' + this.params.policyNo
    );

    var start = eStatementData.archieveFileName.lastIndexOf("/");
    var docId = eStatementData.archieveFileName.substring((start + 1), eStatementData.archieveFileName.length);

    this.customerService.getCustomer(this.userId).subscribe((res: any) => {
      const response: RESTResponse<Customer> = res.body;
      const reqDownload = new eLetter()
      reqDownload.year = eStatementData.year;
      reqDownload.filePath = "";
      reqDownload.password = "";
      reqDownload.docNo = "";
      reqDownload.docId = docId;
      reqDownload.policyCode = eStatementData.policyNo;
      reqDownload.type = "ats";
      reqDownload.fileName = "";
      reqDownload.customerId = "" + response.data.customerId;

      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = reqDownload;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.downloadLetterAutomation(req).subscribe((res: any) => {
        if (res.status == 200) {
          if (res.body.data.base64 != null && res.body.data.base64 != "") {
            if (res.body.data.succes) {
              this.downloadPdf(res.body.data.base64, res.body.data.fileName);
            } else {
              this.cpSweetalertService.show('Pernyataan Transaksi Tahunan tidak ditemukan', 'error');
            }
          } else {
            this.cpSweetalertService.show('Pernyataan Transaksi Tahunan tidak ditemukan', 'error');
          }
        } else {
          this.cpSweetalertService.show('Pernyataan Transaksi Tahunan tidak ditemukan', 'error');
        }
      }, (err: any) => {
        this.cpSweetalertService.show('Pernyataan Transaksi Tahunan tidak ditemukan', 'error');
        console.log(err.message);
      }).add(() => {

      });
    });
  }

  doDownloadEstatementTIVFile(eStatementData: any) {
    this.auditTrail.saveLog(
      'My Data Policy Information',
      'Download E-Statement TIV File Policy Detail #' + this.params.policyNo
    );

    this.customerService.getCustomer(this.userId).subscribe((res: any) => {
      const response: RESTResponse<Customer> = res.body;
      const reqDownload = new eLetter()
      reqDownload.year = eStatementData.year;
      reqDownload.filePath = "";
      reqDownload.password = "";
      reqDownload.docNo = "";
      reqDownload.docId = "";
      reqDownload.policyCode = eStatementData.policyNo;
      reqDownload.type = "tiv";
      reqDownload.fileName = "";
      reqDownload.customerId = "" + response.data.customerId;

      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = reqDownload;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.downloadLetterAutomation(req).subscribe((res: any) => {
        if (res.status == 200) {
          if (res.body.data.base64 != null && res.body.data.base64 != "") {
            if (res.body.data.succes) {
              this.downloadPdf(res.body.data.base64, res.body.data.fileName);
            } else {
              this.cpSweetalertService.show('Surat Nilai Investasi tidak ditemukan', 'error');
            }
          } else {
            this.cpSweetalertService.show('Surat Nilai Investasi tidak ditemukan', 'error');
          }
        } else {
          this.cpSweetalertService.show('Surat Nilai Investasi tidak ditemukan', 'error');
        }
      }, (err: any) => {
        this.cpSweetalertService.show('Surat Nilai Investasi tidak ditemukan', 'error');
        console.log(err.message);
      }).add(() => {

      });
    });
  }

  today: any = new Date();
  paymentStartDate: any = {};
  paymentEndDate: any = {};
  transactionType: string = '';
  transactionStartDate: any = {};
  transactionEndDate: any = {};
  estatementStartDate: any = {};
  estatementEndDate: any = {};

  reqEndorsement(descirption: string, id: string, reportId: any) {
    this.auditTrail.saveLog('My Data Policy Information', 'Download Endorsement +#' + this.params.policyNo);
    const desc = this.translate.instant(descirption);
    const message = this.translate.instant('_request_endorsement');
    Swal.fire({
      title: message + ' ' + desc + '?',
      showCancelButton: true,
      type: 'question',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.loadingEndorsement = true;
        if (reportId != null) {
          this.onClickDownloadPdf(this.params.policyNo, reportId, "" + id);
        } else {
          this.reqEndors.endorsementId = "" + id;
          this.reqEndors.deskription = descirption;
          const req = new RESTRequest<ReqEndorsement>();
          req.appid = this.cnf.appid;
          req.appkey = this.cnf.appkey;
          req.data = this.reqEndors;
          req.apptype = 'web';
          req.token = this.cnf.token;
          this.customerService.saveReqEndorsement(req).subscribe((res: any) => {
            if (res.status == 200) {
              this.cpSweetalertService.show('_your_request_has_been_success', 'success');
            } else {
              this.cpSweetalertService.show(res.message, 'error');
            }
          },
            err => {
              if (err.status === 400) {
                const msg = err.error;
                const message = this.translate.instant(msg.message);
                this.cpSweetalertService.show(message, 'error');
              } else {
                this.cpSweetalertService.show('_endorsement_error_occurred', 'error');
              }
            })
            .add(() => {
              this.loadingEndorsement = false;
            });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.cpSweetalertService.show('_your_request_cancelled', 'error');
      }
    })
  }

  downloadPdf(base64String: any, fileName: any) {
    const source = 'data:application/pdf;base64,' + base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName;
    link.click();
  }

  onClickDownloadPdf(policyNo: any, reportId: string, idEndorsement: any) {
    this.customerService.donwloadEndorsementLetter(policyNo, reportId, idEndorsement).subscribe((res: any) => {
      if (res.status == 200) {
        if (res.body.data.stringBase64 != "") {
          this.cpSweetalertService.show('_your_request_has_been_success', 'success');
          this.downloadPdf(res.body.data.stringBase64, res.body.data.fileName);
        } else {
          this.cpSweetalertService.show('_endorsement_letter_notfound', 'error');
        }
      } else {
        this.cpSweetalertService.show('_soory_endorsement_letter_occurred', 'error');
      }
    }, err => {
      this.cpSweetalertService.show('_endorsement_letter_notfound', 'error');
    }
    ).add(() => {
      this.loadingEndorsement = false;
    });
  }

  CoiCor() {
    return this.customerService.detailCoiCor(this.params.policyNo).subscribe((res: any) => {
      this.CoiCorList = res.body.data
    });

  }


  CashValue() {
    this.CashValueList = [];
    return this.customerService.getCustomerFund(this.params.policyNo).subscribe((res: any) => {
      if (res.status == 200) {
        this.CashValueList = res.body.data;
        this.sumIDR = 0;
        this.sumUSD = 0;
        for (var a of this.CashValueList) {
          if ("IDR" == a.currency) {
            this.sumIDR += a.sumFund;
          }
          if ("USD" === a.currency) {
            this.sumUSD += a.sumFund;
          }
        }
      }
    });

  }

  setNoteLetter() {
    if (this.letterType != "") {
      let id = this.letterType.split("=")[0];
      if (id === "1") {
        this.isEndorsement = true;
        this.isHSR = false;
      } else if(id === "4"){
        this.isEndorsement = false;
        this.isHSR = true;
      } else {
        this.isHSR = false;
        this.isEndorsement = false;
      }
      let name = this.letterType.split("=")[1];
      this.noteLetter = "Berikut daftar " + name;
    } else {
      this.idTypeLetter = "";
      this.noteLetter = "";
    }
    this.otherLetterData = [];
  }

  doReset() {
    this.noteLetter = "";
    this.letterType = "";
    this.otherLetterData = [];
    this.otherLetterStartDate = undefined;
    this.otherLetterEndDate = undefined;
  }

  async doSearchOtherLetter() {
    this.loadingOtherLetter = true;
    if (this.letterType != "" && this.letterType != null) {
      this.reqLetter.policyCode = this.params.policyNo;
      let id = this.letterType.split("=")[0];
      let name = this.letterType.split("=")[1];
      let type = this.letterType.split("=")[2];
      this.tempIdLetter = id;
      this.tempType = type;

      this.reqLetter.docId = id;
      this.reqLetter.type = type;

      if (this.otherLetterStartDate != undefined) {
        let start =
          this.otherLetterStartDate.year +
          '-' +
          ('0' + this.otherLetterStartDate.month).slice(-2) +
          '-' +
          ('0' + this.otherLetterStartDate.day).slice(-2);
        this.reqLetter.startDate = start;
      }
      if (this.otherLetterEndDate != undefined) {
        let end =
          this.otherLetterEndDate.year +
          '-' +
          ('0' + this.otherLetterEndDate.month).slice(-2) +
          '-' +
          ('0' + this.otherLetterEndDate.day).slice(-2);
        this.reqLetter.endDate = end;
      }
      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = this.reqLetter;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.getLetterAutomation(req).subscribe((res: any) => {
        this.loadingOtherLetter = false;
        if (res.status == 200) {
          this.otherLetterData = res.body.data;
        } else {
          this.loadingOtherLetter = false;
        }
      }, (err: any) => {
        this.loadingOtherLetter = false;
        console.log(err.message);
      });
    } else {
      this.otherLetterData = [];
      this.loadingOtherLetter = false;
    }
  }

  doDownloadLetterAutomation(letterData: any) {
    this.loadingLoadLetter = true;
    this.auditTrail.saveLog(
      'My Data Policy Information',
      'Download E-Letter Policy Detail #' + this.params.policyNo
    );
    if(["hsr", "general_hsr", "mmr", "hsr_mmr"].includes(letterData.letterType)){
      this.reqNewDownload.letterType = letterData.letterType;
      this.reqNewDownload.fileName = letterData.fileName;
      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = this.reqNewDownload;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.directDownload(req).subscribe((blob: Blob) => {
        this.saveAS(blob, letterData.fileName);
        this.auditTrail.saveLog(
          'My Data Policy Information',
          'Download E-Letter '+letterData.endorsementName+' #' + this.params.policyNo
        );
      }, (err: any) => {
        this.loadingLoadLetter = false;
        this.cpSweetalertService.show('Surat Eletronik tidak ditemukan', 'error');
        console.log(err.message);
      }).add(()=>{
        this.loadingLoadLetter = false;
      });
    }else{
      this.reqDownload.filePath = letterData.filePath;
      this.reqDownload.password = letterData.password;
      this.reqDownload.docNo = letterData.docNo;
      this.reqDownload.type = this.tempType;
      this.reqDownload.policyCode = this.params.policyNo;
      let req = new RESTRequest<eLetter>();
      req.appid = this.cnf.appid;
      req.appkey = this.cnf.appkey;
      req.data = this.reqDownload;
      req.apptype = 'web';
      req.token = this.cnf.token;
      this.customerService.downloadLetterAutomation(req).subscribe((res: any) => {
        if (res.status == 200) {
          if (res.body.data.base64 != null && res.body.data.base64 != "") {
            this.downloadPdf(res.body.data.base64, res.body.data.fileName);
          } else {
            this.cpSweetalertService.show('Surat Eletronik tidak ditemukan', 'error');
          }
        } else {
          this.cpSweetalertService.show('Surat Eletronik tidak ditemukan', 'error');
        }
      }, (err: any) => {
        this.cpSweetalertService.show('Surat Eletronik tidak ditemukan', 'error');
        console.log(err.message);
      }).add(() => {
        this.loadingLoadLetter = false;
      });
    }
  }

  saveAS(blob: any, filename: string): string {
    const url = window.URL.createObjectURL(blob); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }

  gotoChange(url: any, policyno: any) {
    if (url.value != "") {
      var link = url.value.replace(':policyno', '/' + policyno);
      this.router.navigate([link]);
    }
  }
}
