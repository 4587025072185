import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpParams } from '@angular/common/http';
import { APIService } from './api.service';
import { RESTService } from './rest.service';

@Injectable()
export class PolicyService {

  constructor(private cnf: Config, private api: APIService, private rest: RESTService) { }

  load(params: HttpParams, userID: any) {
    let paramsObject = this.cnf.convertPostData(params);
    return this.api.post(this.cnf.URLWSNonPublicArea + '/policy_information/header/userid/' + userID, paramsObject);
  }

}
