import { Injectable } from '@angular/core';
import { Config } from '../config';
import { RESTService } from './rest.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileClass } from '../models/fileClass';
import { RESTRequest } from '../models/restrequest.model';

@Injectable()
export class FileService {
  constructor(private cnf: Config, private rest: RESTService) { }
  url = this.cnf.URLFileService + '/upload';
  url2 = this.cnf.URLFileService + '/file';

  uploadWithNProgress(file: FileClass) {
    // this will be the our resulting map
    // this will be the our resulting map
    let req = new RESTRequest<FileClass>();
    req.data = file;
    return this.rest.post(this.url2 + '/upload3', req);
  }
}
