import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Config } from '../../config'; 
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';

@Component({
	selector: 'app-detail-product',
	templateUrl: './detail-product.component.html',
	styleUrls: ['./detail-product.component.scss'],
	providers: [ Config, AuditTrail ]
})
export class DetailProductComponent implements OnInit {
	params: any;
	data: any = {};
	public preview: any = '';
	public token: any = '';

	constructor(private route: ActivatedRoute, private cnf: Config, private auditTrail: AuditTrail, private titleService: Title, private http: HttpClient, private cacheService: CacheService) {
		this.route.params.subscribe(params => this.params = params);
		this.route.queryParams.subscribe(params => {
			this.preview = params['preview'];
			this.token = params['token'];
		});
	}

	ngOnInit() {
		this.cacheService.get(this.cnf.lang + '/product/' + this.params.id, this.loadData()).subscribe((res: any) => {
			if (res.status == 100) {
				this.data = res.datas;
				this.titleService.setTitle( this.cnf.prefixTitle + this.data.title + this.cnf.postfixTitle);
			}
		});
	}

	loadData() {
		let params = new HttpParams();
		params = params.append('appId', this.cnf.appid);
		params = params.append('appKey', this.cnf.appkey);
		params = params.append('lang', this.cnf.lang);

		let url = this.cnf.URLWS + '/product';
		if (this.preview) {
			params = params.append('token', this.token);
			url = url + '/preview/' + this.params.id;
		} else {
			url = url + '/frontend/byid/' + this.params.id;
		}
		let paramsObject = this.cnf.convertPostData(params);

		return this.http.post(url, paramsObject)
			.pipe(map((response: Response) => response));
	}
}