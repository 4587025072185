import { Injectable } from '@angular/core';
import {
  HttpParams,
  HttpClient,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Config } from '../config';
import { RESTResponse } from '../models/restresponse';
import { FileResponse } from '../models/file-response';
import { RESTRequest } from '../models/restrequest.model';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LocalService } from '../local.service';

@Injectable()
export class RESTService {
  headers = new HttpHeaders({
  });

  fileHeaders = new HttpHeaders({
    //'Content-Type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
    'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Credentials': 'true',
    Pragma: 'no-cache',
    Expires: '-1',
    'X-XSS-Protection': '1;mode=block',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Content-Type-Options': 'nosniff'
  });

  constructor(private cnf: Config, private http: HttpClient, private localService: LocalService) {
  }

  get<T>(url: string, params?: HttpParams): Observable<HttpResponse<RESTResponse<T>>> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Pragma: 'no-cache',
      Expires: '-1',
      'X-XSS-Protection': '1;mode=block',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Authorization': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    });
    return this.http
      .get<RESTResponse<T>>(url, { headers: this.headers, params: params, observe: 'response' })
      .pipe(retry(3), catchError(this.errorHandler));
  }

  post<T>(
    url: string,
    body?: string | RESTRequest<any>,
    params?: HttpParams
  ): Observable<HttpResponse<RESTResponse<T>>> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Pragma: 'no-cache',
      Expires: '-1',
      'X-XSS-Protection': '1;mode=block',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Authorization': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    });
    return this.http.post<RESTResponse<T>>(url, body, { headers: this.headers, params: params, observe: 'response' });
  }

  postFiles(
    url: string,
    body?: string | RESTRequest<any>,
  ){
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Pragma: 'no-cache',
      Expires: '-1',
      'X-XSS-Protection': '1;mode=block',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Authorization': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    });
    return this.http.post(url, body, { headers: this.headers, responseType: 'blob'});
  }

  put<T>(
    url: string,
    body?: string | RESTRequest<any>,
    params?: HttpParams
  ): Observable<HttpResponse<RESTResponse<T>>> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Pragma: 'no-cache',
      Expires: '-1',
      'X-XSS-Protection': '1;mode=block',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Authorization': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    });
    return this.http.put<RESTResponse<T>>(url, body, { headers: this.headers, params: params, observe: 'response' });
  }

  delete<T>(url: string): Observable<HttpResponse<RESTResponse<T>>> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
      'Access-Control-Allow-Origin': 'https://customercorner.chubblife.co.id',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Pragma: 'no-cache',
      Expires: '-1',
      'X-XSS-Protection': '1;mode=block',
      'X-Frame-Options': 'SAMEORIGIN',
      'X-Content-Type-Options': 'nosniff',
      'Authorization': (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')))
    });
    return this.http.delete<RESTResponse<T>>(url, { headers: this.headers, observe: 'response' });
  }

  postFile(url: string, fileToUpload: File): Observable<HttpResponse<RESTResponse<FileResponse>>> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post<RESTResponse<FileResponse>>(url, formData, {
      headers: this.fileHeaders,
      observe: 'response'
    });
  }

  upload(url: string, file: File) {
    try {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      return this.http.post(url, formData, { headers: this.fileHeaders, observe: 'events', reportProgress: true });
    } catch (error) { }
  }

  errorHandler(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      console.error('Error: ${error.error.message}');
    } else {
      // server-side error
      console.error('Error Code: ' + error.status + '\n Message: ' + error.message);
      throwError(error.message);
    }

    return throwError(error.message);
  }
}
