export class AuditTrailDataAfter {
    public correspondenceAddress1: string;
    public correspondenceAddress2: string;
    public correspondenceAddress3: string;
    public correspondenceAddress4: string;
    public correspondenceAddress5: string;
    public postalCode: string;
    public mobilePhonenumber: string;
    public homePhonenumber: string;
    public officePhonenumber: string;
    public email: string;
    public idCard: string;
    public dispatchType: string;
    public occupation: string;
    public annualIncome: string;
    public beneficiaryName1: string;
    public beneficiaryGender1: string;
    public beneficiaryDob1: Date;
    public beneficiaryDesignation1: string;
    public beneficiaryShare1: string;
    public beneficiaryName2: string;
    public beneficiaryGender2: string;
    public beneficiaryDob2: Date;
    public beneficiaryDesignation2: string;
    public beneficiaryShare2: string;
    public beneficiaryName3: string;
    public beneficiaryGender3: string;
    public beneficiaryDob3: Date;
    public beneficiaryDesignation3: string;
    public beneficiaryShare3: string;
    public beneficiaryName4: string;
    public beneficiaryGender4: string;
    public beneficiaryDob4: Date;
    public beneficiaryDesignation4: string;
    public beneficiaryShare4: string;
    public beneficiaryName5: string;
    public beneficiaryGender5: string;
    public beneficiaryDob5: Date;
    public beneficiaryDesignation5: string;
    public beneficiaryShare5: string;
    public freqPayment: string;
    public fund1: string;
    public fund2: string;
    public fund3: string;
    public fund4: string;
    public fund5: string;
    public percen1: number;
    public percen2: number;
    public percen3: number;
    public percen4: number;
    public percen5: number;
    public expiredDateCard: string;
    public policyHolder: string;
}