import { Component, OnInit } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { PaymentService } from '../../services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTRequest } from '../../models/restrequest.model';
import { RESTResponse } from '../../models/restresponse';
import { Subscription } from 'rxjs/Subscription';

import { ModalOptions } from 'ngx-bootstrap/modal';

import { Ipay88 } from '../../models/ipay88.model';
import { Ipay88Service } from '../../services/ipay88.service';
import { environment } from './../../../environments/environment';
import { CustomerService } from '../../services/customer.service';
import { PaymentRequest } from '../../models/paymentRequest.model';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { RESTService } from '../../services/rest.service';
import { stringify } from 'querystring';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
  providers: [Config, AuditTrail]
})
export class PaymentDetailComponent implements OnInit {
  params: any = '';
  public userId: any = '';
  policyDetailData: any = {};
  loadingPolicy = false;
  loadingRequestCC = false;
  paymentRequest: PaymentRequest = new PaymentRequest();
  personalDataSubscription: Subscription;
  refNo: string;
  Premi = 0;
  stamp: number = 0;

  // hardcoded is product TROP
  isTROP: boolean = false;
  adminFee: number = 0;
  sumPremium: number = 0;
  detailPremium: number = 0;
  detailLoan: number = 0;
  detailTerbilang: string = "";
  loadingBill: boolean = false;
  loadingButton: boolean = false;
  message: string = "";
  isAtu: string = "";
  detailAtu: number = 0;
  installment: number = 0;
  isError: boolean = false;
  is0installment: boolean = false;

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  constructor(
    private paymentService: PaymentService,
    private ipay88Service: Ipay88Service,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private cnf: Config,
    private cpSweetalertService: CpSweetalertService,
    private restApi: RESTService,
    private auditTrail: AuditTrail
  ) {
    this.route.params.subscribe(params => (this.params = params));
  }

  ngOnInit() {
    this.auditTrail.saveLog('Payment Policy Information', 'Open Detail Policy #' + this.params.policyNo);
    this.loadingBill = true;
    this.loadingPolicy = true;
    this.loadingButton = true;
    this.paymentService
      .getPolisDetail(this.params.policyNo)
      .subscribe((response: any) => {
        const res: RESTResponse<any> = response.body;

        if (res.success) {
          this.policyDetailData = res.data[0];

          let premi = Number(this.policyDetailData.totalPremium);
          this.installment = premi;
          this.adminFee = this.policyDetailData.adminFee;
          this.detailPremium = this.policyDetailData.premium;
          this.detailLoan = this.policyDetailData.loan;

          if ("Half Yearly" == this.policyDetailData.paymentFrequency || "Yearly" == this.policyDetailData.paymentFrequency) {
            this.is0installment = true;
          }

          if (!this.policyDetailData.homeAddress1) {
            this.policyDetailData.homeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.homeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.homeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.homeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.homeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.homeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.homeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.homeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.homePostCode = this.policyDetailData.correspondencePostCode;
          } else if (!this.policyDetailData.officeAddress1) {
            this.policyDetailData.officeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.officeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.officeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.officeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.officeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.officeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.officeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.officeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.officePostCode = this.policyDetailData.correspondencePostCode;
          }

          if (this.policyDetailData.isAtu == 'Y' && this.detailPremium == 0) {
            this.loadingButton = true;
            this.isAtu = 'Y';
          } else {
            this.isAtu = 'N';
            this.loadingButton = false;
          }

          if (this.isAtu == 'N') {
            if (this.detailPremium == 0) {
              this.detailPremium = this.installment;
            }
            this.sumPremium = ((this.detailPremium + this.detailLoan) + this.adminFee);
          }

          this.detailTerbilang = this.terbilang(this.sumPremium);

        } else {
          this.router.navigate(['/404']);
        }
      })
      .add(() => {
        this.loadingPolicy = false;
        this.loadingBill = false;
      });
  }

  inputATU(data: any) {
    if (data.target.value == "") {
      this.loadingButton = true;
      this.detailAtu = 0;
    } else {
      this.loadingButton = false;
      let number = Number(data.target.value);
      this.detailAtu = number;
    }
    this.sumPremium = ((this.detailAtu + this.detailLoan) + this.adminFee);
    this.detailTerbilang = this.terbilang(this.sumPremium);
  }

  terbilang(bilangan: any) {
    bilangan = String(bilangan);
    var angka = new Array('0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0');
    var kata = new Array('', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan');
    var tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');
    var panjang_bilangan = bilangan.length;
    /* pengujian panjang bilangan */
    if (panjang_bilangan > 15) {
      var kaLimat = "Diluar Batas";
      return kaLimat;
    }
    /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
    for (var i = 1; i <= panjang_bilangan; i++) {
      angka[i] = bilangan.substr(-(i), 1);
    }
    var i = 1;
    var j = 0;
    kaLimat = "";
    /* mulai proses iterasi terhadap array angka */
    while (i <= panjang_bilangan) {
      var subkaLimat = "";
      var kata1 = "";
      var kata2 = "";
      var kata3 = "";
      /* untuk Ratusan */
      if (angka[i + 2] != "0") {
        if (angka[i + 2] == "1") {
          kata1 = "Seratus";
        } else {
          kata1 = kata[angka[i + 2]] + " Ratus";
        }
      }
      /* untuk Puluhan atau Belasan */
      if (angka[i + 1] != "0") {
        if (angka[i + 1] == "1") {
          if (angka[i] == "0") {
            kata2 = "Sepuluh";
          } else if (angka[i] == "1") {
            kata2 = "Sebelas";
          } else {
            kata2 = kata[angka[i]] + " Belas";
          }
        } else {
          kata2 = kata[angka[i + 1]] + " Puluh";
        }
      }
      /* untuk Satuan */
      if (angka[i] != "0") {
        if (angka[i + 1] != "1") {
          kata3 = kata[angka[i]];
        }
      }
      /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
      if ((angka[i] != "0") || (angka[i + 1] != "0") || (angka[i + 2] != "0")) {
        subkaLimat = kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
      }
      /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
      kaLimat = subkaLimat + kaLimat;
      i = i + 3;
      j = j + 1;
    }
    /* mengganti Satu Ribu jadi Seribu jika diperlukan */
    if ((angka[5] == "0") && (angka[6] == "0")) {
      kaLimat = kaLimat.replace("Satu Ribu", "Seribu");
    }
    return kaLimat + "Rupiah";
  }

  onRequestCC(typePayment: any) {
    this.loadingRequestCC = true;
    if (this.personalDataSubscription) {
      this.personalDataSubscription.unsubscribe();
    }
    this.personalDataSubscription = this.customerService.personalDataSubject.subscribe(personalData => {
      if (!this.customerService.checkEmpty(personalData)) {

        if (environment.production) {
          this.paymentRequest.email = personalData.email;
          this.paymentRequest.mobilePhone = personalData.mobile;
        } else {
          this.paymentRequest.email = 'muhammad.sodiq@chubb.com';
          this.paymentRequest.mobilePhone = '085258713694';
        }

        this.paymentRequest.beneficiaryName = personalData.userId;
        this.paymentRequest.customerName = this.policyDetailData.mainLifeAssured;
        this.paymentRequest.policyNumber = this.policyDetailData.policyNo;
        this.paymentRequest.paymentMethod = 'cc';
        this.paymentRequest.premi = +this.sumPremium;
        this.paymentRequest.dateOfBirth = this.policyDetailData.birthday;

        if (this.isAtu == "Y") {
          this.paymentRequest.typePayment = "ATU";
        } else {
          this.paymentRequest.typePayment = typePayment;
        }

        if (this.paymentRequest.typePayment != null) {
          const req = new RESTRequest<PaymentRequest>();
          req.appid = this.cnf.appid;
          req.appkey = this.cnf.appkey;
          req.data = this.paymentRequest;
          req.apptype = 'web';
          req.token = '';
          this.ipay88Service.reqRequirement(req).subscribe(res => {
            const response: any = res.body.data;
            const paymentModel = { ...this.createPaymentRequestModel(response) };
            var jsonString1 = JSON.stringify(paymentModel).replace("||", "");
            var jsonString2 = jsonString1.replace("||", "");
            this.auditTrail.saveLog('Post Request Payment Menu', jsonString2);
            this.ipay88Service.paymentModel.next(paymentModel);
            this.auditTrail.saveLog('Payment Policy Information', 'Submit Success Payment Detail Policy #' + this.params.policyNo);
          });
        } else {
          this.cpSweetalertService.show('Tipe pembayaran tidak ditemukan, mohon untuk refresh halamanan ini', 'error');
          this.auditTrail.saveLog('Payment Policy Information', 'Submit Failed Payment Detail Policy #' + this.params.policyNo);
        }

      } else {
        this.loadingRequestCC = false;
        this.cpSweetalertService.show('_data_customer_not_found', 'error');
        this.auditTrail.saveLog('Payment Policy Information', 'Submit Failed Payment Detail Policy #' + this.params.policyNo);
      }
    }, err => {
      this.loadingRequestCC = false;
      this.cpSweetalertService.show('_error_get_data_customer', 'error');
      this.auditTrail.saveLog('Payment Policy Information', 'Submit Failed Payment Detail Policy #' + this.params.policyNo);
      console.log(err);
    });
  }

  createPaymentRequestModel(resp: any) {
    const paymentModel = new Ipay88();
    paymentModel.MerchantCode = resp.merchantCode;
    paymentModel.PaymentId = resp.paymentId;
    paymentModel.RefNo = resp.refNo;
    paymentModel.Amount = +resp.amount;
    paymentModel.Currency = resp.currency;
    paymentModel.ProdDesc = resp.prodDesc;
    paymentModel.UserName = resp.username;
    paymentModel.UserEmail = resp.userEmail;
    paymentModel.UserContact = resp.userContact;
    paymentModel.Lang = resp.lang;
    paymentModel.Signature = resp.signature;
    paymentModel.ResponseURL = resp.responseUrl;
    paymentModel.BackendURL = resp.backendUrl;
    paymentModel.Remark = resp.remark;
    paymentModel.Ipay88Url = resp.urlIpay88;
    paymentModel.xfield1 = resp.xfield1;
    return paymentModel;
  }
}
