import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Config } from './../config';
import { APIService } from './api.service';

@Injectable()
export class FundService {

  constructor(private cnf: Config, private api: APIService) { }

  loadName() {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/fund/fund-name/');

  }

  loadH1() {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/fund/daily-nav/h-1/');
  }

  loadH2() {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/fund/daily-nav/h-2/');
  }

  loadDataByDate(startDate: any, endDate: any) {
    return this.api.post(this.cnf.URLWSNonPublicArea + '/fund/daily-nav/' + startDate + '/' + endDate);
  }

}
