import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserInternal } from '../../models/user-internal.model';
import { UserService } from '../../services/user.service';
import { APIResponse } from '../../models/apiresponse';
import { LocalService } from '../../local.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [Config, AuditTrail, UserService]
})
export class LoginComponent implements OnInit {
  @ViewChild('loginErrorSwal', { static: false }) private loginErrorSwal: SwalComponent;
  @ViewChild('errorActivationNotFoundSwal', { static: false }) private errorActivationNotFoundSwal: SwalComponent;

  public userInternal: UserInternal;
  public loadingSignin: boolean = false;
  public personalData: any = {};
  public userId: any = '';
  activation: string = '';
  errorMessage: string;

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private user: UserService,
    private localService: LocalService
  ) {
    this.route.queryParams.subscribe(params => {
      this.activation = params['activation'];

      if (this.activation == 'expired') {
        setTimeout(() => {
          let message = this.translate.instant('_activation_not_found');
          this.showErrorNotFoundMessage(message);
        }, 500);
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.cnf.prefixTitle + 'Login' + this.cnf.postfixTitle);

    this.cnf.clearLocalStorage();
    this.userInternal = new UserInternal({});
  }
  onInternalSubmit({ value, valid }: { value: UserInternal; valid: boolean }) {
    this.userInternal = value;
    this.loadingSignin = true;

    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      userId: this.userInternal.email,
      password: this.cnf.encryptData(this.userInternal.password)
    };
    this.user.login(params, data).subscribe(
      (response: any) => {
        let resp: APIResponse<any> = response.body;
        if (resp.status == 100) {
          this.userId = resp.datas.userId;
          this.localService.setJsonValue('token', resp.token);
          this.localService.setJsonValue('userid', resp.datas.userId);
          this.localService.setJsonValue('lastLogin', resp.datas.lastLogin);
          this.cnf.token = resp.token;
          this.auditTrail.saveLog('Login', 'Login');

          params = params.set('appId', 'test');
          params = params.set('appKey', 'on');
          params = params.append('token', this.cnf.token);
          this.router.navigate(['/dashboard']);
        } else {
          this.loadingSignin = false;
          this.loginErrorSwal.fire();
        }
      },
      err => {
        console.log(err);
        this.loadingSignin = false;
        this.loginErrorSwal.fire();
      }
    );
  }

  showErrorNotFoundMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorActivationNotFoundSwal.fire();
    }, 100);
  }
}
