export class PolicyInfoPayment {
  public policyNo: string;
  public transactionType: string;
  public transactionDate: string;
  public dueTime: string;
  public description: string;
  public moneyName: string;
  public amount: string;
  public total: number;
  public totalPage: number;
  public page: string;
  public per_page: string;
  public start_date: string;
  public end_date: string;
}
