import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
const SecureStorage = require('secure-web-storage');
import * as process from './ZW52aXJvbm1lbnQ=.json';
const cf = JSON.parse(atob(process.process));

@Injectable()
export class StorageService {
  constructor() { }
  public secureStorage = new SecureStorage(localStorage, {
    // Encrypt the localstorage data

    hash: function hash(key:any) {
      key = CryptoJS.SHA256(key, cf.text);

      return key.toString();
    }, encrypt: function encrypt(data:any) {
      data = CryptoJS.AES.encrypt(data, cf.ens);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data:any) {
      data = CryptoJS.AES.decrypt(data, cf.ens);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });
}