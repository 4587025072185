import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import * as CryptoJS from 'crypto-js';
import { OrderPipe } from 'ngx-order-pipe';
import { FundService } from '../../services/fund.service';
import { APIResponse } from '../../models/apiresponse';
import { UserService } from '../../services/user.service';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-daily-nav',
  templateUrl: './daily-nav.component.html',
  styleUrls: ['./daily-nav.component.scss'],
  providers: [Config, AuditTrail, FundService, UserService]
})
export class DailyNavComponent implements OnInit {
  page = 4;
  //params: any;
  fundNameData: any = [];
  h1: any = [];
  h2: any = [];
  dailyNavData: any = [];
  percentage: any;
  dateStart: any;
  dateEnd: any;
  modelStart;
  modelEnd;
  modelFundName;
  public loadingData: boolean = false;
  public userId: any = '';
  order: string = 'percentage';
  reverse: boolean = false;
  attoken: string = '';
  projectName: string = 'fund';

  constructor(private route: ActivatedRoute,
    private cnf: Config, private auditTrail: AuditTrail,
    private titleService: Title, private http: HttpClient,
    private cacheService: CacheService,
    private fund: FundService,
    private user: UserService,
    private orderPipe: OrderPipe,
    private localService: LocalService) {
    // this.route.params.subscribe( params => this.params = params );
  }

  decryptData(encryptData) {
    let decodeString = atob(encryptData);

    let key = CryptoJS.enc.Utf8.parse(this.cnf.yek.enc);
    let decrypted = CryptoJS.AES.decrypt(decodeString, key,
      {
        keySize: 128 / 8,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

    let decryptData = JSON.parse(decrypted);

    return decryptData;
  }

  ngOnInit() {
    console.log('tess');
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    this.titleService.setTitle(this.cnf.prefixTitle + 'Daily NAV' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');

    this.user.getAttoken(params).subscribe((response: any) => {
      let resp: APIResponse<any> = response.body;
      if (resp.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, resp.datas);
        params = params.append('attoken', this.attoken);
      }

      this.cacheService.get(this.cnf.lang + '/fund_name/',
        this.fund.loadName().subscribe((res: any) => {
          let result: APIResponse<any> = res.body;
          this.fundNameData = result.datas;
          this.loadingData = true;
        },
          err => {
            console.log(err);
          }));

      this.cacheService.get(this.cnf.lang + '/fund_daily_nav_h1/',
        this.fund.loadH1().subscribe((res: any) => {
          let result: APIResponse<any> = res.body;
          let decryptData = JSON.parse(this.cnf.decryptData(result.datas));

          this.h1 = decryptData[0].fund_daily_nav;

          this.cacheService.get(this.cnf.lang + '/fund_daily_nav_h2/',
            this.fund.loadH2()).subscribe((respon: any) => {
              let resul: APIResponse<any> = respon.body;
              let decData = JSON.parse(this.cnf.decryptData(resul.datas));

              this.h2 = decData[0].fund_daily_nav;

              let h1 = this.h1;
              let h2 = this.h2;
              this.loadMerge(h1, h2);
            });
        },
          err => {
            console.log(err);
          }));
    },
      err => {
        console.log(err);
      });
  }

  loadH1() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);

    return this.fund.loadH1();

    // return this.http.post( this.cnf.URLWSNonPublicArea + '/fund/daily-nav/h-1/', paramsObject )
    //   .pipe(map((response: Response) => response));
  }

  loadH2() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);
    return this.fund.loadH2();
    // return this.http.post( this.cnf.URLWSNonPublicArea + '/fund/daily-nav/h-2/', paramsObject )
    //   .pipe(map((response: Response) => response));
  }

  loadMerge(endDate, startDate) {
    let output = [];

    for (var i in endDate) {
      let dataEndDate = endDate[i];

      for (var i in startDate) {
        let dataStartDate = startDate[i];

        if (dataEndDate.fundName == dataStartDate.fundName) {
          let dataStartDateSplit = dataStartDate.pricingDate.split('-');
          let dataEndDateSplit = dataEndDate.pricingDate.split('-');
          this.dateStart = new Date(parseInt(dataStartDateSplit[0]), parseInt(dataStartDateSplit[1]) - 1, parseInt(dataStartDateSplit[2])); // yy,mm-1,dd
          this.dateEnd = new Date(parseInt(dataEndDateSplit[0]), parseInt(dataEndDateSplit[1]) - 1, parseInt(dataEndDateSplit[2]));

          let rumus = ((dataEndDate.bidPrice - dataStartDate.bidPrice) / dataStartDate.bidPrice) * 100;
          let percentageResult = Math.round(rumus * 10000) / 10000;

          output.push({
            'fundName': dataEndDate.fundName,
            'pricingDateStart': dataStartDate.pricingDate,
            'pricingDateEnd': dataEndDate.pricingDate,
            'bidPriceDateStart': Number(dataStartDate.bidPrice),
            'bidPriceDateEnd': Number(dataEndDate.bidPrice),
            'percentage': percentageResult,
            'moneyCode': dataEndDate.moneyCode,
          })
        }
      }
    }

    this.dailyNavData = this.orderPipe.transform(output, 'percentage');

  }

  loadDataByDate(startDate, endDate) {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);

    return this.fund.loadDataByDate(startDate, endDate);

    // return this.http.post( this.cnf.URLWSNonPublicArea + '/fund/daily-nav/'+startDate+'/'+endDate, paramsObject )
    //   .pipe(map((response: Response) => response));
  }

  loadMergeSearch(dateStart, dateEnd, results) {
    let outputStart = [];
    let outputEnd = [];
    for (var i in results) {
      let result = results[i];
      let dateObj = new Date(result.pricingDate);
      let dateDay = dateObj.getDate();
      let dateMonth = dateObj.getMonth() + 1;
      let dateYear = dateObj.getFullYear();
      let resultDate = dateYear + '-' + dateMonth + '-' + dateDay;

      if (resultDate == dateStart) {

      }
    }
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  valuedate = new Date();
}
