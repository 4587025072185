import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as uuid from 'uuid';
import { Config } from '../config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifWaEmailSms {

  constructor(
    private http: HttpClient,
    private cnf: Config
  ) { }

  bcc: string = "muhammad.sodiq@chubb.com";
  from: string = "PT Chubb Life Insurance Indonesia <no_reply@chubb.com>";
  prefixSubject: string = "[UAT] - NOTIFIKASI CHUBBLIFE - ";

  sendNotification(email: any, mobile: any, policyCode: any, featureApp: any) {

    var toPhone = "085258713694";
    var toMail = "muhammad.sodiq@chubb.com";
    if (environment.production) {
      toPhone = mobile;
      toMail = email;
      this.prefixSubject = "NOTIFIKASI CHUBBLIFE - ";
    }

    this.SendEmail(policyCode, toMail, featureApp)
    this.SendSms(policyCode, toPhone, featureApp)
    this.SendWA(policyCode, toPhone, featureApp)
  }

  SendEmail(policyCode: any, email: any, featureApp: any) {
    const myId = uuid.v4();
    const body = {
      bulkID: myId,
      data: {
        messages: [
          {
            attachment: "",
            bcc: this.bcc,
            cc: "",
            from: this.from,
            html: this.TemplateEmail(policyCode, featureApp),
            messageID: "",
            subject: this.prefixSubject + policyCode,
            to: email
          }
        ]
      },
      deliverID: 4,
      senderID: 3
    }
    this.http.post(this.cnf.URLMicroServices + '/email/sendMultipleEmail', body).subscribe((res: any) => {
    }, err => {
      console.log('Something went wrong!');
    })
  }

  template: string;
  TemplateEmail(policyCode: any, featureApp: any) {
    this.template = "<!DOCTYPE html><html><head><title>Page Title</title></head><body><p>Pemegang Polis Yth.</p><p> Terima kasih atas pengajuan Perubahan Polis Nomor " + policyCode + ". Kami akan proses dan tindak lanjuti. Apabila Anda memerlukan informasi lain, silakan menghubungi Customer Service kami pada hari Senin-Jumat pukul 08:00 sampai dengan 18:00  WIB di:</p><table><tr><td>Customer Corner</td><td>:</td><td>https://customercorner.chubblife.co.id</td></tr><tr><td>Alamat e-mail </td><td>:</td><td>Customer.Service.IDLife@Chubb.com </td></tr><tr><td>WhatsApp </td><td>:</td><td>+6281584814087 </td></tr><tr><td>Nomor telepon </td><td>:</td><td>021-2356-8887 </td></tr><tr><td></td><td></td><td>14087 (area Jabodetabek)</td></table><p>Terima kasih atas perhatian dan kerjasama Anda</p><p>*this email is being generated automatically by system</p></body></html>";
    return this.template;
  }

  SendSms(policyCode: any, mobile: any, featureApp: any) {
    let str = mobile.slice(1);
    let mobileFix = "62" + str;
    const myId = uuid.v4();
    const body = {
      bulkID: myId,
      data: {
        messages: [
          {
            messageID: "",
            text: this.TemplateSms(policyCode, featureApp),
            to: mobileFix
          }
        ]
      },
      deliverID: 1,
      senderID: 3
    }
    this.http.post(this.cnf.URLMicroServices + '/sms/sendMultipleSMS', body).subscribe((res: any) => {
      console.log("send sms")
    }, err => {
      console.log('Something went wrong!');
    })
  }

  TemplateSms(policyCode: any, featureApp: any) {
    let template = "Pemegang Polis Yth. Terima kasih atas pengajuan perubahan Polis Nomor " + policyCode + ". Kami akan tindaklanjuti. Informasi hub CS 0212356887"
    return template;
  }

  SendWA(policyCode: any, mobile: any, featureApp: any) {
    const myId = uuid.v4();
    let str = mobile.slice(1);
    let mobileFix = "62" + str;
    const body = {
      bulkID: myId,
      data: {
        messages: [
          {
            content: [policyCode],
            messageID: "",
            to: mobileFix,
            waformatID: 5
          }
        ]
      },
      deliverID: 3,
      senderID: 3
    }

    this.http.post(this.cnf.URLMicroServices + '/wa/sendMultipleWA', body).subscribe((res: any) => {
      console.log("send wa")
    }, err => {
      console.log('Something went wrong!');
    })
  }
}
