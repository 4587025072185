export class PersonalData {
  public birthday: string;
  public correspondenceAddress1: string;
  public correspondenceAddress2: string;
  public correspondenceAddress3: string;
  public correspondenceAddress4: string;
  public correspondenceAddress5: string;
  public correspondenceAddress6: string;
  public correspondenceAddress7: string;
  public correspondencePostCode: string;
  public correspondenceState: string;
  public customerId: string;
  public email: string;
  public email2: string;
  public gender: string;
  public homeAddress1: string;
  public homeAddress2: string;
  public homeAddress3: string;
  public homeAddress4: string;
  public homeAddress5: string;
  public homeAddress6: string;
  public homeAddress7: string;
  public homePostCode: string;
  public homeState: string;
  public homeTel: string;
  public idCardNumber: string;
  public idType: string;
  public mobile: string;
  public officeAddress1: string;
  public officeAddress2: string;
  public officeAddress3: string;
  public officeAddress4: string;
  public officeAddress5: string;
  public officeAddress6: string;
  public officeAddress7: string;
  public officePostCode: string;
  public officeState: string;
  public officeTel: string;
  public policyHolderName: string;
  public firstName: string;
  public userId: string;
  public fullName: string;
}
