import { Injectable } from '@angular/core';
import { Config } from '../config';
import { APIService } from './api.service';
import { RESTService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private cnf: Config, private api: APIService, private rest: RESTService) { }
  getPolicies(userId, page, perPage) {
    const url = `${this.cnf.URLCpService}/customer/header/userid/${userId}/${page}/${perPage}`;
    // const url = `http://localhost:3000/polis/${page}`;

    return this.rest.get(`${url}`);
  }
  getPolisDetail(polisNo) {
    const url = `${this.cnf.URLCpService}/customer/detail/policy/${polisNo}`;
    // const url = `http://localhost:3000/polis-detail/${polisNo}`;

    return this.rest.get(`${url}`);
  }
  reqRequirement(body: string) {
    const _url = `${this.cnf.URLCpService}/cc/request-payment/`;
    return this.rest.post<any>(_url, body);
  }
  detailPayment(id: string) {
    const _url = this.cnf.URLCpService + '/paymentGateway/detail-payment/' + id;
    return this.rest.get<any>(_url);
  }
}
