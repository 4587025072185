import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { PaymentService } from '../../services/payment.service';
import { RESTResponse } from '../../models/restresponse';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [Config, AuditTrail]
})
export class PaymentComponent implements OnInit {
  public userId: any = '';
  page = 1;
  policyData: any = [];
  public loadingData: boolean = false;

  // paging
  currentPage: any = 1;
  perPage: any = 10;
  totalPages: any = 1;
  total: any = 0;
  totalDataPagination: any = 1;
  previousPage: any = 1;

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private http: HttpClient,
    private cacheService: CacheService,
    private paymentService: PaymentService,
    private _location: Location,
    private localService: LocalService
  ) { }

  ngOnInit() {
    this.auditTrail.saveLog('My Data Payment Policy Information', 'Open');
    this.titleService.setTitle(this.cnf.prefixTitle + 'Payment Policy Information' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    // this.loadData().subscribe((res: any) => {
    //   this.loadingData = true;

    //   if (res.status == 100) {
    //     let decryptData = JSON.parse(this.cnf.decryptData(res.datas));
    //     this.policyData = decryptData[0].policyInformation_header;

    //     this.total = decryptData[0].total;
    //     this.totalDataPagination = decryptData[0].total;
    //     this.totalPages = decryptData[0].total_page;
    //   }
    // });
    this.loadData();
  }

  loadPage(page: string) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.currentPage = page;
      this.loadData();
    }
  }

  loadData() {
    this.loadingData = false;

    return this.paymentService.getPolicies(this.userId, this.currentPage, this.perPage).subscribe((res: any) => {
      this.loadingData = true;
      const response: RESTResponse<any> = res.body;
      this.policyData = response.data.policyInfoHeaderDto;
      this.loadingData = true;

      this.total = response.data.total;
      this.totalDataPagination = response.data.total;
      this.totalPages = response.data.total_page;
    });
  }

  backClicked() {
    this._location.back();
  }
}
