import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Config } from '../../config'; 
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { AboutService } from '../../services/about.service';
import { APIResponse } from '../../models/apiresponse';

@Component({
  selector: 'app-about-chubb-life',
  templateUrl: './about-chubb-life.component.html',
  styleUrls: ['./about-chubb-life.component.scss'],
	providers: [ Config, AuditTrail, AboutService ]
})
export class AboutChubbLifeComponent implements OnInit {
  public data : any = {};
  public loadingData : boolean = false;
  public preview: any = '';
  public token: any = '';

  constructor( private cnf: Config, private auditTrail: AuditTrail,
    private titleService: Title, private http: HttpClient,
    private cacheService: CacheService,
    private about: AboutService,
    private route: ActivatedRoute ) {
    this.route.queryParams.subscribe(params => {
      this.preview = params['preview'];
      this.token = params['token'];
    });
  }

  ngOnInit() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let url = this.cnf.URLWS + '/about_chubb_life';
    if( this.preview ) {
      params = params.append('token', this.token);
      url = url + '/preview/' + this.preview;
    } else {
      url = url + '/frontend/all';
    }

    this.cacheService.get('about_chubb_life',
      this.about.post(params, url).subscribe((res: any) => {
        const response: APIResponse<any> = res.body;
        this.loadingData = true;
        if (response.status === 100) {
          this.data = response.datas;
          this.titleService.setTitle(this.cnf.prefixTitle + this.data.title + this.cnf.postfixTitle);
        }
      },
      err => {
        console.log(err);
    }));
  }

}
