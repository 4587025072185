import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';

import { Router, ActivatedRoute } from '@angular/router';
import { CacheService } from '../../services/cache.service';
import { CustomerService } from '../../services/customer.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { Subscription } from 'rxjs/Subscription';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  listColors = ['magenta', 'blue', 'green', 'orange', 'red', 'tosca', 'violet'];

  @Input('type') type: string = 'page'; // input prop to control type of header (page/non-public)
  @Input('color') colorHeader: string = 'magenta'; // input prop for color header
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  isCollapsed: boolean;
  public lang: any;
  token: any;
  userData: any = {};
  linkMenu: any = {};
  search: any;
  public userId: any = '';
  personalDataSubscription: Subscription;
  uriSegment: any;
  secondBreadcrumb: any;
  showDashboard: boolean = false;
  lastLogin: any = '';
  errorMessage: string = '';
  data: any = '';
  attoken: string = '';
  projectName: string = 'inbox-blast';
  showWhiteDash = false;
  routerLinkBack = ['/dashboard'];

  isback: boolean = false;
  isbackName: string;

  constructor(
    private cacheService: CacheService,
    private auditTrail: AuditTrail,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private cnf: Config,
    private http: HttpClient,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    private localService: LocalService,
  ) {
    this.isCollapsed = true;
  }

  ngOnInit() {
    const indexColor = this.listColors.findIndex(color => color === this.colorHeader);
    if (indexColor === -1) {
      this.colorHeader = 'magenta';
    }

    this.cacheService.get('/assets/config/menu.json', this.loadLinkMenu()).subscribe((res: any) => {
      this.linkMenu = res;
    });
    this.loadTranslate();
    this.userId = this.localService.getJsonValue('userid');
    //this.userId = localStorage.getItem('userid');
    this.token = this.localService.getJsonValue('token') ? this.localService.getJsonValue('token') : '';
    this.subscribePersonalDataSubject();

    if (this.type === 'non-public') {
      // if type header is non-public
      this.lastLogin = new Date(this.localService.getJsonValue('lastLogin')); // get last login from localstorage
      this.showDashboard = true; // show dashboard menu
      this.uriSegment = this.router.url;
      let uriSegments: any = this.router.parseUrl(this.uriSegment).root.children['primary'].segments; // extract uri segment
      let uri1, uri2;
      this.showWhiteDash = true;
      const activeRoute: any = this.activatedRoute.snapshot.url;
      if (this.uriSegment === '/dashboard') {
        this.showDashboard = false;
      }

      uri1 = uriSegments[0].path;

      if (uriSegments.length > 1) {
        uri2 = uriSegments[1].path;

        if (uri2 === 'proposal-information' && uriSegments.length === 3) {
          this.secondBreadcrumb = '_proposal_information';
          this.routerLinkBack = ['/my-data/proposal-information'];
        } else if (uri2 === 'policy-information' && uriSegments.length === 3) {
          this.secondBreadcrumb = '_policy_information';
          this.routerLinkBack = ['/my-data/policy-information'];
        } else if (uri1 === 'history-detail' && uriSegments.length === 2) {
          this.secondBreadcrumb = '_history';
          this.routerLinkBack = ['/claim/history'];
        } else if (uri1 === 'inbox-detail' && uriSegments.length === 2) {
          this.secondBreadcrumb = '_inbox';
          this.routerLinkBack = ['/inbox'];
        } else if (
          (activeRoute.length && activeRoute[0].path === 'payment' && activeRoute[1].path === 'success') ||
          (activeRoute.length && activeRoute[0].path === 'payment' && activeRoute[1].path === 'error') ||
          (activeRoute.length && activeRoute[0].path === 'payment' && activeRoute[1].path === 'detail')
        ) {
          this.secondBreadcrumb = 'Pembayaran';
          this.routerLinkBack = ['/payment'];
        } else if (activeRoute.length && activeRoute[0].path === 'payment' && activeRoute[1].path === 'va') {
          this.secondBreadcrumb = 'Detail Pembayaran';
          this.routerLinkBack = ['/payment/detail', activeRoute[2].path];
        } else if (uri2 === 'change-frequency' || uri2 === 'req-med' || uri2 === 'req-polis' || uri2 === 'update-occup'
          || uri2 === 'Document-UnComplete' || uri2 === 'req-change-bene' || uri2 === 'req-change-topup' || uri2 === 'switching'
          || uri2 === 'premium-redirection' || uri2 === 'update-credit-card' || uri2 === 'update-policy-holder' || uri2 === 'premium-holiday') {
          this.isback = true;
          this.isbackName = 'Detail Polis';
        }
      }

      // check token
      this.cnf.getAttoken().subscribe((res: any) => {
        if (res.status == 100) {
          this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
        }
        this.loadNotification().subscribe((res: any) => {
          if (res.status == 100) {
            let datas = res.datas;
            this.data = datas;
          }
        });
      });
      if (this.token === '') {
        this.router.navigate(['/']);
      } else {
        this.loadToken().subscribe((res: any) => {
          if (res.status != 100) {
            this.showErrorMessage(res.message);
            setTimeout(() => {
              this.doLogout();
            }, 500);
          }
        });
      }
    }
  }

  /**
   * @desc show error swal-alert
   * @param message
   */
  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.swalError.fire();
    }, 500);
  }

  /**
   * @desc Load translate
   */
  loadTranslate() {
    this.lang = this.cnf.lang;

    this.translate.addLangs([this.lang]);
    this.translate.setDefaultLang('id');
    this.translate.use(this.lang);
  }

  /**
   * @desc Load Notification
   */
  loadNotification() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('attoken', this.attoken);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/inbox_blast/frontend/notification/' + this.userId, paramsObject)
      .pipe(map((response: Response) => response));
  }

  /**
   * @desc change Language
   */
  changeLanguage(lang: string) {
    this.localService.setJsonValue('lang', lang);
    location.reload();
  }

  /**
   * @desc go to search page
   */
  doSearch() {
    this.router.navigate(['/result-page/', this.search]);
  }

  /**
   * @desc logout action
   */
  doLogout() {
    this.auditTrail.saveLog('Home', 'Logout');
    setTimeout(() => {
      let params = new HttpParams();
      params = params.append('appId', 'test');
      params = params.append('appKey', 'on');
      params = params.append('token', this.cnf.token);
      let paramsObject = this.cnf.convertPostData(params);
      var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
      this.http.post(url, paramsObject).subscribe(res => {
        this.cnf.clearLocalStorage();
        this.router.navigate(['/']);
        location.reload();
      });
    }, 100);
  }

  /**
   * @desc Load Link menu
   */
  loadLinkMenu() {
    return this.http.get('/assets/config/menu.json').pipe(map((response: Response) => response));
  }

  /**
   * @desc Load Token
   */
  loadToken() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/checkToken', paramsObject)
      .pipe(map((response: Response) => response));
  }

  /**
   * @desc go to previous page using angular location
   */
  backClicked() {
    this.location.back();
  }
  addCloseClass() {
    var x = document.getElementById('burger');
    x.classList.toggle('change');
  }

  /**
   * @desc Subscribe For Personal Data
   */
  subscribePersonalDataSubject() {
    // check if personal data on customer service is empty
    const personalDataEmpty = this.customerService.personalDataEmptyStatus;

    // if personalDataEmpty is empty will get data from customer service
    if (personalDataEmpty && this.userId) {
      this.customerService.setPersonalData(this.userId, this.cnf.token);
    }

    // listen to personal data BehaviorSubject
    if (this.personalDataSubscription) {
      this.personalDataSubscription.unsubscribe();
    }
    this.personalDataSubscription = this.customerService.personalDataSubject.subscribe(personalData => {
      if (!this.customerService.checkEmpty(personalData)) {
        this.userData = personalData;
      }
    });
  }
}
