import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIService } from './api.service';

@Injectable()
export class NotificationService {

  constructor(private cnf: Config, private api: APIService, private http: HttpClient) { }

  load(params: HttpParams, idUser: any, data?: {}) {
    let paramsObject = this.cnf.convertPostData(params);
    if (data) {
      paramsObject = this.cnf.convertPostData(params, data);
      return this.api.post(this.cnf.URLWSNonPublicArea + '/inbox_blast/frontend/userid/' + idUser, paramsObject);
    } else {
      return this.api.post(this.cnf.URLWSNonPublicArea + '/inbox_blast/frontend/notification/' + idUser, paramsObject);
    }
  }
}
