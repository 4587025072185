import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { APIResponse } from '../../models/apiresponse';
import { LocalService } from '../../local.service';


@Component({
  selector: 'app-notification',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  providers: [Config, AuditTrail, UserService, NotificationService]
})
export class InboxComponent implements OnInit {
  public userId: any = '';
  page = 1;
  inboxData: any = [];
  public loadingData: boolean = false;
  selectedAll: any;

  // paging
  currentPage: any = 1;
  perPage: any = 10;
  totalPages: any = 1;
  total: any = 0;
  totalDataPagination: any = 1;
  previousPage: any = 1;
  attoken: string = '';
  projectName: string = 'inbox-blast';

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private notification: NotificationService,
    private user: UserService,
    private http: HttpClient,
    private cacheService: CacheService,
    private _location: Location,
    private localService: LocalService
  ) { }

  backClicked() {
    this._location.back();
  }

  ngOnInit() {
    this.auditTrail.saveLog('Inbox', 'Open');
    this.titleService.setTitle(this.cnf.prefixTitle + 'Inbox' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');

    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    this.user.getAttoken(params).subscribe(
      (res: any) => {
        let resp: APIResponse<any> = res.body;
        if (resp.status === 100) {
          this.attoken = this.cnf.generateAttoken(this.projectName, resp.datas);
        }

        params = params.append('token', this.cnf.token);
        params = params.append('lang', this.cnf.lang);
        params = params.append('attoken', this.attoken);

        let data = {
          page: this.currentPage,
          perPage: this.perPage
        };

        this.notification.load(params, this.userId, data).subscribe(
          (response: any) => {
            let respon: APIResponse<any> = response.body;
            this.loadingData = true;

            if (respon.status === 100) {
              this.inboxData = respon.datas.inbox;
              this.total = respon.datas.total;
              this.totalDataPagination = respon.datas.total;
              this.totalPages = respon.datas.total_page;
            }
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  loadDeleteData(inboxId) {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);
    params = params.append('attoken', this.attoken);

    let data = {
      userid: this.userId
    };

    let paramsObject = this.cnf.convertPostData(params, data);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/inbox_blast/frontend/delete/' + inboxId, paramsObject)
      .pipe(map((response: Response) => response));
  }

  selectAll() {
    for (var i = 0; i < this.inboxData.length; i++) {
      this.inboxData[i].selected = this.selectedAll;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.inboxData.every(function (item: any) {
      return item.selected == true;
    });
  }

  doDelete() {
    this.deleteData(0);
    this.auditTrail.saveLog('Inbox', 'Delete All Inbox');
  }

  deleteData(i) {
    let dataTemp = this.inboxData[i];

    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);
    params = params.append('attoken', this.attoken);

    if (dataTemp.selected == true) {
      this.loadDeleteData(dataTemp.id).subscribe((res: any) => {
        if (i + 1 == this.inboxData.length) {
          this.user.getAttoken(params).subscribe(
            (resp: any) => {
              let respon: APIResponse<any> = resp.body;
              if (respon.status == 100) {
                this.attoken = this.cnf.generateAttoken(this.projectName, respon.datas);
              }

              let data = {
                page: this.currentPage,
                perPage: this.perPage
              };

              this.notification.load(params, this.userId, data).subscribe(
                (response: any) => {
                  let respons: APIResponse<any> = response.body;
                  this.inboxData = [];
                  this.loadingData = true;

                  if (respons.status == 100) {
                    this.inboxData = respons.datas.inbox;
                    this.total = respons.datas.total;
                    this.totalPages = respons.datas.total_page;
                  }
                },
                err => {
                  console.log(err);
                }
              );
            },
            err => {
              console.log(err);
            }
          );
        } else {
          let x = i + 1;
          this.deleteData(x);
        }
      });
    } else {
      if (i + 1 == this.inboxData.length) {
        this.user.getAttoken(params).subscribe(
          (res: any) => {
            let respon: APIResponse<any> = res.body;
            if (respon.status == 100) {
              this.attoken = this.cnf.generateAttoken(this.projectName, respon.datas);
            }

            let data = {
              page: this.currentPage,
              perPage: this.perPage
            };

            this.notification.load(params, this.userId, data).subscribe(
              (response: any) => {
                let respons: APIResponse<any> = response.body;
                this.inboxData = [];
                this.loadingData = true;

                if (respons.status == 100) {
                  this.inboxData = respons.datas.inbox;
                  this.total = respons.datas.total;
                  this.totalPages = respons.datas.total_page;
                }
              },
              err => {
                console.log(err);
              }
            );
          },
          err => {
            console.log(err);
          }
        );
      } else {
        let x = i + 1;
        this.deleteData(x);
      }
    }
  }
}
