import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Location } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from '../../ngb-date-fr-parser-formatter';
import { ClaimService } from '../../services/claim.service';
import { APIResponse } from '../../models/apiresponse';
import { PolicyService } from '../../services/policy.service';
import { LocalService } from '../../local.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [Config, AuditTrail, ClaimService, PolicyService, { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class HistoryComponent implements OnInit {
  @ViewChild('SessionExpired', { static: false }) private loginErrorSwal: SwalComponent;

  public data: any = {};
  public userId: any = '';
  public loadingData: boolean = false;
  page = 1;
  historyData: any = [];
  firstLoading: boolean = false;

  policyData: any = [];
  statusClaimData: any = [];
  modelPolicyNo: any = "";
  modelStatus: any = "";

  // paging
  historyCurrentPage: any = 1;
  historyPerPage: any = 10;
  historyTotalPages: any = 1;
  historyTotal: any = 0;
  historyTotalDataPagination: any = 1;
  historyPreviousPage: any = 1;
  modelStartHistory: any;

  constructor(
    private api: APIService,
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private claim: ClaimService,
    private policy: PolicyService,
    private _location: Location,
    private localService: LocalService) {

  }

  ngOnInit() {
    this.auditTrail.saveLog('Claim History', 'Open');
    this.titleService.setTitle(this.cnf.prefixTitle + 'History' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');

    this.doGetSearch();
    this.loadPolicy().subscribe((res: any) => {
      const response: APIResponse<any> = res.body;
      if (response.status === 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(response.datas));
        this.policyData = decryptData[0].policyInformation_header;
      }
    },
      err => {
        console.log(err);
      });

    this.loadStatusClaim().subscribe((res: any) => {
      const respon: APIResponse<any> = res.body;
      if (respon.status === 100) {
        let datas = respon.datas[0].claim_status;
        let dataOutput = [];
        for (let i in datas) {
          let dataTemp = datas[i];
          if (dataTemp.indexOf('+') !== -1) {
            dataTemp = dataTemp.replace('+', '');
            this.modelStatus = dataTemp;
          }
          dataOutput.push(dataTemp);
        }
        this.statusClaimData = dataOutput;
      }
    },
      err => {
        console.log(err);
      });


    this.loadHistory().subscribe((resp: any) => {
      const response: APIResponse<any> = resp.body;
      this.firstLoading = true;
      this.loadingData = true;
      if (response.status === 100) {
        this.historyData = response.datas[0].claim_header;

        this.historyTotal = response.datas[0].total;
        this.historyTotalDataPagination = response.datas[0].total;
        this.historyTotalPages = response.datas[0].total_page;
      } else {
        this.historyData = [];
        this.historyTotal = 0;
        this.historyTotalPages = 0;
        this.historyTotalDataPagination = 1;
      }
    }).add(() => {
      this.firstLoading = true;
      this.loadingData = true;
    });
  }

  doSearchHistory() {
    this.auditTrail.saveLog("Claim Tracking", "Search History");
    this.loadingData = false;

    this.historyPreviousPage = 1;
    this.historyCurrentPage = 1;
    this.historyData = [];
    this.doSaveSearch();

    this.loadHistory().subscribe((res: any) => {
      this.loadingData = true;
      const response: APIResponse<any> = res.body;
      if (response.status === 100) {
        this.historyData = response.datas[0].claim_header;
        this.historyTotal = response.datas[0].total;
        this.historyTotalPages = response.datas[0].total_page;
      } else {
        this.historyData = [];
        this.historyTotal = 0;
        this.historyTotalPages = 0;
        this.historyTotalDataPagination = 1;
      }
    });
  }

  doSaveSearch() {
    this.localService.setJsonValue("history_modelPolicyNo", this.modelPolicyNo);
    this.localService.setJsonValue("history_modelStartHistory", JSON.stringify(this.modelStartHistory));
    this.localService.setJsonValue("history_modelStatus", this.modelStatus);
  }

  doGetSearch() {
    this.modelPolicyNo = (this.localService.getJsonValue("history_modelPolicyNo") ? this.localService.getJsonValue("history_modelPolicyNo") : '');
    this.modelStartHistory = (this.localService.getJsonValue("history_modelStartHistory") && this.localService.getJsonValue("history_modelStartHistory") != "" ? JSON.parse(this.localService.getJsonValue("history_modelStartHistory")) : '');
    this.modelStatus = (this.localService.getJsonValue("history_modelStatus") ? this.localService.getJsonValue("history_modelStatus") : '');
  }

  loadPage(page: string) {
    if (page !== this.historyPreviousPage) {
      this.loadingData = false;
      this.historyData = [];

      this.historyPreviousPage = page;
      this.historyCurrentPage = page;

      this.loadHistory().subscribe((res: any) => {
        this.loadingData = true;
        const response: APIResponse<any> = res.body;
        if (response.status === 100) {
          this.historyData = response.datas[0].claim_header;
        } else {
          this.historyData = [];
          this.historyTotal = 0;
          this.historyTotalPages = 0;
          this.historyTotalDataPagination = 1;
        }
      });
    }
  }

  loadPolicy() {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('per_page', '100');
    return this.policy.load(params, this.userId);
  }

  loadStatusClaim() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let data = {
      'page': 1,
      'per_page': 100
    };
    return this.claim.loadStatus(this.userId, null, params, data);
    //let paramsObject = this.cnf.convertPostData(params, data);

    //return this.http.post(this.cnf.URLWSNonPublicArea + '/claim/header/allstatusclaim/' + this.userId, paramsObject)
    //  .pipe(map((response: Response) => response));
  }

  loadHistory() {
    let modelStartHistory = '';
    if (this.modelStartHistory) {
      modelStartHistory = this.modelStartHistory.year + '-' + ('0' + this.modelStartHistory.month).slice(-2) + '-' + ('0' + this.modelStartHistory.day).slice(-2);
    }

    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');

    params = params.append('token', this.cnf.token);

    let data = {
      'page': this.historyCurrentPage,
      'per_page': this.historyPerPage,
      'policyno': this.modelPolicyNo,
      'admissiondate': modelStartHistory,
      'status': this.modelStatus
    };

    let paramsObject = this.cnf.convertPostData(params, data);
    // return this.claim.loadHistory(this.userId, null, params, data);

    return this.api.post(this.cnf.URLWSNonPublicArea + '/claim/header/claim/' + this.userId, paramsObject);
  }

  backClicked() {
    this._location.back();
  }

  doResetFilter() {
    this.modelPolicyNo = '';
    this.modelStartHistory = '';
    this.modelStatus = '';

    this.doSearchHistory();
  }

}
