import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Ipay88Service } from '../../services/ipay88.service';
import { Ipay88 } from '../../models/ipay88.model';
@Component({
  selector: 'app-ipay88',
  templateUrl: './ipay88.component.html'
})
export class Ipay88Component implements OnInit {
  @ViewChild('ePaymentForm', { static: false }) ePaymentForm: ElementRef; // get form
  payment: Ipay88 = new Ipay88();
  constructor(private ipay88Service: Ipay88Service) {
    // subscribe to paymentModel
    this.ipay88Service.paymentModel.subscribe(data => {
      this.payment = data;
      // console.table(this.payment);
      if (Object.entries(data).length !== 0 && this.ePaymentForm) {
        setTimeout(() => {
          this.ePaymentForm.nativeElement.submit(); // submit payment form
        }, 1000);
      }
    });
  }

  ngOnInit() { }
}
