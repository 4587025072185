import { FundCustomer } from "./FundCustomer";
import { PremiumHolidayDTO } from "./premium-holiday-dto";

export class ChangeRequest {
    public policyCode: string;
    public mainLifeAssured: string;
    public typeRequest: string;
    public title: string;
    public note: string;
    public subject: string;
    public idCard: string;
    public freqBefore: string;
    public freqAfter: string;
    public paymentMode: string;
    public listFund: FundCustomer[] = [];
    public expiredDateCardBefore: string;
    public expiredDateCardAfter: string;
    public policyHolderBefore: string;
    public policyHolderAfter: string;
    public designation: string;
    public idCardNew: string;
    public file1: string;
    public file2: string;
    public file3: string;
    public file4: string;
    public file5: string;
    public emailPolicyHolderAfter: string;
    public mobilePolicyHolderAfter: string;
    public premiumHoliday: PremiumHolidayDTO[] = [];
    public unPremiumHoliday: PremiumHolidayDTO[] = [];
    public typePremium: string;
}