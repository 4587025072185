import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ipay88 } from '../models/ipay88.model';
import { Subject } from 'rxjs';
import { Config } from '../config';
import { RESTService } from './rest.service';
@Injectable({
  providedIn: 'root'
})
export class Ipay88Service {
  paymentModel: Subject<Ipay88> = new Subject<Ipay88>();

  constructor(private _http: HttpClient, private cnf: Config, private rest: RESTService) {}
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  reqRequirement(body: any) {
    const _url = `${this.cnf.URLCpService}/paymentGateway/cc/request-payment/`;
    return this.rest.post<any>(_url, body);
  }
  checkPayment(body: string) {
    const _url = `${this.cnf.URLCpService}/paymentGateway/cc/check-payment/`;
    return this._http.post<any>(_url, body, this.headers);
  }
}
