import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { UserInternal } from '../../models/user-internal.model';
import { UserService } from '../../services/user.service';
import { APIResponse } from '../../models/apiresponse';
import { FundService } from '../../services/fund.service';
import { NotificationService } from '../../services/notification.service';
import { NgxCarousel } from 'ngx-carousel';
import { NgImageSliderComponent } from 'ng-image-slider';
import { SliderImage } from '../../models/slider-image';
import { CustomerService } from '../../services/customer.service';
import { Subscription } from 'rxjs/Subscription';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [Config, AuditTrail, FundService, UserService, NotificationService]
})
export class HomeComponent implements OnInit {
  @ViewChild('loginErrorSwal', { static: false }) private loginErrorSwal: SwalComponent;
  @ViewChild('swal', { static: false }) private swal: SwalComponent;
  @ViewChild('slider', { static: false }) slider: NgImageSliderComponent;

  public h1: any = [];
  public h2: any = [];
  public dateStart: any;
  public dateEnd: any;
  public dailyNavData: any = [];
  public userInternal: UserInternal;
  public carouselOne: NgxCarousel;
  public dataCarousel: any = [];
  //public dataCarousel: IImage[] = [];
  //public dataCarousel: Object[] = [];
  height: string = '400px';
  minHeight: string;
  arrowSize: string = '30px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = true;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = '100%';
  fullscreen: boolean = false;
  enableZoom: boolean = false;
  enablePan: boolean = false;
  noLoop: boolean = false;
  public data: any;
  public personalData: any;
  public res: any;
  public loadingBanner: boolean = false;
  public loadingNav: boolean = false;
  public loadingSignin: boolean = false;
  public defaultImage: any;
  public userId: any = '';
  public idUser: any = '';
  public loadingData: boolean = false;
  public checkTime: boolean = false;
  public userData: any = {};
  public token: any;
  public lastLogin: any = '';
  linkMenu: any = {};
  public datas: any = '';
  safeSrc: SafeResourceUrl;
  public preview: any = '';
  public previewToken: any;
  public bannerBorderColor: any = 'banner-bl-tosca';
  attoken: string = '';
  attokenNotification: string;
  projectName: string = 'fund';
  public swalData: any = {};

  constructor(

    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private http: HttpClient,
    private cacheService: CacheService,
    private user: UserService,
    private fund: FundService,
    private notification: NotificationService,
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private _ngZone: NgZone,
    private customerService: CustomerService,
    private localService: LocalService,
    private sanitizer: DomSanitizer
  ) {
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://chubbcs.nadila.net/ccmsvconf/");
    this.route.queryParams.subscribe(params => {
      this.preview = params['preview'];
      this.previewToken = params['token'];

      let colors = [
        'banner-bl-blue',
        'banner-bl-orange',
        'banner-bl-tosca',
        'banner-bl-red',
        'banner-bl-green',
        'banner-bl-magenta',
        'banner-bl-yellow',
        'banner-bl-purple'
      ];
      this.bannerBorderColor = colors[3];
    });
  }
  personalDataSubscription: Subscription;
  ngOnInit() {

    var myDate = {
      "checkinStartDate": "01/10/2021 09:00:00 AM",
      "checkinEndDate": "01/10/2021 06:00:00 PM"
    }

    var checkInStart = new Date(myDate.checkinStartDate);
    var checkInEnd = new Date(myDate.checkinEndDate);

    var current = new Date();

    //only enable during this time
    if ((current.getTime() > checkInStart.getTime()) && (current.getTime() < checkInEnd.getTime())) {
      this.checkTime = true;
    } else {
      this.checkTime = false;
    }

    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('apptype', this.cnf.apptype);

    this.cacheService.get('/assets/config/menu.json', this.loadLinkMenu()).subscribe((res: any) => {
      this.linkMenu = res;
    });
    this.titleService.setTitle(this.cnf.prefixTitle);
    this.token = this.cnf.token;
    this.lastLogin = new Date(this.localService.getJsonValue('lastLogin'));
    this.localService.getJsonValue('userid');

    this.userInternal = new UserInternal({});
    // Carousel
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner'
    };

    this.data = this.cacheService.get(this.cnf.lang + '/banner', this.loadData()).subscribe((res: any) => {
      this.loadingBanner = true;
      if (res.status === 100) {
        let slides: SliderImage[] = res.datas;
        for (let slide of slides) {
          if (slide) {
            this.dataCarousel.push(slide);
          }
        }
      } else {
        this.dataCarousel.length = 0;
      }
    });

    if (this.token) {
      this.user.getAttoken(params).subscribe(res => {
        const respon: APIResponse<any> = res.body;
        if (respon.status === 100) {
          let projectName = 'inbox-blast';
          this.attokenNotification = this.cnf.generateAttoken(projectName, respon.datas);
        }
        let params2 = new HttpParams();
        params2 = params2.set('appId', 'test');
        params2 = params2.set('appKey', 'on');
        params2 = params2.append('attoken', this.attokenNotification);
        params2 = params2.delete('apptype');

        this.notification.load(params2, this.userId).subscribe(
          (response: any) => {
            let respon: APIResponse<any> = response.body;
            if (respon.status == 100) {
              let resDatas = respon.datas;
              this.datas = resDatas;
            }
          },
          err => {
            console.log(err);
          }
        );
      });
      this.subscribePersonalDataSubject();

      this.user.getAttoken(params).subscribe(
        resp => {
          const respon: APIResponse<any> = resp.body;
          if (respon.status === 100) {
            this.attoken = this.cnf.generateAttoken(this.projectName, respon.datas);
            params = params.append('attoken', this.attoken);
          }
          this.cacheService.get(
            this.cnf.lang + '/fund_daily_nav_h1/',
            this.fund.loadH1().subscribe(
              (res: any) => {
                let result: APIResponse<any> = res.body;
                let decryptData = JSON.parse(this.cnf.decryptData(result.datas));

                this.h1 = decryptData[0].fund_daily_nav;

                this.cacheService.get(this.cnf.lang + '/fund_daily_nav_h2/', this.fund.loadH2()).subscribe(
                  (respon: any) => {
                    let resul: APIResponse<any> = respon.body;
                    let decData = JSON.parse(this.cnf.decryptData(resul.datas));

                    this.h2 = decData[0].fund_daily_nav;

                    let h1 = this.h1;
                    let h2 = this.h2;
                    this.loadMerge(h1, h2);
                  },
                  err => {
                    console.log(err);
                  }
                );
              },
              err => {
                console.log(err);
              }
            )
          );
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  loadLinkMenu() {
    return this.http.get('/assets/config/menu.json').pipe(map((response: Response) => response));
  }

  prevImageClick() {
    this.slider.prev();
  }

  nextImageClick() {
    this.slider.next();
  }

  loadData() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let url = this.cnf.URLWS + '/banner';
    if (this.preview) {
      params = params.append('token', this.previewToken);
      url = url + '/preview/' + this.preview;
      let paramsObject = this.cnf.convertPostData(params);
      return this.http.post(url, paramsObject).pipe(map((response: Response) => response));
    } else {
      let paramsObject = this.cnf.convertPostData(params);
      url = this.cnf.URLCpService + '/banner/frontend/all/' + this.cnf.lang;
      return this.http.get(url).pipe(map((response: Response) => response));
    }

  }

  splitJsonData(output, parts: number) {
    let _output = [];
    let x: number = 0;

    for (let i in output) {
      if (Number(i) % parts == 0) {
        x = Number(i) / parts;
        _output.push({
          data: []
        });
      }

      _output[x]['data'].push(output[i]);
    }
    return _output;
  }

  loadMerge(endDate, startDate) {
    let output = [];

    for (var i in endDate) {
      let dataEndDate = endDate[i];

      for (var i in startDate) {
        let dataStartDate = startDate[i];

        if (dataEndDate.fundName == dataStartDate.fundName) {
          let dataStartDateSplit = dataStartDate.pricingDate.split('-');
          let dataEndDateSplit = dataEndDate.pricingDate.split('-');
          this.dateStart = new Date(
            parseInt(dataStartDateSplit[0]),
            parseInt(dataStartDateSplit[1]) - 1,
            parseInt(dataStartDateSplit[2])
          ); // yy,mm-1,dd
          this.dateEnd = new Date(
            parseInt(dataEndDateSplit[0]),
            parseInt(dataEndDateSplit[1]) - 1,
            parseInt(dataEndDateSplit[2])
          );

          output.push({
            fundName: dataEndDate.fundName,
            pricingDateStart: dataStartDate.pricingDate,
            pricingDateEnd: dataEndDate.pricingDate,
            bidPriceDateStart: dataStartDate.bidPrice,
            bidPriceDateEnd: dataEndDate.bidPrice,
            moneyCode: dataEndDate.moneyCode
          });
        }
      }
    }

    this.dailyNavData = this.splitJsonData(output, 3);
  }

  onInternalSubmit({ value, valid }: { value: UserInternal; valid: boolean }) {
    this.userInternal = value;
    this.loadingSignin = true;

    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    const salt = bcrypt.genSaltSync(12);

    let data = {
      userId: this.userInternal.email,
      password: this.cnf.encryptData(this.userInternal.password)
    };

    this.user.login(params, data).subscribe(
      (res: any) => {
        let resp: APIResponse<any> = res.body;
        if (resp.status == 100) {
          this.userId = resp.datas.userId;

          this.localService.setJsonValue('token', resp.token);
          this.localService.setJsonValue('userid', resp.datas.userId);
          this.localService.setJsonValue('lastLogin', resp.datas.lastLogin);
          this.localService.setJsonValue('islogin', 'Y');
          let ack = localStorage.getItem('img')
          if (!ack) {
            localStorage.setItem('img', '01')
          }

          this.cnf.token = resp.datas.token;
          this.cnf.userId = resp.datas.userId;
          this.loadingSignin = false;
          this.router.navigate(['/dashboard']);
        } else {
          this.loadingSignin = false;
          let oops = this.translate.instant('_oops');
          let message = this.translate.instant(resp.message);
          this.swalData = {
            title: oops,
            message: message,
            type: 'error'
          };
          this.showSwal();
        }
      },
      err => {
        this.loadingSignin = false;
        console.log(err);

        let oops = this.translate.instant('_oops');
        let message = "";
        message = this.translate.instant('_login_failed_invalid_user_id_or_password');

        this.swalData = {
          title: oops,
          message: message,
          type: 'error'
        };
        this.showSwal();
      }
    );
  }

  doLogout() {
    this.auditTrail.saveLog('Home', 'Logout');
    setTimeout(() => {
      let params = new HttpParams();
      params = params.append('appId', 'test');
      params = params.append('appKey', 'on');
      params = params.append('token', this.cnf.token);
      let paramsObject = this.cnf.convertPostData(params);
      var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
      this.http.post(url, paramsObject).subscribe(res => {
        this.cnf.clearLocalStorage();
        this.router.navigate(['/']);
        location.reload();
      });
    }, 100);
  }

  doLoadBanner(url: any) {
    var a = url;
    if (a.match(/^[a-zA-Z0-9_:\.//-]*$/)) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = a;
      link.click();
      link.remove();
    }
  }

  showSwal() {
    setTimeout(() => {
      this.swal.fire();
    }, 100);
  }

  subscribePersonalDataSubject() {
    // check if personal data on customer service is empty
    const personalDataEmpty = this.customerService.personalDataEmptyStatus;

    // if personalDataEmpty is empty will get data from customer service
    if (personalDataEmpty && this.userId) {
      this.customerService.setPersonalData(this.userId, this.cnf.token);
    }

    // listen to personal data BehaviorSubject
    if (this.personalDataSubscription) {
      this.personalDataSubscription.unsubscribe();
    }
    this.personalDataSubscription = this.customerService.personalDataSubject.subscribe(personalData => {
      if (!this.customerService.checkEmpty(personalData)) {
        this.userData = personalData;
      }
    });
  }

  checkDate() {
    var ONE_HOUR = 60 * 60 * 1000; /* ms */
    var myDate = {
      "checkinStartDate": "10/22/2016 09:10:04 AM",
      "checkinEndDate": "10/22/2016 12:10:04 PM"
    }

    var checkInStart = new Date(myDate.checkinStartDate);
    var checkInEnd = new Date(myDate.checkinEndDate);

    var current = new Date();

    //only enable during this time
    if ((current.getTime() < checkInStart.getTime() + ONE_HOUR) && (current.getTime() + ONE_HOUR > checkInEnd.getTime()))
      return false;


    //otherwhile disable  
    return true;
  }


  ngOnDestroy() {
    if (this.personalDataSubscription) {
      this.personalDataSubscription.unsubscribe();
    }
    this.cnf.removeRecaptchaBadge();
  }

  showPwd(passId: any, pwdId: any) {
    var ps = document.getElementById(passId);
    var fa = document.getElementById(pwdId);
    if ("fas fa-eye-slash" == fa.className) {
      fa.classList.remove('fa-eye-slash');
      fa.classList.add('fa-eye');
      ps.setAttribute('type', 'text');
    } else {
      fa.classList.remove('fa-eye');
      fa.classList.add('fa-eye-slash');
      ps.setAttribute('type', 'password');
    }
  }
}
