import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpParams, HttpResponse } from '@angular/common/http';

import { MinorAlterationModel } from '../models/minor-alteration.model';
import { RESTRequest } from '../models/restrequest.model';

import { APIService } from './api.service';
import { RESTService } from './rest.service';
import { ReqModel } from '../models/reqModel';
import { RequestWithdrawal } from '../models/request-withdrawal.model';
import { ReqUpdateOccup } from '../models/req-update-occup.model';
import { ReqBene } from '../models/req-bene.model';
import { BeneDto } from '../models/bene-dto.model';

@Injectable()
export class MinorAlterationService {
  constructor(private cnf: Config, private api: APIService, private rest: RESTService) { }

  getMinorAlterationData(userId: string) {
    return this.rest.get<MinorAlterationModel>(this.cnf.URLCustomerService + '/personaldata/alteration/' + userId);
  }

  submitMinor(request: RESTRequest<MinorAlterationModel>, userid: string) {
    return this.rest.post(this.cnf.URLCustomerService + `/personaldata/user/${userid}`, request);
  }

  submitReqMed(request: RESTRequest<ReqModel>, userid: string) {
    return this.rest.put(this.cnf.URLCustomerService + `/reprintMed/${userid}`, request);
  }

  submitReqUpdate(request: RESTRequest<ReqUpdateOccup>, userid: string) {
    return this.rest.put(this.cnf.URLCustomerService + `/reqUpdateOccup/${userid}`, request);
  }

  submitReqUpdateBene(request: RESTRequest<ReqBene>, userid: string, polisno: any, fullname: any) {
    return this.rest.put(this.cnf.URLCustomerService + `/updateBene/${userid}/${polisno}/${fullname}`, request);
  }

  submitReqWithdrawal(request: RESTRequest<RequestWithdrawal>) {
    return this.rest.put(this.cnf.URLCustomerService + `/requestWithdrawal`, request);
  }
}
