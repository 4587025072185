import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CacheService } from '../../services/cache.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { UserActivation } from '../../models/user-activation.model';
import { UserService } from '../../services/user.service';
import { CustomerService } from '../../services/customer.service';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
  providers: [Config, AuditTrail, UserService]
})

export class ActivationComponent implements OnInit {
  errorMessage: string;
  @ViewChild('successActivationSwal', { static: false }) private successActivationSwal: SwalComponent;
  @ViewChild('errorActivationSwal', { static: false }) private errorActivationSwal: SwalComponent;
  @ViewChild('errorActivationNotFoundSwal', { static: false }) private errorActivationNotFoundSwal: SwalComponent;
  @ViewChild('activationTimeoutSwal', { static: false }) private activationTimeoutSwal: SwalComponent;

  params: any;
  userActivation: UserActivation;
  isOTP: boolean = false;
  loadingButton: boolean = false;
  otpCode: any = '';
  handphone: any = '';
  activationCountdown: any;
  resendActivationCountdown: any = new Date();
  resendActivationButton: any = true;
  isActivationPopup: any = true;
  isResendActivationTimeout: any = true;
  activationTime: any = 5;
  resendOtpCodeTime: any = 1;
  public barLabel: string = 'Password strength:';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  // passwordPattern: any = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^]).{8,}$';
  passwordPattern: any = '^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]).{8,}$';
  public googleRecaptchaKey: any = '';
  termOfUseLink: any = '';
  otpResponse: any = {};

  activation: string = '';
  returnUrl: string = '';

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private route: ActivatedRoute,
    private user: UserService,
    private http: HttpClient,
    private cacheService: CacheService,
    private router: Router,
    private translate: TranslateService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private customerService: CustomerService,
    private localService: LocalService
  ) {
    this.route.params.subscribe(params => {
      this.params = params;
      this.activation = params['activation'];
    });
  }

  ngOnInit() {
    this.termOfUseLink = '/term-of-use';
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;

    let currentLang = this.localService.getJsonValue('lang');
    this.cnf.clearLocalStorage();
    this.localService.setJsonValue('lang', currentLang);
    this.userActivation = new UserActivation({
    });

    let decodeString = atob(this.params.activationId);

    let key = CryptoJS.enc.Utf8.parse(this.cnf.yek.enc);
    let decrypted = CryptoJS.AES.decrypt(decodeString, key, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    let decryptData = decrypted ? JSON.parse(decrypted) : '';

    this.userActivation.userId = decryptData.userId;
    this.userActivation.activationCode = decryptData.activationCode;
    this.userActivation.type = decryptData.type ? decryptData.type : '';
    if (this.userActivation.type != 'forgotpassword') {
      this.titleService.setTitle(this.cnf.prefixTitle + 'Activation' + this.cnf.postfixTitle);
    } else {
      this.titleService.setTitle(this.cnf.prefixTitle + 'Forgot Password' + this.cnf.postfixTitle);
    }
  }

  onInternalSubmit({ value, valid }: { value: UserActivation; valid: boolean }) {
    this.loadingButton = true;
    // let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^]).{8,}$/;
    let passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]).{8,}$/;

    if (!this.userActivation.email || !this.userActivation.password || !this.userActivation.retypePassword) {
      this.showErrorMessage('Input form masih ada yang kosong');
      this.loadingButton = false;
    } else {
      if (!passwordRegex.test(this.userActivation.password)) {
        this.showErrorMessage('Password harus berisi minimal 8 digit, kombinasikan huruf angka dan simbol');
        this.loadingButton = false;
      } else if (this.userActivation.password != this.userActivation.retypePassword) {
        this.showErrorMessage('Password yang Anda isi masih belum sama');
        this.loadingButton = false;
      } else {
        this.cnf.showRecaptchaBadge();
        this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'contact', captchaToken => {
          this.userActivation.captcha = captchaToken;
          this.sendRequestOtp('false').subscribe((res: any) => {
            this.loadingButton = false;
            if (res.status == 100) {
              window.scrollTo(0, 0);
              this.otpResponse = res.datas;
              this.isOTP = true;

              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.resendActivationCountdown = new Date().setMinutes(new Date().getMinutes() + this.resendOtpCodeTime);
            } else {
              let message = this.translate.instant(res.message);
              this.showErrorMessage(message);
            }
          });
        });
      }
    }
  }

  bcrypt(pass) {

    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);


    let data = {
      pass: btoa(pass),
      userName: this.userActivation.userId,
      password: ''
    };

    this.user.updatePassBcrypt(params, data).subscribe(
      (response: any) => { }
    );

  }

  submitActivation() {
    if (this.userActivation.otpCode) {
      this.loadingButton = true;

      this.sendActivation().subscribe((res: any) => {
        this.loadingButton = false;

        if (res.status == 100) {
          //          this.bcrypt(this.userActivation.retypePassword);
          this.successActivationSwal.fire();
          this.router.navigate(['/auth/login'], { queryParams: { activation: 'success' } });
        } else {
          let message = this.translate.instant(res.message);
          this.showErrorMessage(message);
        }
      });
    } else {
      this.errorActivationSwal.fire();
    }
  }

  resolved(captchaResponse: string) {
    this.userActivation.captcha = captchaResponse;
  }

  sendActivation() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      userid: this.userActivation.userId,
      activationid: this.userActivation.activationCode,
      otp: this.userActivation.otpCode,
      email: this.userActivation.email,
      password: this.userActivation.password,
      repassword: this.userActivation.retypePassword,
      type: this.userActivation.type
    };
    let paramsObject = this.cnf.convertPostData(params, data);

    // let req = new RESTRequest<ActivationRequest>();
    // req.appid = this.cnf.appid;
    // req.appkey = this.cnf.appkey;
    // req.data = data;
    // req.apptype = 'web';
    // req.token = '';
    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/activation', paramsObject)
      .pipe(map((response: Response) => response));
  }

  checkActivation() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      userid: this.userActivation.userId,
      activationid: this.userActivation.activationCode
    };
    let paramsObject = this.cnf.convertPostData(params, data);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/checkActivation', paramsObject)
      .pipe(map((response: Response) => response));
  }

  sessionExpired() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      userid: this.userActivation.userId,
      activationid: this.userActivation.activationCode
    };
    let paramsObject = this.cnf.convertPostData(params, data);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/sessionExpired', paramsObject)
      .pipe(map((response: Response) => response));
  }

  sendRequestOtp(resend) {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      userid: this.userActivation.userId,
      activationid: this.userActivation.activationCode,
      email: this.userActivation.email,
      captcha: this.userActivation.captcha,
      password: this.userActivation.password,
      repassword: this.userActivation.retypePassword,
      type: this.userActivation.type,
      resend: resend
    };
    let paramsObject = this.cnf.convertPostData(params, data);
    // let req = new RESTRequest<SendOtpRequest>();
    // req.appid = this.cnf.appid;
    // req.appkey = this.cnf.appkey;
    // req.data = data;
    // req.apptype = 'web';
    // req.token = this.userActivation.captcha;
    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/requestOTP', paramsObject)
      .pipe(map((response: Response) => response));
  }

  resendRequestOtp() {
    this.resendActivationButton = true;
    this.sendRequestOtp('true').subscribe((res: any) => {
      this.otpResponse.totalRequestOtp = this.otpResponse.totalRequestOtp + 1;
      this.isResendActivationTimeout = true;
      this.resendActivationCountdown = new Date().setMinutes(new Date().getMinutes() + 1);
    });
  }

  enableResendButton() {
    this.isResendActivationTimeout = false;
    this.resendActivationButton = false;

    if (this.otpResponse.totalRequestOtp >= this.otpResponse.maxRequestOtp) {
      this.returnUrl = '/';
      let message = this.translate.instant('_session_expired');
      this.showErrorNotFoundMessage(message);
    }
  }

  activationTimeout() {
    if (this.isActivationPopup == true) {
      this.returnUrl = '/';
      this.isActivationPopup = false;
      this.sessionExpired().subscribe();
      let message = this.translate.instant('_session_expired');
      this.showErrorNotFoundMessage(message);
    }
  }

  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorActivationSwal.fire();
    }, 100);
  }

  showErrorNotFoundMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorActivationNotFoundSwal.fire();
    }, 100);
  }

  doGoHome($event) {
    this.router.navigate([this.returnUrl]);
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }

  showPwd(passId: any, pwdId: any) {
    var ps = document.getElementById(passId);
    var fa = document.getElementById(pwdId);
    if ("fas fa-eye-slash" == fa.className) {
      fa.classList.remove('fa-eye-slash');
      fa.classList.add('fa-eye');
      ps.setAttribute('type', 'text');
    } else {
      fa.classList.remove('fa-eye');
      fa.classList.add('fa-eye-slash');
      ps.setAttribute('type', 'password');
    }
  }
}
