export class UserForgot {
    email: string;
    secretQuestions: string;
    secretAnswer: string;
    otpCode: string;
    captcha: string;
    birthday: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
