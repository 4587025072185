import { Component, OnInit } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { PaymentService } from '../../services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTResponse } from '../../models/restresponse';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
  providers: [Config, AuditTrail]
})
export class PaymentStatusComponent implements OnInit {
  params: any = '';
  public userId: any = '';
  policyDetailData: any = {};
  loadingPolicy = false;
  paymentStatus = null;

  // hardcoded is product TROP
  isTROP: boolean = false;
  isATU: boolean = false;
  bill: number = 0;

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const urlStatus = this.route.snapshot.url[1].path;
    this.paymentStatus = urlStatus === 'success' ? true : urlStatus === 'error' ? false : false;
    this.route.params.subscribe(params => (this.params = params));
  }
  ngOnInit() {
    this.loadingPolicy = true;
    const policyNo = this.params.policyNo.split('-')[0];
    this.paymentService
      .getPolisDetail(policyNo)
      .subscribe((response: any) => {
        const res: RESTResponse<any> = response.body;
        if (res.success) {
          this.policyDetailData = res.data[0];
          this.detailBill(this.params.policyNo);
          if (!this.policyDetailData.homeAddress1) {
            this.policyDetailData.homeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.homeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.homeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.homeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.homeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.homeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.homeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.homeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.homePostCode = this.policyDetailData.correspondencePostCode;
          } else if (!this.policyDetailData.officeAddress1) {
            this.policyDetailData.officeAddress1 = this.policyDetailData.correspondenceAddress1;
            this.policyDetailData.officeAddress2 = this.policyDetailData.correspondenceAddress2;
            this.policyDetailData.officeAddress3 = this.policyDetailData.correspondenceAddress3;
            this.policyDetailData.officeAddress4 = this.policyDetailData.correspondenceAddress4;
            this.policyDetailData.officeAddress5 = this.policyDetailData.correspondenceAddress5;
            this.policyDetailData.officeAddress6 = this.policyDetailData.correspondenceAddress6;
            this.policyDetailData.officeAddress7 = this.policyDetailData.correspondenceAddress7;
            this.policyDetailData.officeState = this.policyDetailData.correspondenceState;
            this.policyDetailData.officePostCode = this.policyDetailData.correspondencePostCode;
          }
        } else {
          console.log("errorr");
          this.router.navigate(['/404']);
        }
      })
      .add(() => {
        this.loadingPolicy = false;
      });
  }

  detailBill(id: any) {
    this.paymentService.detailPayment(id).subscribe((response: any) => {
      if (response.status == 200) {
        this.bill = response.body.premi;
        if (response.body.typePayment == "ATU") {
          this.isATU = true;
        } else {
          this.isATU = false;
        }
      }
    });
  }
}
