import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from '../../services/claim.service';
import { FileService } from '../../services/file.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

import { RESTRequest } from '../../models/restrequest.model';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RESTResponse } from '../../models/restresponse';
import { MinorAlterationService } from '../../services/minor-alteration.service';


import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, NgForm } from '@angular/forms';

import { FileProgress } from '../../models/file-progress';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';



import { MoneyList } from '../../models/money-list.model';
import { RequestWithdrawal } from '../../models/request-withdrawal.model';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, FileService, MinorAlterationService]
})
export class WithdrawalComponent implements OnInit {

  public googleRecaptchaKey: any = '';
  public swalData: any = {};
  isChecked = false;

  moneys: any[] = [];
  policyInvestmentData: any = [];
  policyDetailData: any = {};

  public show: boolean = false;

  maxWithdrawal: number = this.cnf.maxWithdrawal;
  minWithdrawal: number = this.cnf.minWithdrawal;

  reqRequest: RequestWithdrawal = new RequestWithdrawal();
  reqData: RequestWithdrawal = new RequestWithdrawal();

  dispatchTypes: Array<{ value: string; label: string }> = [
    { value: 'TRANSFER KE REKENING', label: 'TRANSFER KE REKENING' },
    { value: 'TRANSFER KE POLIS (UNTUK PEMBAYARAN PREMI)', label: 'TRANSFER KE POLIS (UNTUK PEMBAYARAN PREMI)' }
  ];

  @ViewChild('withdrawal', { static: false }) withdrawal: NgForm;

  curr: any;
  formGroup: FormGroup;
  successMessage: string;
  responseEmail: any;
  target: any;
  target2: any;
  target3: any;
  date: any;
  date2: any;
  date3: any;
  ext: any;
  ext2: any;
  ext3: any;
  dateFormat: any;
  newFileName: any;
  newFileName2: any;
  newFileName3: any;
  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress2: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress3: FileProgress = { loading: false, progress: 0 };

  identityCardFileName: string = null;
  accountBook: string = null;
  powerOfAttorneyName: string = null;

  fileClass: FileClass = new FileClass();
  fileClass2: FileClass = new FileClass();
  fileClass3: FileClass = new FileClass();
  private imageSrc: string = '';
  private imageSrc2: string = '';
  private imageSrc3: string = '';
  public data: any = {};
  public userId: any = '';
  errorMessage: string;
  params: any;
  loadingButton: boolean = false;

  loading = true;

  disclaimer = new FormControl(false);

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };


  constructor(
    private cnf: Config,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient,
    private auditTrail: AuditTrail,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private claim: ClaimService,
    private formBuilder: FormBuilder,
    private file: FileService,
    private minorAlterationService: MinorAlterationService,
    private translate: TranslateService,
    private cpSweetalertService: CpSweetalertService,
    private apiService: APIService,
    private localService: LocalService) {
    // disable address form group at init form
    // build form
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm();
  }

  ngOnInit() {
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.auditTrail.saveLog('Withdrawal', 'Withdrawal #' + this.params.policyNo);
    this.titleService.setTitle(this.cnf.prefixTitle + 'Request Reprint Medical' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');


    this.policyDetail()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          this.loading = true;
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyDetailData = decryptData[0].policyInformation_detail[0];


          this.loadPolicyInvestmentData();

          this.claim.getFormatMoney().subscribe(
            (res: any) => {
              const response: RESTResponse<MoneyList[]> = res.body;
              if (response.success) {

                for (let entry of response.data) {

                  let newMoneys = {
                    value: entry.moneyCode,
                    label: entry.moneyCode
                  };
                  this.moneys.push(newMoneys);
                }
              }
            },
            err => {
              console.error(err);
            }
          );
          this.loading = false;
        } else {
          this.router.navigate(['/404']);
        }
      });



  }

  loadPolicyInvestmentData() {
    this.policyInvestment()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyInvestmentData = decryptData[0].policyInformation_fund_invest;
        }
      });
  }

  policyInvestment() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(
    //     this.cnf.URLWSNonPublicArea + '/policy_information/fund_invest/policy/' + this.params.policyNo,
    //     paramsObject
    //   )
    //   .pipe(map((response: Response) => response));
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/fund_invest/policy/' + this.params.policyNo);
  }

  policyDetail() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + this.params.policyNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + this.params.policyNo);
  }

  /**
  * @desc Reset Form
  */
  resetForm() {
    this.withdrawal.resetForm();
  }

  /**
  * @desc Build formGroup
  */
  buildForm() {
    this.formGroup = this.formBuilder.group({

      amount: new FormControl(null, [Validators.required, Validators.max(this.maxWithdrawal), Validators.min(this.minWithdrawal)]),
      bankHolder: new FormControl(null),
      bankNumber: new FormControl(null),
      bankName: new FormControl(null),
      bankBranch: new FormControl(null),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      policyNo: new FormControl({ value: null, disabled: true }, Validators.required),
      email: new FormControl({ value: null, disabled: true }),
      identityCardPhoto: new FormControl(null, [Validators.required]),
      accountBook: new FormControl(null),
      powerOfAttorney: new FormControl(null),
      fundType: new FormControl(null, [Validators.required]),
      dispatchType: new FormControl(null, [Validators.required]),
      money: new FormControl(null, [Validators.required]),
      disclaimer: this.disclaimer
    });
  }


  /**
   * @desc on KTP selected function
   */
  onIdentityCardFileSelect(event) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }

  }

  onIdentityCardFileSelect3(event) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target3 = event.target.files[0];
    const ext3 = target3.type.split('/').pop();

    if (target3.size < 10485760 && (ext3 == "jpeg" || ext3 == "pdf" || ext3 == "png" || ext3 == "xbm" || ext3 == "tif" || ext3 == "pjp" || ext3 == "webp" || ext3 == "jpg")) {
      this.target3 = event.target.files[0];
      this.date3 = new Date();
      this.ext3 = this.target3.type.split('/').pop();
      this.dateFormat =
        this.date3.getFullYear() +
        '' +
        (this.date3.getMonth() + 1) +
        '' +
        this.date3.getDate() +
        '' +
        this.date3.getHours() +
        '' +
        this.date3.getMinutes();
      this.newFileName3 = this.makecustom(8) + this.dateFormat + '.' + this.ext3;

      reader.onload = this._handleReaderLoaded3.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onIdentityCardFileSelect2(event) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target2 = event.target.files[0];
    const ext2 = target2.type.split('/').pop();

    if (target2.size < 10485760 && (ext2 == "jpeg" || ext2 == "pdf" || ext2 == "png" || ext2 == "xbm" || ext2 == "tif" || ext2 == "pjp" || ext2 == "webp" || ext2 == "jpg")) {
      this.target2 = event.target.files[0];
      this.date2 = new Date();
      this.ext2 = this.target2.type.split('/').pop();
      this.dateFormat =
        this.date2.getFullYear() +
        '' +
        (this.date2.getMonth() + 1) +
        '' +
        this.date2.getDate() +
        '' +
        this.date2.getHours() +
        '' +
        this.date2.getMinutes();
      this.newFileName2 = this.makecustom(8) + this.dateFormat + '.' + this.ext2;

      reader.onload = this._handleReaderLoaded2.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e, event) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.identityCard = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  _handleReaderLoaded2(e, event) {
    let reader2 = event.target;
    this.imageSrc2 = reader2.result;
    this.fileClass2.fileDownloadUri = this.imageSrc2;
    this.fileClass2.fileName = this.newFileName2;
    this.fileClass2.fileType = this.ext2;
    this.fileClass2.size = this.target2.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass2).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const accountBook = response.data.fileDownloadUri;
          this.reqRequest.accountBook = accountBook;
          this.accountBook = this.newFileName2;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('accountBook').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress2 = { loading: false, progress: 0 };
        this.formGroup.get('accountBook').enable();
      }
    );
  }

  _handleReaderLoaded3(e, event) {
    let reader3 = event.target;
    this.imageSrc3 = reader3.result;
    this.fileClass3.fileDownloadUri = this.imageSrc3;
    this.fileClass3.fileName = this.newFileName3;
    this.fileClass3.fileType = this.ext3;
    this.fileClass3.size = this.target3.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass3).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const powerOfAttorney = response.data.fileDownloadUri;
          this.reqRequest.powerOfAttorney = powerOfAttorney;
          this.powerOfAttorneyName = this.newFileName3;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('powerOfAttorney').setValue(null);
        this.cpSweetalertService.show('Surat Kuasa Error', 'error');
      },
      () => {
        this.identityCardFileProgress3 = { loading: false, progress: 0 };
        this.formGroup.get('powerOfAttorney').enable();
      }
    );
  }

  /**
   * @desc create custom random char for file-name
   */
  makecustom(length) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  toggle(str: string) {
    this.curr = str;
  }

  toggle2() {
    const formValue = this.formGroup.value;
    if (formValue.fundType == null) {
      this.cpSweetalertService.show('Harap Pilih Jenis Investasi Dana Terlebih Dahulu', 'error');
    } else {
      if (this.curr != formValue.money) {
        this.cpSweetalertService.show('Mata Uang Wajib Sama Dengan Mata Uang Polis', 'error');
        this.formGroup.get('money').setValue(null);
      } else {
        this.cpSweetalertService.show('Minimal dana yang harus tersisa adalah Rp 5.000.000 (USD500) ', 'warning');
      }
    }

  }

  toggle3() {
    this.cpSweetalertService.show('Jika Berbeda dengan Nama Pemegang Polis Harap Untuk Melampirkan Surat Kuasa Bermaterai', 'warning');

  }

  toggle4() {
    const formValue = this.formGroup.value;
    if (formValue.dispatchType == 'TRANSFER KE REKENING') {
      this.show = true;
      this.formGroup.controls.bankHolder.setValidators([Validators.required]);
      this.formGroup.controls.bankNumber.setValidators([Validators.required]);
      this.formGroup.controls.bankName.setValidators([Validators.required]);
      this.formGroup.controls.bankBranch.setValidators([Validators.required]);
      this.formGroup.controls.accountBook.setValidators([Validators.required]);
    } else {
      this.formGroup.controls.bankHolder.clearValidators();
      this.formGroup.controls.bankHolder.updateValueAndValidity();
      this.formGroup.controls.bankNumber.clearValidators();
      this.formGroup.controls.bankNumber.updateValueAndValidity();
      this.formGroup.controls.bankName.clearValidators();
      this.formGroup.controls.bankName.updateValueAndValidity();
      this.formGroup.controls.bankBranch.clearValidators();
      this.formGroup.controls.bankBranch.updateValueAndValidity();
      this.formGroup.controls.accountBook.clearValidators();
      this.formGroup.controls.accountBook.updateValueAndValidity();

      this.show = false;
    }

  }

  /**
   * @desc on disclaimer checbox change
   */
  changeCheck(event) {
    // reset form Error Counter
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;

    this.errorAllCounter(this.formGroup);

    const count = this.formCountError.count;

    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      this.cpSweetalertService.show('_eclaim_not_complete_warning', 'warning');

    }
  }

  /**
   * @desc Check all error form input
   */
  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  /**
  * @desc fire validate form
  * @param formGroup
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
 * @desc on submit 
 */
  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqpolis', captchaToken => {
        const formValue = this.formGroup.value;
        this.reqRequest.dispatchType = formValue.dispatchType;
        this.reqRequest.policyCode = this.policyDetailData.policyNo;
        this.reqRequest.fundType = formValue.fundType;
        this.reqRequest.amount = formValue.amount;
        this.reqRequest.currency = formValue.money;
        this.reqRequest.bankHolder = formValue.bankHolder;
        this.reqRequest.bankName = formValue.bankName;
        this.reqRequest.bankBranch = formValue.bankBranch;
        this.reqRequest.bankNumber = formValue.bankNumber;

        const req = new RESTRequest<RequestWithdrawal>();
        req.appid = this.cnf.appid;
        req.appkey = this.cnf.appkey;
        req.data = this.reqRequest;
        req.apptype = 'web';
        req.token = captchaToken;
        this.minorAlterationService
          .submitReqWithdrawal(req)
          .subscribe(
            (res: any) => {
              const response = res.body;
              if (response.success) {
                this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima. hanya pengajuan sesuai dengan ketentuan dan prosedur yang akan disetujui oleh Chubb Life', 'warning');
                this.resetForm();
              }
            },
            err => {
              if (err.status === 400) {
                const msg = err.error;
                const message = this.translate.instant(msg.message);
                this.cpSweetalertService.show(message, 'error');
              } else {
                console.error(err);
                this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
              }
            }
          )
          .add(() => {
            this.loadingButton = false;
          });
      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

}
