import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AbstractControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ChangePassword } from '../../models/change-password.model';
import { UserService } from '../../services/user.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [Config, AuditTrail, UserService]
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('successChangePassword', { static: false }) private successChangePassword: SwalComponent;
  @ViewChild('errorChangePassword', { static: false }) private errorChangePassword: SwalComponent;

  public changePassword: ChangePassword;
  public barLabel: string = 'Password strength:';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public loadingButton: boolean = false;
  public errorMessage: any;
  // public passwordPattern: any = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^]).{8,}$';
  public passwordPattern: any = "^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]).{8,}$";
  public googleRecaptchaKey: any = '';

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private _location: Location,
    private translate: TranslateService,
    private user: UserService,
    private cpSweetalertService: CpSweetalertService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.auditTrail.saveLog('Account Setting Change Password', 'Open');
    this.titleService.setTitle(this.cnf.prefixTitle + 'Change Password' + this.cnf.postfixTitle);

    this.changePassword = new ChangePassword({ currentPassword: '', newPassword: '', retypePassword: '', captcha: '' });
  }

  /**
   * @desc on submit form
   * @param form
   */
  onInternalSubmit(form: NgForm) {
    // let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^]).{8,}$/;
    let passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]).{8,}$/;
    // validation
    if (this.changePassword.currentPassword === '') {
      this.showErrorMessage('Password saat ini tidak boleh kosong');
    } else if (this.changePassword.newPassword === '') {
      this.showErrorMessage('Password baru tidak boleh kosong');
    } else if (this.changePassword.retypePassword === '') {
      this.showErrorMessage('Ulangi password tidak boleh kosong');
    } else if (this.changePassword.retypePassword !== this.changePassword.newPassword) {
      this.showErrorMessage('Ulangi password tidak boleh kosong');
    } else if (!passwordRegex.test(this.changePassword.newPassword)) {
      this.showErrorMessage(
        'Password harus berisi minimal 8 digit, kombinasikan huruf angka dan simbol'
      );
    } else {
      this.loadingButton = true;

      this.cnf.showRecaptchaBadge();
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'contact', captchaToken => {
        this.changePassword.captcha = captchaToken;
        let params = new HttpParams();
        params = params.append('appId', this.cnf.appid);
        params = params.append('appKey', this.cnf.appkey);
        params = params.append('token', this.cnf.token);

        let data = {
          currentpassword: this.changePassword.currentPassword,
          password: this.changePassword.newPassword,
          repassword: this.changePassword.retypePassword,
          captcha: this.changePassword.captcha,
          userid: this.cnf.userId
        };

        this.user.updatePassword(params, data).subscribe((res: any) => {
          this.loadingButton = false;

          if (res.status === 200) {
            //            this.bcrypt(this.changePassword.newPassword);
            this.changePassword = new ChangePassword({ currentPassword: '', newPassword: '', retypePassword: '' });
            form.resetForm();
            this.successChangePassword.fire();
          } else {
            const message = 'Password gagal diubah';
            this.showErrorMessage(message);
          }
        });
      });
    }
    this.auditTrail.saveLog('Account Setting Change Password', 'Submit Reset Password');
  }

  bcrypt(pass) {

    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);


    let data = {
      pass: btoa(pass),
      userName: this.cnf.userId,
      password: ''
    };

    this.user.updatePassBcrypt(params, data).subscribe(
      (response: any) => { }
    );

  }

  /**
   * @desc event when resolver
   * @param captchaResponse
   */
  resolved(captchaResponse: string) {
    this.changePassword.captcha = captchaResponse;
  }

  /**
   * @desc show error message
   * @param message
   */
  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorChangePassword.fire();
    }, 100);
  }

  backClicked() {
    this._location.back();
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }

  showPwd(passId: any, pwdId: any) {
    var ps = document.getElementById(passId);
    var fa = document.getElementById(pwdId);
    if ("fas fa-eye-slash" == fa.className) {
      fa.classList.remove('fa-eye-slash');
      fa.classList.add('fa-eye');
      ps.setAttribute('type', 'text');
    } else {
      fa.classList.remove('fa-eye');
      fa.classList.add('fa-eye-slash');
      ps.setAttribute('type', 'password');
    }
  }

  @ViewChild('internalForm', { static: false }) ngForm: NgForm;
  checkPass() {
    var current = this.changePassword.currentPassword.toLocaleLowerCase();
    var newPass = this.changePassword.newPassword.toLocaleLowerCase();
    if (current == newPass) {
      this.ngForm.controls['newPassword'].setErrors({ same: 'Password tidak boleh sama dengan password sekarang' });
    } else {
      if (newPass.includes(current)) {
        this.ngForm.controls['newPassword'].setErrors({ same: 'Password tidak boleh mirip dengan password sekarang' });
      } else {
        var loopp = current.split('');
        var check = false;
        for (var i = 0; i < loopp.length; i++) {
          if (i < (loopp.length - 3)) {
            if (newPass.includes(current.substring(0, (loopp.length - (i + 1))))) {
              check = true;
            }
          }
        }
        if (check) {
          this.ngForm.controls['newPassword'].setErrors({ same: 'Password tidak boleh mirip dengan password sekarang' });
        }
      }
    }
  }
}
