import { Component, OnInit } from '@angular/core';
import { AuditTrail } from './../../audit-trail';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoPembayaranComponent implements OnInit {

  constructor(private auditTrail: AuditTrail) { }

  ngOnInit() {
    this.auditTrail.saveLog('Payment Video', 'Open');
  }

}
