export class MinorAlterationModel {
  public birthday: Date;
  public correspondenceAddress1: string = '';
  public correspondenceAddress2: string = '';
  public correspondenceAddress3: string = '';
  public correspondenceAddress4: string = '';
  public correspondenceAddress5: string = '';
  public correspondenceAddress6: string = '';
  public correspondenceAddress7: string = '';
  public correspondencePostCode: string = '';
  public correspondenceState: string = '';
  public dispatchType: string = '';
  public dispatchTypes?: DispatchType[];
  public email: string = '';
  public email2: string = '';
  public gender: string = '';
  public homeTel: string = '';
  public idCardNumber: string = '';
  public idType: string = '';
  public identityCardPhoto: string = '';
  public identityCardPhoto2: string = '';
  public mobile: string = '';
  public officeTel: string = '';
  public policyHolderName: string = '';
  public policyNumber: string = '';
  public policyNumbers?: PolicyNumbers[];
}
export class DispatchType {
  public dispatchType: number;
  public typeName: string;
}
export class PolicyNumbers {
  public policyCode: string;
  public policyId: number;
}
