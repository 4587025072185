import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalService } from './local.service';

@Injectable()
export class AuthGuard implements CanActivate {
    public token: string = '';

    constructor(private router: Router,
        private localService: LocalService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.token = this.localService.getJsonValue('token');

        if (this.token) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }
}
