export class SelfRegistration {
    policyNo: string;
    dateOfBirth: string;
    email: string;
    phone: string;
    captcha: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
