export class ReqModel {
  public dispatchType: string = '';
  public dispatchTypes?: DispatchType[];
  public address: string = '';
  public address2: string = '';
  public addresses?: Addresses[];
  public identityCardPhoto: string = '';
  public lostCertificate: string = '';
  public receipt: string = '';
  public policyHolderName: string = '';
  public policyCode: string = '';
  public reason: string = '';
  public policyNumbers?: PolicyNumbers[];
  public email: string = '';
  public homeTel: string = '';
  public officeTel: string = '';
  public mobile: string = '';
  public subject: string = '';
  
}
export class DispatchType {
  public dispatchType: number;
  public typeName: string;
}
export class Addresses {
  public dispatchType: number;
  public typeName: string;
}
export class PolicyNumbers {
  public policyCode: string;
  public policyId: number;
}
