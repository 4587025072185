import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CpSweetalertService {
  sweetalertEvent: EventEmitter<any> = new EventEmitter();
  show(word: string, type: string, translate: boolean = true) {
    const alert = {
      status: 'show',
      type,
      word,
      translate
    };
    this.sweetalertEvent.emit(alert);
  }
  constructor() { }
}
