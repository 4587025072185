import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { APIResponse } from '../models/apiresponse';
import { APIRequest } from '../models/apirequest';
import { LocalService } from '../local.service';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  headers = new HttpHeaders({});

  constructor(private http: HttpClient, private localService: LocalService) { }

  get<T>(url: string, params?: HttpParams): Observable<HttpResponse<APIResponse<T>>> {
    var xtoken = "";
    if (this.localService.getJsonValue('token') != null && this.localService.getJsonValue('userid') != null) {
      xtoken = (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')));
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Auth-Token': xtoken
    });
    return this.http.get<APIResponse<T>>(url, { headers: this.headers, params: params, observe: 'response' });
  }
  post<T>(url: string, body?: string | APIRequest<any>, params?: HttpParams): Observable<HttpResponse<APIResponse<T>>> {
    var xtoken = "";
    if (this.localService.getJsonValue('token') != null && this.localService.getJsonValue('userid') != null) {
      xtoken = (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')));
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Auth-Token': xtoken
    });
    return this.http.post<APIResponse<T>>(url, body, { headers: this.headers, params: params, observe: 'response' });
  }
  put<T>(url: string, body?: string | APIRequest<any>, params?: HttpParams): Observable<HttpResponse<APIResponse<T>>> {
    var xtoken = "";
    if (this.localService.getJsonValue('token') != null && this.localService.getJsonValue('userid') != null) {
      xtoken = (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')));
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Auth-Token': xtoken
    });
    return this.http.put<APIResponse<T>>(url, body, { headers: this.headers, params: params, observe: 'response' });
  }
  delete<T>(url: string): Observable<HttpResponse<APIResponse<T>>> {
    var xtoken = "";
    if (this.localService.getJsonValue('token') != null && this.localService.getJsonValue('userid') != null) {
      xtoken = (btoa(this.localService.getJsonValue('token') + '#' + this.localService.getJsonValue('userid')));
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Auth-Token': xtoken
    });
    return this.http.delete<APIResponse<T>>(url, { headers: this.headers, observe: 'response' });
  }
}
