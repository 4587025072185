import { LocalizedDatePipe } from './localize-ddate.pipe';
import { ExcerptFilter } from './excerpt.pipe';
import { HighlightSearch } from './highlight.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClient, HttpClientModule } from '@angular/common/http';

// Providers
import { CacheService } from './services/cache.service';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxCarouselModule } from 'ngx-carousel';
import { NgImageSliderModule } from 'ng-image-slider';

// Gmap
import { AgmCoreModule } from '@agm/core';

import 'hammerjs';

// Translation
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';

// Loading Bar
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';

// Countdown Timer
import { CountdownTimerModule } from 'ngx-countdown-timer';

// Google Recaptcha
import { NgxCaptchaModule } from 'ngx-captcha';

// Reorder Sort
import { OrderModule } from 'ngx-order-pipe';

// User Idle
import { UserIdleModule } from 'angular-user-idle';

// User Tour
import { NgxAppTour } from 'ngx-app-tour';

import { SignaturePadModule } from 'angular2-signaturepad';

//  Page
import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { AboutComponent } from './public/about/about.component';
import { FooterComponent } from './general/footer/footer.component';
import { ProductComponent } from './public/product/product.component';
import { HospitalComponent } from './public/hospital/hospital.component';
import { NewsComponent } from './public/news/news.component';
import { AgentsComponent } from './public/agents/agents.component';
import { ContactComponent } from './public/contact/contact.component';
import { ActivationComponent } from './public/activation/activation.component';
import { RegistrationComponent } from './public/registration/registration.component';
import { LoginComponent } from './public/login/login.component';
import { ForgetPasswordComponent } from './public/forget-password/forget-password.component';
import { AwardsComponent } from './public/awards/awards.component';
import { FormDownloadsComponent } from './public/form-downloads/form-downloads.component';
import { FaqComponent } from './public/faq/faq.component';
import { PaymentPublicComponent } from './public/payment/paymentPublic.component';
import { OfficeNetworkComponent } from './public/office-network/office-network.component';
import { DetailAwardComponent } from './public/detail-award/detail-award.component';
import { DetailNewsComponent } from './public/detail-news/detail-news.component';
import { FinancialReportComponent } from './public/financial-report/financial-report.component';
import { AboutChubbLifeComponent } from './public/about-chubb-life/about-chubb-life.component';
import { DashboardComponent } from './non-public/dashboard/dashboard.component';
import { MenuDashboardHorizontalComponent } from './non-public/menu-dashboard-horizontal/menu-dashboard-horizontal.component';
import { MenuDashboardVerticalComponent } from './non-public/menu-dashboard-vertical/menu-dashboard-vertical.component';
import { PersonalDataComponent } from './non-public/personal-data/personal-data.component';
import { ProposalInformationComponent } from './non-public/proposal-information/proposal-information.component';
import { PolicyInformationComponent } from './non-public/policy-information/policy-information.component';
import { ProposalDetailComponent } from './non-public/proposal-detail/proposal-detail.component';
import { EducationCenterComponent } from './public/education-center/education-center.component';
import { DetailProductComponent } from './public/detail-product/detail-product.component';
import { ContactProductComponent } from './public/contact-product/contact-product.component';
import { PolicyDetailComponent } from './non-public/policy-detail/policy-detail.component';
import { TransactionComponent } from './non-public/transaction/transaction.component';
import { TrackingComponent } from './non-public/tracking/tracking.component';
import { HistoryComponent } from './non-public/history/history.component';
import { UnitPriceComponent } from './non-public/unit-price/unit-price.component';
import { FundFactsheetComponent } from './non-public/fund-fact-sheet/fund-fact-sheet.component';
import { InboxComponent } from './non-public/inbox/inbox.component';
import { InboxDetailComponent } from './non-public/inbox-detail/inbox-detail.component';
import { ChartRupiahEquityFundComponent } from './non-public/chart-rupiah-equity-fund/chart-rupiah-equity-fund.component';
import { ChartRupiahSyariahBondFundComponent } from './non-public/chart-rupiah-syariah-bond-fund/chart-rupiah-syariah-bond-fund.component';
import { PerformanceComponent } from './non-public/performance/performance.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { TermOfUseComponent } from './public/term-of-use/term-of-use.component';

import { TermsOfUseComponent } from './public/terms-of-use/terms-of-use.component';
import { DailyNavComponent } from './public/daily-nav/daily-nav.component';
import { ResultPageComponent } from './public/result-page/result-page.component';
import { TipsTrickComponent } from './public/tips-trick/tips-trick.component';
import { DetailTipsTrickComponent } from './public/detail-tips-trick/detail-tips-trick.component';
import { FundMonthlyComponent } from './non-public/fund-monthly/fund-monthly.component';
import { FundYearlyComponent } from './non-public/fund-yearly/fund-yearly.component';
import { HistoryDetailComponent } from './non-public/history-detail/history-detail.component';
import { CashlessTrackingDetailComponent } from './non-public/cashless-tracking-detail/cashless-tracking-detail.component';
import { ReimbursementTrackingDetailComponent } from './non-public/reimbursement-tracking-detail/reimbursement-tracking-detail.component';
import { CustomerServiceComponent } from './non-public/customer-service/customer-service.component';
import { CustomerServiceDetailComponent } from './non-public/customer-service-detail/customer-service-detail.component';
import { ChangePasswordComponent } from './non-public/change-password/change-password.component';
import { MinorAlterationComponent } from './non-public/minor-alteration/minor-alteration.component';
import { ReqMedComponent } from './non-public/req-med/req-med.component';
import { NotfoundComponent } from './general/notfound/notfound.component';
import { environment } from '../environments/environment';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { EqualValidator } from './equal-validator.directive';
import { AuthGuard } from './AuthGuard';
import { EclaimComponent } from './non-public/eclaim/eclaim.component';
import { RESTService } from './services/rest.service';
import { Config } from './config';
import { TextFieldModule } from '@angular/cdk/text-field';
import { APIService } from './services/api.service';
import { LocalService } from './local.service';

// Validation
import { NgxErrorsModule } from '@hackages/ngxerrors';

// Material
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRadioModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ValidationService } from './services/validation.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CpSweetalertComponent } from './general/cp-sweetalert/cp-sweetalert.component';
import { LoadingProgressComponent } from './general/loading-progress/loading-progress.component';
import { HeaderComponent } from './general/header/header.component';
import { PaymentComponent } from './non-public/payment/payment.component';
import { PaymentDetailComponent } from './non-public/payment-detail/payment-detail.component';
import { Ipay88Component } from './general/ipay88/ipay88.component';
import { PaymentStatusComponent } from './non-public/payment-status/payment-status.component';
import { PaymentVaComponent } from './non-public/payment-va/payment-va.component';
import { VideoComponent } from './non-public/video/video.component';
import { VideoPembayaranComponent } from './non-public/videoPembayaran/video.component';
import { VideoClaimComponent } from './non-public/videoClaim/video.component';
import { VideoClaimReimComponent } from './non-public/videoClaimReim/video.component';
import { VideoCCComponent } from './non-public/videoCC/video.component';
import { VideoMinorComponent } from './non-public/videoMinor/video.component';
import { StorageService } from './storage.service';
import { ReqPolisComponent } from './non-public/req-polis/req-polis.component';
import { WithdrawalComponent } from './non-public/withdrawal/withdrawal.component';
import { UpdateOccupComponent } from './non-public/update-occup/update-occup.component';
import { PolicyCheckComponent } from './non-public/policy-check/policy-check.component';
import { ReqChangeBeneComponent } from './non-public/req-change-bene/req-change-bene.component';
import { ReqChangeTopupComponent } from './non-public/req-change-topup/req-change-topup.component';
import { DocumentUncompleteComponent } from './non-public/document-uncomplete/document-uncomplete.component';
import { ChangeFrequencyComponent } from './non-public/change-frequency/change-frequency.component';
import { SwitchingComponent } from './non-public/switching/switching.component';
import { PremiumRedirectionComponent } from './non-public/premium-redirection/premium-redirection.component';
import { UpdateCreditCardComponent } from './non-public/update-credit-card/update-credit-card.component';
import { UpdatePolicyHolderComponent } from './non-public/update-policy-holder/update-policy-holder.component';
import { PremiumHolidayComponent } from './non-public/premium-holiday/premium-holiday.component';
import { ChatUsComponent } from './general/chat-us/chat-us.component';
import * as process from './ZW52aXJvbm1lbnQ=.json';
const cf = JSON.parse(atob(process.process));

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about/about-chubb',
    component: AboutComponent
  },
  {
    path: 'about/financial-report',
    component: FinancialReportComponent
  },
  {
    path: 'about/about-chubb-life',
    component: AboutChubbLifeComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'term-of-use',
    component: TermOfUseComponent
  },
  {
    path: 'product',
    component: ProductComponent
  },
  {
    path: 'hospital',
    component: HospitalComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/:id',
    component: DetailNewsComponent
  },
  {
    path: 'agents',
    component: AgentsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'auth/activation/:activationId',
    component: ActivationComponent
  },
  {
    path: 'auth/registration',
    component: RegistrationComponent
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'about/awards',
    component: AwardsComponent
  },
  {
    path: 'about/awards/:id',
    component: DetailAwardComponent
  },
  {
    path: 'form-downloads',
    component: FormDownloadsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'paymentPublic',
    component: PaymentPublicComponent
  },
  {
    path: 'office-network',
    component: OfficeNetworkComponent
  },
  {
    path: 'education-center',
    component: EducationCenterComponent
  },
  {
    path: 'product/:id',
    component: DetailProductComponent
  },
  {
    path: 'contact-product',
    component: ContactProductComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'daily-nav',
    component: DailyNavComponent
  },
  {
    path: 'result-page/:query',
    component: ResultPageComponent
  },
  {
    path: 'tips-trick',
    component: TipsTrickComponent
  },
  {
    path: 'tips-trick/:id',
    component: DetailTipsTrickComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'transaction',
    component: TransactionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'claim/tracking',
    component: TrackingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'claim/history',
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'claim/eclaim',
    component: EclaimComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'video/welcome',
    component: VideoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videoPembayaran/welcome',
    component: VideoPembayaranComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videoCC/welcome',
    component: VideoCCComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videoMinor/welcome',
    component: VideoMinorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videoClaim/welcome',
    component: VideoClaimComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videoClaimReim/welcome',
    component: VideoClaimReimComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund/unit-price',
    component: UnitPriceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund/fund-fact-sheet',
    component: FundFactsheetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund/performance',
    component: PerformanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inbox',
    component: InboxComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inbox-detail/:id',
    component: InboxDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/personal-data',
    component: PersonalDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/minor-alteration',
    component: MinorAlterationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/req-med',
    component: ReqMedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/req-change-bene/:policyNo',
    component: ReqChangeBeneComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/update-occup/:policyNo',
    component: UpdateOccupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/req-polis',
    component: ReqPolisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/withdrawal/:policyNo',
    component: WithdrawalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/proposal-information',
    component: ProposalInformationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/policy-information',
    component: PolicyInformationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/policy-information/:policyNo',
    component: PolicyDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/Document-UnComplete/:policyNo',
    component: DocumentUncompleteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/proposal-information/:ppajNo',
    component: ProposalDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/req-change-topup',
    component: ReqChangeTopupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund-monthly',
    component: FundMonthlyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund-yearly',
    component: FundYearlyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'history-detail/:claimId',
    component: HistoryDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cashless-tracking-detail/:claimId',
    component: CashlessTrackingDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reimbursement-tracking-detail/:claimId',
    component: ReimbursementTrackingDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-service',
    component: CustomerServiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-service-detail',
    component: CustomerServiceDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/success/:policyNo/:refNo',
    component: PaymentStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/error/:policyNo/:refNo',
    component: PaymentStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/detail/:policyNo',
    component: PaymentDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/va/:policyNo',
    component: PaymentVaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/Document-UnComplete/:policyNo',
    component: DocumentUncompleteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/change-frequency/:policyNo',
    component: ChangeFrequencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/switching/:policyNo',
    component: SwitchingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/premium-redirection/:policyNo',
    component: PremiumRedirectionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/update-credit-card/:policyNo',
    component: UpdateCreditCardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/update-policy-holder',
    component: UpdatePolicyHolderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-data/premium-holiday/:policyNo',
    component: PremiumHolidayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    ProductComponent,
    HospitalComponent,
    NewsComponent,
    AgentsComponent,
    ContactComponent,
    ActivationComponent,
    RegistrationComponent,
    LoginComponent,
    ForgetPasswordComponent,
    AwardsComponent,
    FormDownloadsComponent,
    FaqComponent,
    PaymentPublicComponent,
    OfficeNetworkComponent,
    DetailAwardComponent,
    DetailNewsComponent,
    FinancialReportComponent,
    AboutChubbLifeComponent,
    PrivacyPolicyComponent,
    TermOfUseComponent,
    DashboardComponent,
    MenuDashboardHorizontalComponent,
    MenuDashboardVerticalComponent,
    PersonalDataComponent,
    ProposalInformationComponent,
    PolicyInformationComponent,
    ProposalDetailComponent,
    EducationCenterComponent,
    DetailProductComponent,
    ContactProductComponent,
    PolicyDetailComponent,
    TransactionComponent,
    TrackingComponent,
    HistoryComponent,
    UnitPriceComponent,
    FundFactsheetComponent,
    InboxComponent,
    InboxDetailComponent,
    ChartRupiahEquityFundComponent,
    ChartRupiahSyariahBondFundComponent,
    PerformanceComponent,
    TermsOfUseComponent,
    DailyNavComponent,
    ResultPageComponent,
    TipsTrickComponent,
    DetailTipsTrickComponent,
    FundMonthlyComponent,
    FundYearlyComponent,
    HistoryDetailComponent,
    CashlessTrackingDetailComponent,
    ReimbursementTrackingDetailComponent,
    CustomerServiceComponent,
    CustomerServiceDetailComponent,
    ChangePasswordComponent,
    NotfoundComponent,
    HighlightSearch,
    ExcerptFilter,
    LocalizedDatePipe,
    SafeHtmlPipe,
    EqualValidator,
    EclaimComponent,
    MinorAlterationComponent,
    ReqMedComponent,
    CpSweetalertComponent,
    LoadingProgressComponent,
    HeaderComponent,
    PaymentComponent,
    PaymentDetailComponent,
    Ipay88Component,
    PaymentStatusComponent,
    PaymentVaComponent,
    VideoPembayaranComponent,
    VideoCCComponent,
    VideoMinorComponent,
    VideoClaimComponent,
    VideoClaimReimComponent,
    VideoComponent,
    ReqPolisComponent,
    WithdrawalComponent,
    UpdateOccupComponent,
    PolicyCheckComponent,
    ReqChangeBeneComponent,
    ReqChangeTopupComponent,
    DocumentUncompleteComponent,
    ChangeFrequencyComponent,
    SwitchingComponent,
    PremiumRedirectionComponent,
    UpdateCreditCardComponent,
    UpdatePolicyHolderComponent,
    PremiumHolidayComponent,
    ChatUsComponent
  ],
  imports: [
    SlideshowModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxAppTour.forRoot(),
    RouterModule.forRoot(appRoutes),
    TextFieldModule,
    NgbModule,
    NgxCarouselModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: cf.eel
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: (translateService, translateCacheSettings) => {
          return new TranslateCacheService(translateService, translateCacheSettings);
        },
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheName: 'lang',
      cacheMechanism: 'Cookie',
      cookieExpiry: 1
    }),
    LoadingBarHttpClientModule,
    LazyLoadImageModule,
    SweetAlert2Module.forRoot(),
    PasswordStrengthBarModule,
    CountdownTimerModule.forRoot(),
    NgxCaptchaModule,
    NgxErrorsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    NgImageSliderModule,
    OrderModule,
    SignaturePadModule,
    UserIdleModule.forRoot({ idle: 240, timeout: 60, ping: 120 }),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  providers: [Config, CacheService, ValidationService, RESTService, APIService, AuthGuard, LocalService, StorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: environment.apiEndpoint + '/language/frontend/lang/', suffix: '.json' },
    { prefix: environment.serviceEndpoint + '/translate/', suffix: '.json' }
  ]);
}

registerLocaleData(localeId);
