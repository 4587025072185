export class PaymentRequest {
  public beneficiaryName: string;
  public customerName: string;
  public email: string;
  public mobilePhone: string;
  public paymentId: number;
  public paymentMethod: string;
  public policyNumber: string;
  public premi: number;
  public dateOfBirth: Date;
  public typePayment: string;
}
