import { Component, OnInit, Input } from '@angular/core';
import { FileProgress } from '../../models/file-progress';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent implements OnInit {
  @Input('progress') loadingProgressData: FileProgress = { loading: false, progress: 0 };
  constructor() {}

  ngOnInit() {}
}
