export class ReqBene {
    policyNumber: string;
    touchPoint: string;
    applicationDate: Date;
    beneDesignation: string;
    beneName: string;
    beneGender: string;
    beneDob: Number;
    beneShare: string;
    beneDesignation2: string;
    beneName2: string;
    beneGender2: string;
    beneDob2: Number;
    beneShare2: string;
    beneDesignation3: string;
    beneName3: string;
    beneGender3: string;
    beneDob3: Number;
    beneShare3: string;
    beneDesignation4: string;
    beneName4: string;
    beneGender4: string;
    beneDob4: Number;
    beneShare4: string;
    beneDesignation5: string;
    beneName5: string;
    beneGender5: string;
    beneDob5: Number;
    beneShare5: string;
    generateEndorsement: string;

    idRefBene11: string;
    idRefBene12: string;
    idRefBene13: string;
    idRefBene14: string;
    idRefBene15: string;
    idRefBene16: string;
    idRefBene17: string;

    idRefBene21: string;
    idRefBene22: string;
    idRefBene23: string;
    idRefBene24: string;
    idRefBene25: string;
    idRefBene26: string;
    idRefBene27: string;

    idRefBene31: string;
    idRefBene32: string;
    idRefBene33: string;
    idRefBene34: string;
    idRefBene35: string;
    idRefBene36: string;
    idRefBene37: string;

    idRefBene41: string;
    idRefBene42: string;
    idRefBene43: string;
    idRefBene44: string;
    idRefBene45: string;
    idRefBene46: string;
    idRefBene47: string;

    idRefBene51: string;
    idRefBene52: string;
    idRefBene53: string;
    idRefBene54: string;
    idRefBene55: string;
    idRefBene56: string;
    idRefBene57: string;

}
