import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { NgbDateFRParserFormatter } from '../../ngb-date-fr-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PolicyService } from '../../services/policy.service';
import { APIResponse } from '../../models/apiresponse';
import { ClaimService } from '../../services/claim.service';
import { LocalService } from '../../local.service';


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [Config, AuditTrail, PolicyService, ClaimService,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class TrackingComponent implements OnInit {
  params: any = '';
  userId: any = '';
  page = 1;
  policyData: any = [];
  claimReimbursementData: any = [];
  claimCashlessData: any = [];

  modelPolicyNoCashless: any = '';
  modelPolicyNoReimbursement: any = '';

  // loading data
  loadingData: boolean = false;
  loadingDataCashless: boolean = false;
  loadingDataReimbursement: boolean = false;

  // paging reimbursement
  reimbursementCurrentPage: any = 1;
  reimbursementPerPage: any = 10;
  reimbursementTotalPages: any = 1;
  reimbursementTotal: any = 0;
  reimbursementTotalDataPagination: any = 1;
  reimbursementPreviousPage: any = 1;

  // paging cashless
  cashlessCurrentPage: any = 1;
  cashlessPerPage: any = 10;
  cashlessTotalPages: any = 1;
  cashlessTotal: any = 0;
  cashlessTotalDataPagination: any = 1;
  cashlessPreviousPage: any = 1;

  constructor(private cnf: Config, private auditTrail: AuditTrail,
    private titleService: Title, private router: Router,
    private cacheService: CacheService,
    private policy: PolicyService,
    private claim: ClaimService,
    private _location: Location,
    private localService: LocalService) {

  }

  ngOnInit() {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('per_page', '100');
    // params = params.append('token', this.cnf.token);

    // let data = {
    //   'page': '1',
    //   'per_page': '100'
    // };

    this.auditTrail.saveLog("Claim Tracking", "Open");
    this.titleService.setTitle(this.cnf.prefixTitle + "Tracking" + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');

    this.policy.load(params, this.userId).subscribe((res: any) => {
      this.loadingData = true;
      const response: APIResponse<any> = res.body;
      if (response.status === 100) {
        this.policyData = response.datas[0].policyInformation_header;

        this.loadClaimReimbursement().subscribe((resp: any) => {
          this.loadingDataReimbursement = true;
          const respon: APIResponse<any> = resp.body;
          if (respon.status === 100) {
            this.claimReimbursementData = respon.datas[0].claim_header;
            this.reimbursementTotal = respon.datas[0].total;
            this.reimbursementTotalDataPagination = respon.datas[0].total;
            this.reimbursementTotalPages = respon.datas[0].total_page;
          }
        });

        this.loadClaimCashless().subscribe((respo: any) => {
          this.loadingDataCashless = true;
          const respon: APIResponse<any> = respo.body;
          if (respon.status === 100) {
            this.claimCashlessData = respon.datas[0].claim_aai;
            this.cashlessTotal = respon.datas[0].total;
            this.cashlessTotalDataPagination = respon.datas[0].total;
            this.cashlessTotalPages = respon.datas[0].total_page;
          }
        });
      }
    });
  }

  loadCashlessDetail(data) {
    this.localService.setJsonValue("cashlessTempData", JSON.stringify(data));
    this.router.navigate(['/cashless-tracking-detail', data.claim_id]);
  }

  doSearchCashless() {
    this.auditTrail.saveLog("Claim Tracking", "Search CashLess");
    this.loadingDataCashless = false;

    this.cashlessPreviousPage = 1;
    this.cashlessCurrentPage = 1;
    this.claimCashlessData = [];

    this.loadClaimCashless().subscribe((res: any) => {
      this.loadingDataCashless = true;
      this.claimCashlessData = res.datas[0].claim_aai;
      this.cashlessTotal = res.datas[0].total;
      this.cashlessTotalPages = res.datas[0].total_page;
    });
  }

  loadPageReimbursement(page: string) {
    if (page !== this.reimbursementPreviousPage) {
      this.loadingDataReimbursement = false;

      this.reimbursementPreviousPage = page;
      this.reimbursementCurrentPage = page;

      this.loadClaimReimbursement().subscribe((res: any) => {
        this.loadingDataReimbursement = true;
        const response: APIResponse<any> = res.body;
        if (response.status === 100) {
          this.claimReimbursementData = response.datas[0].claim_header;
          this.reimbursementTotal = response.datas[0].total;
          this.reimbursementTotalPages = response.datas[0].total_page;
        }
      });
    }
  }

  loadPageCashless(page: string) {
    if (page !== this.cashlessPreviousPage) {
      this.loadingDataCashless = false;

      this.cashlessPreviousPage = page;
      this.cashlessCurrentPage = page;
      this.claimCashlessData = [];

      this.loadClaimCashless().subscribe((res: any) => {
        this.loadingDataCashless = true;
        if (res.status == 100) {
          this.claimCashlessData = res.datas[0].claim_aai;
          this.cashlessTotal = res.datas[0].total;
          this.cashlessTotalPages = res.datas[0].total_page;
        }
      });
    }
  }

  loadClaimReimbursement() {
    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let data = {
      'page': this.reimbursementCurrentPage,
      'per_page': this.reimbursementPerPage
    };
    return this.claim.tracking(this.userId, null, params, data);
    //let paramsObject = this.cnf.convertPostData(params, data);

    //return this.http.post(this.cnf.URLWSNonPublicArea + '/claim/header/reimbursement/tracking/' + this.userId, paramsObject)
    //  .pipe(map((response: Response) => response));
  }

  loadClaimCashless() {
    let modelStartCashless = '';
    if (this.modelStartCashless) {
      modelStartCashless = this.modelStartCashless.year + '-' + ('0' + this.modelStartCashless.month).slice(-2) + '-' + ('0' + this.modelStartCashless.day).slice(-2);
    }

    let params = new HttpParams();
    params = params.append('appId', 'test');
    params = params.append('appKey', 'on');
    params = params.append('token', this.cnf.token);

    let data = {
      'page': this.cashlessCurrentPage,
      'per_page': this.cashlessPerPage,
      'userid': this.userId,
      'policyno': this.modelPolicyNoCashless,
      'admissiondate': modelStartCashless
    };
    return this.claim.cashlesh(null, params, data);
    //let paramsObject = this.cnf.convertPostData(params, data);

    //return this.http.post(this.cnf.URLWSNonPublicArea + '/claim/aai/all/', paramsObject)
    //  .pipe(map((response: Response) => response));
  }

  doSearchReimbursement() {
    this.auditTrail.saveLog("Claim Tracking", "Search Reimbursement");
    this.claimReimbursementData = [];
    this.reimbursementCurrentPage = 1;

    this.loadClaimReimbursement().subscribe((res: any) => {
      const response: APIResponse<any> = res.body;
      this.claimReimbursementData = response.datas[0].claim_header;
      this.loadingData = true;
    });
  }

  backClicked() {
    this._location.back();
  }

  modelStartCashless;
  modelStartReimbursement;
  modelEndReimbursement;

  valuedate = new Date();
}
