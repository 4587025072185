import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalService } from './local.service';
import { AuditTrailDataBefore } from './models/AuditTrailDataBefore';
import { AuditTrailDataAfter } from './models/AuditTrailDataAfter';

@Injectable({
  providedIn: 'root'
})
export class AuditTrail {

  public auditTrailBefore: AuditTrailDataBefore = new AuditTrailDataBefore();
  public auditTrailAfter: AuditTrailDataAfter = new AuditTrailDataAfter();

  menuNameList = [
    'Minor Alteration',
    'Change Beneficiary',
    'Change Annual Income & Occupation',
    'Change Frequency Payment'
  ];

  constructor(
    private http: HttpClient,
    private cnf: Config,
    private localService: LocalService
  ) { }

  saveLog(menuName: any, menuAction: any) {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('token', this.cnf.token);

    let paramsObject = this.cnf.convertPostData(params);

    this.http.post(this.cnf.URLWS + '/audit_trail_front_end/getToken', paramsObject).subscribe((res: any) => {
      let decrypt = this.cnf.decryptData(res.datas);
      let attoken = this.cnf.encryptData(menuName + '|' + menuAction + '|' + decrypt);

      let params = new HttpParams();
      params = params.append('appId', this.cnf.appid);
      params = params.append('appKey', this.cnf.appkey);
      params = params.append('token', this.cnf.token);
      params = params.append('attoken', attoken);

      let data = {};
      const check = this.menuNameList.filter(s => s.includes(menuName));
      if (check.length > 0) {
        data = {
          menuName: menuName,
          menuAction: menuAction,
          ipAddress: this.localService.getJsonValue("ip"),
          location: this.localService.getJsonValue("location"),
          userId: this.localService.getJsonValue('userid'),
          auditDataCustomerBefore: this.auditTrailBefore,
          auditDataCustomerAfter: this.auditTrailAfter
        };
      } else {
        data = {
          menuName: menuName,
          menuAction: menuAction,
          ipAddress: this.localService.getJsonValue("ip"),
          location: this.localService.getJsonValue("location"),
          userId: this.localService.getJsonValue('userid'),
          auditDataCustomerBefore: null,
          auditDataCustomerAfter: null
        };
      }

      let paramsObject = this.cnf.convertPostData(params, data);

      this.http
        .post(this.cnf.URLWS + '/audit_trail_front_end/frontend/audittrail/save', paramsObject)
        .subscribe((res: any) => {
        });
    });
  }
}
