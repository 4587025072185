import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { CacheService } from '../../services/cache.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { TourService } from 'ngx-app-tour';
import { NgxCarousel } from 'ngx-carousel';
import { LocalService } from '../../local.service';
import { Observable } from 'rxjs';
import { APIService } from '../../services/api.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Config, AuditTrail]
})
export class DashboardComponent implements OnInit {
  @ViewChild('stepTemplate', { static: false }) stepTemplate;
  //public carouselOne: NguCarouselConfig;
  //public dataCarousel: IImage[] = [];
  public carouselOne: NgxCarousel;
  public dataCarousel: any[] = [];
  public data: any;
  public loadingBanner: boolean = false;

  public policySummaryData: any = [];
  public topGlobalFundUseridData: any = [];
  public topGlobalFundChubbLifeData: any = [];

  public loadingDataPolicy: boolean = false;
  public loadingData: boolean = false;
  public loadingDataYourTop3Funds: boolean = false;
  public loadingDataChubbLifeTop3Funds: boolean = false;
  public userId: any = '';
  public preview: any = '';
  public previewToken: any;
  public bannerBorderColor: any = 'banner-br-orange';
  attoken: string = '';
  projectName: string = 'fund';

  showMenu: boolean = false;
  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;
  tempPolis: string = '';
  imageOne: boolean = false;

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private http: HttpClient,
    private cacheService: CacheService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public tourService: TourService,
    private localService: LocalService,
    private apiService: APIService,
    private customerService: CustomerService
  ) {
    this.route.queryParams.subscribe(params => {
      this.preview = params['preview'];
      this.previewToken = params['token'];
    });

    let colors = [
      'banner-br-blue',
      'banner-br-orange',
      'banner-br-tosca',
      'banner-br-red',
      'banner-br-green',
      'banner-br-magenta'
    ];
    this.bannerBorderColor = colors[3];

    // Check localstorage userGuide
    if (this.localService.getJsonValue('userGuide') !== 'success') {
      setTimeout(() => {
        // load tour if userguide is false
        this.doLoadUserGuideTour();
      }, 1000);
    }
  }

  ngOnInit() {
    //use location & address
    //sodiq, 03-02-2022, get location and IP address client
    this.locationAddress();

    //non location
    // this.auditTrail.saveLog('Dashboard', 'Open');

    this.titleService.setTitle(this.cnf.prefixTitle + 'Dashboard' + this.cnf.postfixTitle);
    // Carousel
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 4000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner',
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
    };

    // Check Credential stuff
    this.userId = this.localService.getJsonValue('userid');
    this.cnf.getAttoken().subscribe((res: any) => {
      if (res.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
      }
      this.loadPolicySummary().subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policySummaryData = decryptData[0].policyInformation_header;
        }
        this.loadingDataPolicy = true;
      });

      this.loadTopGlobalFundUserid().subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.topGlobalFundUseridData = decryptData[0].fund;
        }
        this.loadingDataYourTop3Funds = true;
      });

      this.loadTopGlobalFundChubbLife().subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.topGlobalFundChubbLifeData = decryptData[0].fund;
        }
        this.loadingDataChubbLifeTop3Funds = true;
      });
    });

    this.data = this.cacheService
      .get(this.cnf.lang + '/segmented_banner_rule', this.loadData())
      .subscribe((res: any) => {
        this.loadingBanner = true;
        if (res.status == 100) {
          for (let i in res.datas) {
            let dataTemp = res.datas[i];
            dataTemp.description = this.cnf.strip_tags(dataTemp.description, [dataTemp.description, 'b', 'i', 'u']);
            dataTemp.showTextSection = false;
            this.dataCarousel.push(dataTemp);
          }
        } else {
          this.dataCarousel.length = 0;
        }
      });

    this.customerService.getPolicyInfo(this.userId).subscribe((res: any) => {
      if (res.status === 200) {
        if (res.body.data) {
          for (let obj of res.body.data) {
            if (obj.liabilityState === '1' && obj.acknowledge === null) {
              if (this.tempPolis === '') {
                this.tempPolis = obj.policyNo
              } else {
                this.tempPolis = this.tempPolis + ', ' + obj.policyNo
              }
            }
          }
          if (this.tempPolis !== '') {
            let number = localStorage.getItem('img')
            if (number === '01') {
              this.imageOne = true;
              localStorage.setItem('img', '02')
            } else {
              this.imageOne = false;
              localStorage.setItem('img', '01')
            }
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }
      }
    });
  }

  locationAddress() {
    this.getLocation().subscribe((position: any) => {
      const longitude = position.coords.longitude;
      const latitude = position.coords.latitude;
      console.log(this.cnf.enp)
      const url = `${this.cnf.enl}${latitude},${longitude}${this.cnf.enp}${this.cnf.enm}`;
      this.http.get(url).subscribe((res: any) => {
        if (res.statusCode == 200) {
          if (res.resourceSets.length > 0) {
            if (res.resourceSets[0].resources.length > 0) {
              this.localService.setJsonValue('location', res.resourceSets[0].resources[0].address.adminDistrict2 + ',' + res.resourceSets[0].resources[0].address.adminDistrict);
              this.http.get(this.cnf.ena).subscribe((res: any) => {
                if (res.ip != "" && res.ip != null) {
                  this.localService.setJsonValue('ip', res.ip);
                  this.saveLog();
                } else {
                  this.localService.setJsonValue('ip', 'unknow ip address');
                  this.saveLog();
                }
              });
            } else {
              this.localService.setJsonValue('location', 'unknow address');
              this.saveLog();
            }
          } else {
            this.localService.setJsonValue('location', 'unknow address');
            this.saveLog();
          }
        } else {
          this.localService.setJsonValue('location', 'unknow address');
          this.saveLog();
        }
      });
    }, err => {
      this.localService.setJsonValue('location', err.message);
      this.http.get(this.cnf.ena).subscribe((res: any) => {
        if (res.ip != "" && res.ip != null) {
          this.localService.setJsonValue('ip', res.ip);
          this.saveLog();
        } else {
          this.localService.setJsonValue('ip', 'unknow ip address');
          this.saveLog();
        }
      });
    });
  }

  getLocation(): Observable<any> {
    return new Observable(obs => {
      navigator.geolocation.getCurrentPosition(
        success => {
          obs.next(success);
          obs.complete();
        },
        error => {
          obs.error(error);
        }
      );
    });
  }

  saveLog() {
    let check = this.localService.getJsonValue('islogin');
    if (check == 'Y') {
      this.auditTrail.saveLog('Home', 'Login');
    }
    this.auditTrail.saveLog('Dashboard', 'Open');
    this.localService.setJsonValue('islogin', 'N');
    this.showMenu = true;
  }

  /**
   * @desc Load Banner
   * @param url
   */
  doLoadBanner(url: any) {
    var a = url;
    if (a.match(/^[a-zA-Z0-9_:\.//-]*$/)) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();
      link.remove();
    }

  }

  /**
   * @desc User Guide Tour based on localstorage
   */
  doLoadUserGuideTour() {
    let stepTwo;
    let contentOne = this.translate.instant('_user_guide_welcome_to_chubb_customer_corner');
    let contentTwo = this.translate.instant('_user_guide_policy_summary');
    let contentThree = this.translate.instant('_user_guide_menu');

    let titleOne = this.translate.instant('_user_guide_title_welcome_to_chubb_customer_corner');
    let titleTwo = this.translate.instant('_user_guide_title_policy_summary');
    let titleThree = this.translate.instant('_user_guide_title_menu');

    if (window.innerWidth >= 992) {
      stepTwo = {
        anchorId: 'second-vertical-tour',
        content: contentThree,
        title: titleThree,
        placement: 'right',
        backdrop: true
      };
    } else {
      stepTwo = {
        anchorId: 'second-horizontal-tour',
        content: contentThree,
        title: titleThree,
        placement: 'bottom',
        backdrop: true
      };
    }

    this.tourService.initialize([
      {
        anchorId: 'first.tour',
        content: contentOne,
        placement: 'bottom',
        title: titleOne,
        // orphan: true,
        backdrop: true
      },
      stepTwo,
      {
        anchorId: 'third-tour',
        content: contentTwo,
        title: titleTwo,
        placement: 'bottom',
        backdrop: true
      }
    ]);

    this.tourService.start();
    this.tourService.events$.subscribe(event => {
      if (event.name === 'end') {
        this.localService.setJsonValue('userGuide', 'success');
      }
    });
  }

  /**
   * @desc loadTopGlobalUserId
   */
  loadTopGlobalFundUserid() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);
    // params = params.append('attoken', this.attoken);

    // let paramsObject = this.cnf.convertPostData(params);

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/fund/top-global-fund/userid/' + this.userId);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/fund/top-global-fund/userid/' + this.userId, paramsObject)
    //   .pipe(map((response: Response) => response));
  }

  /**
   * @desc loadTopGlobalFundChubbLife
   */
  loadTopGlobalFundChubbLife() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);
    // params = params.append('attoken', this.attoken);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/fund/dashboard/top-global-fund/', paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/fund/dashboard/top-global-fund/');
  }

  /**
   * @desc loadPolicySummary
   */
  loadPolicySummary() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');
    // params = params.append('token', this.cnf.token);
    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/policy_information/header/policy/dashboard/' + this.userId, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/header/policy/dashboard/' + this.userId);
  }

  /**
   * @desc Load Banner
   */
  loadData() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let url = this.cnf.URLWS + '/segmented_banner_rule';
    if (this.preview) {
      params = params.append('token', this.previewToken);
      url = url + '/preview/' + this.preview;
    } else {
      url = url + '/frontend/all/' + this.userId;
    }
    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(url, paramsObject).pipe(map((response: Response) => response));
  }

  /**
   * @desc show slide text
   * @param carousel
   */
  showSlideText(carousel: any) {
    carousel.showTextSection = true;
  }

  /**
   * @desc hide Slide text
   * @param carousel
   */
  hideSlideText(carousel: any) {
    carousel.showTextSection = false;
  }
}
