export const environment = {
  production: false,
  VERSION: '1.1.0',

  // WITHOUT VPN
  serviceEndpoint: 'https://uatcc-backend2.chubblife.co.id',
  fileEndpoint: 'https://uatcc-file.chubblife.co.id',
  apiEndpoint: 'https://uatcc-backend1.chubblife.co.id',
  cpEndpoint: 'https://uatcc-payment.chubblife.co.id',

  //VPN
  // serviceEndpoint: 'https://uatcc-backend2.chubblife.co.id:9888',
  // fileEndpoint: 'https://uatcc-file.chubblife.co.id:9099',
  // apiEndpoint: 'https://uatcc-backend1.chubblife.co.id:9011',
  // cpEndpoint: 'https://uatcc-payment.chubblife.co.id:9071',

  //original hostname SET 2
  // serviceEndpoint: 'https://apidlcpuap003.aceins.com/serviceEndpoint',
  // fileEndpoint: 'https://uatcc-file.chubblife.co.id',
  // apiEndpoint: 'https://apidlcpuap003.aceins.com/apiEndpoint',
  // cpEndpoint: 'https://apidlcpuap003.aceins.com/cpEndpoint',

  //original hostname SET 3
  // serviceEndpoint: 'https://apidlcpuap003.aceins.com/serviceEndpointset3',
  // fileEndpoint: 'https://uatcc-file.chubblife.co.id',
  // apiEndpoint: 'https://apidlcpuap003.aceins.com/apiEndpointset3',
  // cpEndpoint: 'https://apidlcpuap003.aceins.com/cpEndpointset3',

  // //original hostname SET 4
  // serviceEndpoint: 'https://apidlcpuap003.aceins.com/serviceEndpointset4',
  // fileEndpoint: 'https://uatcc-file.chubblife.co.id',
  // apiEndpoint: 'https://apidlcpuap003.aceins.com/apiEndpointset4',
  // cpEndpoint: 'https://apidlcpuap003.aceins.com/cpEndpointset4',

  //original hostname SET 4
  // serviceEndpoint: 'https://apidlcpuap003.aceins.com/serviceEndpoint5',
  // fileEndpoint: 'https://uatcc-file.chubblife.co.id',
  // apiEndpoint: 'https://apidlcpuap003.aceins.com/apiEndpoint5',
  // cpEndpoint: 'https://apidlcpuap003.aceins.com/cpEndpoint5',

  
};
