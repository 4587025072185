export class eLetter {
    public customerId: string;
    public policyCode: string;
    public docId: string;
    public startDate: string;
    public endDate: string;
    public docNo: string;
    public letterType: string;
    public filePath: string;
    public fileName: string;
    public datePrint: string;
    public password: string;
    public base64: string;
    public succes: boolean;
    public type: string;
    public year: string;
}
