import { Injectable } from '@angular/core';
import { Config } from '../config';
import { RESTRequest } from '../models/restrequest.model';

import { PersonalData } from '../models/personal-data.model';
import { Login } from '../models/login.model';
import { Token } from '../models/token';

import { APIService } from './api.service';
import { RESTService } from './rest.service';
import { ForgetPasswordRequest } from '../models/forget-password-request.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { RESTResponse } from '../models/restresponse';
import { map } from 'rxjs/operators';
import { PolicyInfoPayment } from '../models/PolicyInfoPayment';
import { PolicyCheck } from '../models/policy-check.model';
import { BeneDto } from '../models/bene-dto.model';
import { ReqEndorsement } from '../models/ReqEndorsement';
import { BeneDesignationDto } from '../models/bene-designation-dto.model';
import { ReqChangeTopUp } from '../models/ReqChangeTopUp';
import { eLetter } from '../models/eLetter';
import { HttpClient } from '@angular/common/http';
import { ChangeRequest } from '../models/ChangeRequest';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private cnf: Config, private api: APIService, private rest: RESTService, private http: HttpClient) { }

  public personalDataSubject: BehaviorSubject<PersonalData> = new BehaviorSubject<PersonalData>(new PersonalData());

  public get currentPersonalDataValue(): PersonalData {
    return this.personalDataSubject.value;
  }

  public get personalDataEmptyStatus() {
    return this.checkEmpty(this.currentPersonalDataValue);
  }

  checkEmpty(data: object) {
    return Object.entries(data).length === 0;
  }

  setPersonalData(userId, token) {
    this.getPersonalData(userId, token).subscribe(
      res => {
        const response: RESTResponse<PersonalData> = res.body;
        if (response.success) {
          this.personalDataSubject.next(response.data);
        }
      },
      err => {
        this.personalDataSubject.error(err);
      }
    );
  }

  getPersonalData(userId: string, token: string) {
    return this.rest.get<PersonalData>(this.cnf.URLCustomerService + '/detail/personaldata/user/' + userId);
  }

  getBene(policyCode: string) {
    return this.rest.get<BeneDto[]>(this.cnf.URLCustomerService + '/getBene/' + policyCode);
  }

  getBeneDesignation() {
    return this.rest.get<BeneDesignationDto[]>(this.cnf.URLCustomerService + '/getBeneDesignation');
  }

  checkToken(request: RESTRequest<Login>) {
    return this.rest.post(this.cnf.URLCustomerService + '/checkToken', request);
  }

  getToken(request: RESTRequest<Token>) {
    return this.rest.post(this.cnf.URLCustomerService + '/getToken', request);
  }

  submitForgotPassword(request: RESTRequest<ForgetPasswordRequest>) {
    return this.rest.post(this.cnf.URLCustomerService + '/forgotpassword', request);
  }

  // POLICY DETAIL
  getPolicyDetailProduct(request: RESTRequest<any>, policyNo) {
    return this.rest
      .post(this.cnf.URLCustomerService + '/info/product/' + policyNo, request)
      .pipe(map(response => response));
  }

  getEstatTiv(policyNo: any) {
    const url = `${this.cnf.URLCustomerService}/estat-tiv/policy/${policyNo}`;
    return this.rest.get(`${url}`);
  }

  getPolicyCheck(policyNo: any) {
    const url = `${this.cnf.URLCustomerService}/policyCheck/${policyNo}`;
    return this.rest.get(`${url}`);
  }

  getEstatYear(policyNo: any) {
    const url = `${this.cnf.URLCustomerService}/estat-year/policy/${policyNo}`;
    return this.rest.get(`${url}`);
  }

  getCustomer(userID: any) {
    return this.rest.get(this.cnf.URLClaimService + '/user/customer/' + userID);
  }

  infoPayment(request: RESTRequest<PolicyInfoPayment>, policy: string) {
    return this.rest.post(this.cnf.URLCpService + '/customer/payment/policy/' + policy, request);
  }

  filePolicy(request: RESTRequest<PolicyCheck>) {
    return this.rest.post(this.cnf.URLCpService + '/customer/file_transfer/policyCheck', request);
  }

  //sodiq, 29-09-2021 5:55 
  getEndorsement() {
    return this.rest.get(this.cnf.URLCustomerService + '/endorsement');
  }

  saveReqEndorsement(request: RESTRequest<ReqEndorsement>) {
    return this.rest.post(this.cnf.URLCustomerService + '/reqEndorsement', request);
  }

  detailInsured(polisno: any) {
    const url = `${this.cnf.URLCustomerService}/detailInsured/${polisno}`;
    return this.rest.get(`${url}`);
  }

  saveReqChangeToUp(request: RESTRequest<ReqChangeTopUp>) {
    return this.rest.post(this.cnf.URLCustomerService + '/reqChangeTopUp', request);
  }

  donwloadEndorsementLetter(policyNo: any, reportId: any, idEndorsement: any) {
    return this.rest.post(this.cnf.URLCustomerService + '/downloadLetterEndorsement/' + policyNo + '/' + reportId + '/' + idEndorsement);
  }

  detailCoiCor(policyNo: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/detailCoiCor/' + policyNo);
  }

  // detailCashValue(policyNo: any) {
  //   return this.rest.get(this.cnf.URLCustomerService + '/detailCashValue/' + policyNo);
  // }

  getLetterAutomation(request: RESTRequest<eLetter>) {
    return this.rest.post(this.cnf.URLCustomerService + '/eletter/automation', request);
  }

  downloadLetterAutomation(request: RESTRequest<eLetter>) {
    return this.rest.post(this.cnf.URLCustomerService + '/eletter/download', request);
  }

  getInsuredByPolicy(policyNo: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/getDataInsured/' + policyNo);
  }

  getPolicyInformation(policyNo: any, token: any) {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');
    // params = params.append('token', token);
    // let paramsObject = this.cnf.convertPostData(params);
    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + policyNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.api.post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + policyNo);


  }

  getChargeMode() {
    return this.rest.get(this.cnf.URLCustomerService + '/chargeMode');
  }

  getFund() {
    return this.rest.get(this.cnf.URLCustomerService + '/fundWithCurr');
  }

  getCustomerFund(policyNo: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/customerFund/' + policyNo);
  }

  getExpiredCard(policyNo: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/expiredCard/' + policyNo);
  }

  changeRequest(request: RESTRequest<ChangeRequest>) {
    return this.rest.post(this.cnf.URLCustomerService + '/changeRequest', request);
  }

  getCategoriJob() {
    return this.rest.get(this.cnf.URLCustomerService + '/getCategoriJob/');
  }

  getPremiumHoliday(policyCode: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/getPremiumHoliday/' + policyCode);
  }

  getPolicyInfo(userId: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/getPolicyInfo/' + userId);
  }

  directDownload(request: RESTRequest<eLetter>) {
    return this.rest.postFiles(this.cnf.URLCustomerService + '/eletter/direct', request);
  }

  getFeeReprint(policyCode: any, reprint:any) {
    return this.rest.get(this.cnf.URLCustomerService + '/getFeeReprint/' + policyCode+'/'+reprint);
  }
}
