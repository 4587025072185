import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MOMENT_DATE_FORMATS, MomentDateAdapter } from './app/moment-date-adapter';
import 'moment/locale/id';

if (environment.production) {
  enableProdMode();
  window.console.log = function () { };
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'id-ID' }, {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
    {provide: DateAdapter, useValue: MomentDateAdapter}]
}).catch(err => console.log(err));
