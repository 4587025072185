import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { NgbDateFRParserFormatter } from '../../ngb-date-fr-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { LocalService } from '../../local.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss'],
  providers: [Config, AuditTrail, { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class ProposalDetailComponent implements OnInit {
  params: any = '';
  proposalHeaderData: any = {};
  proposalFundData: any = [];
  proposalRiderData: any = [];
  proposalTrackingData: any = {};
  proposalAgentData: any = {};
  proposalBeneficiaryData: any = [];
  proposalLifeAssuredData: any = [];
  proposalLifeProductData: any = [];
  public loadingData: boolean = false;
  public userId: any = '';

  loadingAssured: boolean = false;
  loadingFund: boolean = false;
  loadingProduct: boolean = false;
  loadingRider: boolean = false;
  loadingBeneficiary: boolean = false;
  loadingAgent: boolean = false;
  loadingProposal: boolean = false;

  // hardcoded is product TROP
  isTROP: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private router: Router,
    private http: HttpClient,
    private _location: Location,
    private localService: LocalService,
    private apiService: APIService
  ) {
    this.route.params.subscribe(params => (this.params = params));
  }

  ngOnInit() {
    this.auditTrail.saveLog('My Data Proposal Information', 'Proposal Detail #' + this.params.ppajNo);
    this.titleService.setTitle(this.cnf.prefixTitle + 'Proposal Detail #' + this.params.ppajNo + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');
    this.loadingProposal = true;
    this.proposalHeader()
      .subscribe((res: any) => {
        this.loadingData = true;
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalHeaderData = decryptData[0].proposalInformation_detail[0];

          if (!this.proposalHeaderData.homeAddress1) {
            this.proposalHeaderData.homeAddress1 = this.proposalHeaderData.correspondenceAddress1;
            this.proposalHeaderData.homeAddress2 = this.proposalHeaderData.correspondenceAddress2;
            this.proposalHeaderData.homeAddress3 = this.proposalHeaderData.correspondenceAddress3;
            this.proposalHeaderData.homeAddress4 = this.proposalHeaderData.correspondenceAddress4;
            this.proposalHeaderData.homeAddress5 = this.proposalHeaderData.correspondenceAddress5;
            this.proposalHeaderData.homeAddress6 = this.proposalHeaderData.correspondenceAddress6;
            this.proposalHeaderData.homeAddress7 = this.proposalHeaderData.correspondenceAddress7;
            this.proposalHeaderData.homeState = this.proposalHeaderData.correspondenceState;
            this.proposalHeaderData.homePostCode = this.proposalHeaderData.correspondencePostCode;
          } else if (!this.proposalHeaderData.officeAddress1) {
            this.proposalHeaderData.officeAddress1 = this.proposalHeaderData.correspondenceAddress1;
            this.proposalHeaderData.officeAddress2 = this.proposalHeaderData.correspondenceAddress2;
            this.proposalHeaderData.officeAddress3 = this.proposalHeaderData.correspondenceAddress3;
            this.proposalHeaderData.officeAddress4 = this.proposalHeaderData.correspondenceAddress4;
            this.proposalHeaderData.officeAddress5 = this.proposalHeaderData.correspondenceAddress5;
            this.proposalHeaderData.officeAddress6 = this.proposalHeaderData.correspondenceAddress6;
            this.proposalHeaderData.officeAddress7 = this.proposalHeaderData.correspondenceAddress7;
            this.proposalHeaderData.officeState = this.proposalHeaderData.correspondenceState;
            this.proposalHeaderData.officePostCode = this.proposalHeaderData.correspondencePostCode;
          }

          this.proposalFund().subscribe((res: any) => {
            if (res.body.status == 100) {
              let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
              this.proposalFundData = decryptData[0].proposalInformation_agent;
            }
            this.loadingData = true;
          });

          this.proposalTracking().subscribe((res: any) => {
            if (res.body.status == 100) {
              let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
              this.proposalTrackingData = decryptData[0].proposalInformation_tracking[0];
            }
            this.loadingData = true;
          });
        } else {
          this.router.navigate(['/404']);
        }
      })
      .add(() => {
        this.loadingProposal = false;
      });
  }

  onTabChange($event: NgbTabChangeEvent) {
    const tab = $event.nextId;
    if (tab === 'assured-tab') {
      if (!this.proposalLifeAssuredData.length) {
        this.loadAssured();
      }
    } else if (tab === 'product-tab') {
      if (!this.proposalRiderData.length) {
        this.loadRider();
      }
      if (!this.proposalLifeProductData.length) {
        this.loadProduct();
      }
    } else if (tab === 'beneficiary-tab') {
      if (!this.proposalBeneficiaryData.length) {
        this.loadBeneficiary();
      }
    } else if (tab === 'agent-tab') {
      if (!this.proposalAgentData.length) {
        this.loadAgent();
      }
    }
  }
  loadAssured() {
    this.loadingAssured = true;
    this.proposalLifeAssured()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalLifeAssuredData = decryptData[0].proposalInformation_lifeAssured;
        }
      })
      .add(() => {
        this.loadingAssured = false;
      });
  }
  loadProduct() {
    this.loadingProduct = true;
    this.proposalProduct()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalLifeProductData = decryptData[0].proposalInformation_product;
          for (let i in this.proposalLifeProductData) {
            if (this.proposalLifeProductData[i].productCode.includes('TROP')) {
              this.isTROP = true;
            }
          }
        }
      })
      .add(() => {
        this.loadingProduct = false;
      });
  }
  loadRider() {
    this.loadingRider = true;
    this.proposalRider()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalRiderData = decryptData[0].proposalInformation_rider;
        }
      })
      .add(() => {
        this.loadingRider = false;
      });
  }
  loadBeneficiary() {
    this.loadingBeneficiary = true;
    this.proposalBenef()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalBeneficiaryData = decryptData[0].proposalInformation_agent;
        }
      })
      .add(() => {
        this.loadingBeneficiary = false;
      });
  }
  loadAgent() {
    this.loadingAgent = true;
    this.proposalAgent()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.proposalAgentData = decryptData[0].proposalInformation_agent[0];
        }
        this.loadingData = true;
      })
      .add(() => {
        this.loadingAgent = false;
      });
  }

  // loadFund() {
  //   this.loadingFund = true;
  //   this.proposalFund().subscribe((res: any) => {
  //     if (res.status == 100) {
  //       let decryptData = JSON.parse(this.cnf.decryptData(res.datas));
  //       this.proposalFundData = decryptData[0].proposalInformation_agent;
  //     }
  //   }).add(() => {
  //     this.loadingFund = false;
  //   });

  // }
  proposalHeader() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/detail/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/detail/ppaj/' + this.params.ppajNo);
  }

  proposalLifeAssured() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/life_assured/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/life_assured/ppaj/' + this.params.ppajNo);
  }

  proposalFund() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/fund/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/fund/ppaj/' + this.params.ppajNo);
  }

  proposalProduct() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/product/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/product/ppaj/' + this.params.ppajNo);
  }

  proposalRider() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/rider/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/rider/ppaj/' + this.params.ppajNo);
  }

  proposalTracking() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/tracking/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/tracking/ppaj/' + this.params.ppajNo);
  }

  proposalBenef() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/benef/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/benef/ppaj/' + this.params.ppajNo);
  }

  proposalAgent() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/proposal_information/agent/ppaj/' + this.params.ppajNo, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/proposal_information/agent/ppaj/' + this.params.ppajNo);
  }

  backClicked() {
    this._location.back();
  }
}
