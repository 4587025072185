import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpParams } from '@angular/common/http';
import { APIService } from './api.service';
import { RESTService } from './rest.service';
import { ClaimRequest } from '../models/claim-request.model';
import { RESTRequest } from '../models/restrequest.model';
import { Hospital } from '../models/hospital.model';
import { Params } from '@angular/router';

@Injectable()
export class ClaimService {
  constructor(private cnf: Config, private api: APIService, private rest: RESTService) { }

  loadNewDetail(claimID: Params, request?: RESTRequest<any>, params?: HttpParams) {
    if (params != null) {
      return this.api.post(
        this.cnf.URLWSNonPublicArea + '/claim/detail/new/claimid/' + claimID,
        this.cnf.convertPostData(params)
      );
    } else {
      if (request != null) {
      }
    }
  }
  loadDetail(claimID: number, request?: RESTRequest<any>, params?: HttpParams) {
    if (params != null) {
      return this.api.post(
        this.cnf.URLWSNonPublicArea + '/claim/detail/claimid/' + claimID,
        this.cnf.convertPostData(params)
      );
    } else {
      if (request != null) {
      }
    }
  }
  loadHistory(userID: any, request?: RESTRequest<any>, params?: HttpParams, data?: {}) {
    if (params != null) {
      return this.api.post(
        this.cnf.URLWSNonPublicArea + '/claim/header/claim/' + userID,
        this.cnf.convertPostData(params, data)
      );
    } else {
      if (request != null) {
      }
    }
  }
  loadStatus(userID: any, request?: RESTRequest<any>, params?: HttpParams, data?: {}) {
    if (params != null) {
      return this.api.post(
        this.cnf.URLWSNonPublicArea + '/claim/header/allstatusclaim/' + userID,
        this.cnf.convertPostData(params, data)
      );
    } else {
      if (request != null) {
      }
    }
  }
  tracking(userID: any, request?: RESTRequest<any>, params?: HttpParams, data?: {}) {
    // tslint:disable-next-line: max-line-length
    if (params != null) {
      return this.api.post(
        this.cnf.URLWSNonPublicArea + '/claim/header/reimbursement/tracking/' + userID,
        this.cnf.convertPostData(params, data)
      );
    } else {
      if (request != null) {
      }
    }
  }
  cashlesh(request?: RESTRequest<any>, params?: HttpParams, data?: {}) {
    if (params != null) {
      return this.api.post(this.cnf.URLWSNonPublicArea + '/claim/aai/all/', this.cnf.convertPostData(params, data));
    } else {
      if (request != null) {
      }
    }
  }
  getHospitals() {
    return this.rest.get<Hospital[]>(this.cnf.URLClaimService + '/hospital');
  }
  getHospital(hospitalId: Number) {
    return this.rest.get<Hospital>(this.cnf.URLClaimService + '/hospital/' + hospitalId);
  }
  getClaim(claimId: Number) {
    return this.rest.get(this.cnf.URLClaimService + '/id/' + claimId);
  }
  getClaims(policyId: Number) {
    return this.rest.get(this.cnf.URLClaimService + '/policy/' + policyId);
  }
  getPolicies(userID: any) {
    return this.rest.get(this.cnf.URLClaimService + '/contract/policy/' + userID);
  }
  getCustomer(userID: any) {
    return this.rest.get(this.cnf.URLClaimService + '/user/customer/' + userID);
  }
  getInsuredCustomers(policyCode: any) {
    return this.rest.get(this.cnf.URLCustomerService + '/policy/code/' + policyCode);
  }
  submitEClaim(request: RESTRequest<ClaimRequest>) {
    return this.rest.post(this.cnf.URLCustomerService + '/claim', request);
  }
  getIncDoc(claimId: any) {
    return this.rest.get(this.cnf.URLClaimService + '/incDoc/' + claimId);
  }
  getFund() {
    return this.rest.get(this.cnf.URLCustomerService + '/fund');
  }
  getFormatMoney() {
    return this.rest.get(this.cnf.URLCustomerService + '/formatMoney');
  }
}
