import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { HttpClient, HttpParams } from '@angular/common/http';
import { BeneDto } from '../../models/bene-dto.model';

import { ActivatedRoute, Router } from '@angular/router';
import { ClaimService } from '../../services/claim.service';
import { FileService } from '../../services/file.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

import { RESTResponse } from '../../models/restresponse';
import { MinorAlterationService } from '../../services/minor-alteration.service';

import { map } from 'rxjs/operators';
import { ReqModel } from '../../models/reqModel';
import { RESTRequest } from '../../models/restrequest.model';
import { CustomerService } from '../../services/customer.service';

import { FileProgress } from '../../models/file-progress';
import { LocalService } from '../../local.service';

import { ReqBene } from '../../models/req-bene.model';
import { FileClass } from '../../models/fileClass';
import { PersonalData } from '../../models/personal-data.model';

import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, NgForm } from '@angular/forms';
import { BeneDesignationDto } from '../../models/bene-designation-dto.model';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';
import { APIService } from '../../services/api.service';

@Component({
  selector: 'app-req-change-bene',
  templateUrl: './req-change-bene.component.html',
  styleUrls: ['./req-change-bene.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, FileService, MinorAlterationService, UserService]
})
export class ReqChangeBeneComponent implements OnInit {

  @ViewChild('reqBeneForm', { static: false }) reqBeneForm: NgForm;

  genders: Array<{ value: string; label: string }> = [
    { value: 'M', label: 'Laki-laki' },
    { value: 'F', label: 'Perempuan' }
  ];

  fileClass: FileClass = new FileClass();

  private imageSrc: string = '';

  beneDto: BeneDto[] = [];
  beneTempDto: BeneDto[] = [];
  beneDesignationDtos: BeneDesignationDto[] = [];

  isChecked = false;
  policyDetailData: any = {};
  formGroup: FormGroup;
  successMessage: string;
  policyInvestmentData: any = [];
  responseEmail: any;
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;
  public googleRecaptchaKey: any = '';
  disclaimer = new FormControl(false);
  loading = true;
  beneDob3: any;
  loadingButton: boolean = false;
  reqData: ReqModel = new ReqModel();
  policies: any[] = [];
  loadingPolicy: boolean = false;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;

  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  showIDCard11: boolean = true;
  showIDCard12: boolean = false;
  showIDCard13: boolean = false;
  showIDCard14: boolean = false;
  showIDCard15: boolean = false;
  showIDCard16: boolean = false;
  showIDCard17: boolean = false;

  showIDCard21: boolean = true;
  showIDCard22: boolean = false;
  showIDCard23: boolean = false;
  showIDCard24: boolean = false;
  showIDCard25: boolean = false;
  showIDCard26: boolean = false;
  showIDCard27: boolean = false;

  showIDCard31: boolean = true;
  showIDCard32: boolean = false;
  showIDCard33: boolean = false;
  showIDCard34: boolean = false;
  showIDCard35: boolean = false;
  showIDCard36: boolean = false;
  showIDCard37: boolean = false;

  showIDCard41: boolean = true;
  showIDCard42: boolean = false;
  showIDCard43: boolean = false;
  showIDCard44: boolean = false;
  showIDCard45: boolean = false;
  showIDCard46: boolean = false;
  showIDCard47: boolean = false;

  showIDCard51: boolean = true;
  showIDCard52: boolean = false;
  showIDCard53: boolean = false;
  showIDCard54: boolean = false;
  showIDCard55: boolean = false;
  showIDCard56: boolean = false;
  showIDCard57: boolean = false;

  filenumber: string;

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  reqRequest: ReqBene = new ReqBene();
  // reqData: RequestWithdrawal = new RequestWithdrawal();

  public userId: any = '';
  identityCardFileName11: string = 'Cari Dokumen...';
  identityCardFileName12: string = 'Cari Dokumen...';
  identityCardFileName13: string = 'Cari Dokumen...';
  identityCardFileName14: string = 'Cari Dokumen...';
  identityCardFileName15: string = 'Cari Dokumen...';
  identityCardFileName16: string = 'Cari Dokumen...';
  identityCardFileName17: string = 'Cari Dokumen...';

  identityCardFileName21: string = 'Cari Dokumen...';
  identityCardFileName22: string = 'Cari Dokumen...';
  identityCardFileName23: string = 'Cari Dokumen...';
  identityCardFileName24: string = 'Cari Dokumen...';
  identityCardFileName25: string = 'Cari Dokumen...';
  identityCardFileName26: string = 'Cari Dokumen...';
  identityCardFileName27: string = 'Cari Dokumen...';

  identityCardFileName31: string = 'Cari Dokumen...';
  identityCardFileName32: string = 'Cari Dokumen...';
  identityCardFileName33: string = 'Cari Dokumen...';
  identityCardFileName34: string = 'Cari Dokumen...';
  identityCardFileName35: string = 'Cari Dokumen...';
  identityCardFileName36: string = 'Cari Dokumen...';
  identityCardFileName37: string = 'Cari Dokumen...';

  identityCardFileName41: string = 'Cari Dokumen...';
  identityCardFileName42: string = 'Cari Dokumen...';
  identityCardFileName43: string = 'Cari Dokumen...';
  identityCardFileName44: string = 'Cari Dokumen...';
  identityCardFileName45: string = 'Cari Dokumen...';
  identityCardFileName46: string = 'Cari Dokumen...';
  identityCardFileName47: string = 'Cari Dokumen...';

  identityCardFileName51: string = 'Cari Dokumen...';
  identityCardFileName52: string = 'Cari Dokumen...';
  identityCardFileName53: string = 'Cari Dokumen...';
  identityCardFileName54: string = 'Cari Dokumen...';
  identityCardFileName55: string = 'Cari Dokumen...';
  identityCardFileName56: string = 'Cari Dokumen...';
  identityCardFileName57: string = 'Cari Dokumen...';
  params: any;

  loadingReq = true;

  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };
  policyHolder: any = {};

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private http: HttpClient,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private file: FileService,
    private translate: TranslateService,
    private minorAlterationService: MinorAlterationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cpSweetalertService: CpSweetalertService,
    private localService: LocalService,
    private customerService: CustomerService,
    private user: UserService,
    private notif: NotifWaEmailSms,
    private apiService: APIService
  ) {
    // disable address form group at init form
    // build form

    this.route.params.subscribe(params => (this.params = params));
    this.buildForm();
  }

  ngOnInit() {
    this.auditTrail.saveLog('Change Beneficiary', 'Open');
    this.loadingPolicy = true;
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Change Beneficiary' + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');
    this.detaillPolicy(this.params.policyNo);
    this.customerService.getBeneDesignation().subscribe(
      (res: any) => {
        const response: RESTResponse<BeneDesignationDto[]> = res.body;
        if (response.success) {
          this.beneDesignationDtos = response.data;
          this.detailBene(this.params.policyNo);
          this.detailPolicy(this.userId, this.params.policyNo);
        } else {
          console.log(response.message);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  detaillPolicy(policy: any) {
    this.policyDetail(policy)
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          this.loading = true;
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyDetailData = decryptData[0].policyInformation_detail[0];

          this.formGroup.controls.policyHolderName.setValue(this.policyDetailData.policyHolderName);
          this.formGroup.controls.mainLifeAssured.setValue(this.policyDetailData.mainLifeAssured);

          this.loadPolicyInvestmentData();
          this.loading = false;

        } else {
          this.router.navigate(['/404']);
        }
      });
  }

  detailBene(polisno: any) {
    this.customerService.getBene(polisno).subscribe(
      (res: any) => {
        const response: RESTResponse<BeneDto[]> = res.body;
        if (response.success) {
          this.beneDto = response.data;
          console.log(this.beneDto);
          if (this.beneDto[0] != null) {
            this.formGroup.controls.beneName.setValue(this.beneDto[0].customer.fullName);
            this.formGroup.controls.gender.setValue(this.convertGender(this.beneDto[0].customer.gender));
            this.formGroup.controls.beneDesignation.setValue(this.beneDto[0].tbeneDesignation.designationCode);
            this.formGroup.controls.beneDob.setValue(new Date(this.beneDto[0].customer.birthday));
            this.formGroup.controls.shareRate.setValue(this.beneDto[0].shareRate != null ? (Number(this.beneDto[0].shareRate) * 100) : null);

            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = this.beneDto[0].customer.fullName;
            cutomer.gender = this.beneDto[0].customer.gender;
            cutomer.birthday = this.getFormatDate(this.beneDto[0].customer.birthday);
            designation.designationCode = this.beneDto[0].tbeneDesignation.designationCode;
            designation.designationDesc = this.getDesignationDesc(this.beneDto[0].tbeneDesignation.designationCode);
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = this.beneDto[0].shareRate;
            this.beneTempDto.push(beneficiary);

          } else {
            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = "";
            cutomer.gender = "";
            cutomer.birthday = "";
            designation.designationCode = "";
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = "";
            this.beneTempDto.push(beneficiary);
          }

          if (this.beneDto[1] != null) {
            this.formGroup.controls.beneName2.setValue(this.beneDto[1].customer.fullName);
            this.formGroup.controls.gender2.setValue(this.convertGender(this.beneDto[1].customer.gender));
            this.formGroup.controls.beneDesignation2.setValue(this.beneDto[1].tbeneDesignation.designationCode);
            this.formGroup.controls.beneDob2.setValue(new Date(this.beneDto[1].customer.birthday));
            this.formGroup.controls.shareRate2.setValue(this.beneDto[1].shareRate != null ? (Number(this.beneDto[1].shareRate) * 100) : null);

            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = this.beneDto[1].customer.fullName;
            cutomer.gender = this.beneDto[1].customer.gender;
            cutomer.birthday = this.getFormatDate(this.beneDto[1].customer.birthday);
            designation.designationCode = this.beneDto[1].tbeneDesignation.designationCode;
            designation.designationDesc = this.getDesignationDesc(this.beneDto[1].tbeneDesignation.designationCode);
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = this.beneDto[1].shareRate;
            this.beneTempDto.push(beneficiary);

          } else {
            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = "";
            cutomer.gender = "";
            cutomer.birthday = "";
            designation.designationCode = "";
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = "";
            this.beneTempDto.push(beneficiary);
          }

          if (this.beneDto[2] != null) {
            this.formGroup.controls.beneName3.setValue(this.beneDto[2].customer.fullName);
            this.formGroup.controls.gender3.setValue(this.convertGender(this.beneDto[2].customer.gender));
            this.formGroup.controls.beneDesignation3.setValue(this.beneDto[2].tbeneDesignation.designationCode);
            this.formGroup.controls.beneDob3.setValue(new Date(this.beneDto[2].customer.birthday));
            this.formGroup.controls.shareRate3.setValue(this.beneDto[2].shareRate != null ? (Number(this.beneDto[2].shareRate) * 100) : null);

            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = this.beneDto[2].customer.fullName;
            cutomer.gender = this.beneDto[2].customer.gender;
            cutomer.birthday = this.getFormatDate(this.beneDto[2].customer.birthday);
            designation.designationCode = this.beneDto[2].tbeneDesignation.designationCode;
            designation.designationDesc = this.getDesignationDesc(this.beneDto[2].tbeneDesignation.designationCode);
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = this.beneDto[2].shareRate;
            this.beneTempDto.push(beneficiary);

          } else {
            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = "";
            cutomer.gender = "";
            cutomer.birthday = "";
            designation.designationCode = "";
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = "";
            this.beneTempDto.push(beneficiary);
          }

          if (this.beneDto[3] != null) {
            this.formGroup.controls.beneName4.setValue(this.beneDto[3].customer.fullName);
            this.formGroup.controls.gender4.setValue(this.convertGender(this.beneDto[3].customer.gender));
            this.formGroup.controls.beneDesignation4.setValue(this.beneDto[3].tbeneDesignation.designationCode);
            this.formGroup.controls.beneDob4.setValue(new Date(this.beneDto[3].customer.birthday));
            this.formGroup.controls.shareRate4.setValue(this.beneDto[3].shareRate != null ? (Number(this.beneDto[3].shareRate) * 100) : null);

            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = this.beneDto[3].customer.fullName;
            cutomer.gender = this.beneDto[3].customer.gender;
            cutomer.birthday = this.getFormatDate(this.beneDto[3].customer.birthday);
            designation.designationCode = this.beneDto[3].tbeneDesignation.designationCode;
            designation.designationDesc = this.getDesignationDesc(this.beneDto[3].tbeneDesignation.designationCode);
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = this.beneDto[3].shareRate;
            this.beneTempDto.push(beneficiary);

          } else {
            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = "";
            cutomer.gender = "";
            cutomer.birthday = "";
            designation.designationCode = "";
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = "";
            this.beneTempDto.push(beneficiary);
          }

          if (this.beneDto[4] != null) {
            this.formGroup.controls.beneName5.setValue(this.beneDto[4].customer.fullName);
            this.formGroup.controls.gender5.setValue(this.convertGender(this.beneDto[4].customer.gender));
            this.formGroup.controls.beneDesignation5.setValue(this.beneDto[4].tbeneDesignation.designationCode);
            this.formGroup.controls.beneDob5.setValue(new Date(this.beneDto[4].customer.birthday));
            this.formGroup.controls.shareRate5.setValue(this.beneDto[4].shareRate != null ? (Number(this.beneDto[4].shareRate) * 100) : null);

            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = this.beneDto[4].customer.fullName;
            cutomer.gender = this.beneDto[4].customer.gender;
            cutomer.birthday = this.getFormatDate(this.beneDto[4].customer.birthday);
            designation.designationCode = this.beneDto[4].tbeneDesignation.designationCode;
            designation.designationDesc = this.getDesignationDesc(this.beneDto[4].tbeneDesignation.designationCode);
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = this.beneDto[4].shareRate;
            this.beneTempDto.push(beneficiary);

          } else {
            let cutomer = new PersonalData();
            let beneficiary = new BeneDto();
            let designation = new BeneDesignationDto();
            cutomer.fullName = "";
            cutomer.gender = "";
            cutomer.birthday = "";
            designation.designationCode = "";
            beneficiary.customer = cutomer;
            beneficiary.tbeneDesignation = designation;
            beneficiary.shareRate = "";
            this.beneTempDto.push(beneficiary);
          }

        } else {
          console.log(response.message);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  convertGender(gender: any) {
    if (gender == "Female") {
      return "F";
    } else {
      return "M";
    }
  }

  detailPolicy(idUser: any, nopolis: any) {
    this.minorAlterationService.getMinorAlterationData(idUser).subscribe(
      (res: any) => {
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          if (this.reqData.policyNumbers.length > 0) {
            for (const policyNumber of this.reqData.policyNumbers) {
              const newPolicy = {
                value: policyNumber.policyCode,
                label: policyNumber.policyCode
              };
              this.policies.push(newPolicy);
            }
            this.formGroup.controls.policyNo.setValue(nopolis);
            this.loadingPolicy = false;
          }
        }
      }
    );
  }

  getDesignationDesc(code: any) {
    var res = "";
    for (var design of this.beneDesignationDtos) {
      if (code == design.designationCode) {
        res = design.designationDesc;
        // var designation = design.designationDesc.split("/");
        // if (designation.length == 1) {
        //   res = designation[0];
        // } else {
        //   res = designation[0].replace(" ", "");
        // }
      }
    }
    return res;
  }

  getFormatDate(date: any) {
    const dateNew = new Date(date);
    return dateNew.getFullYear() + '-' + (dateNew.getMonth() + 1) + '-' + dateNew.getDate();
  }

  choicePolicy(data: any) {
    this.detailBene(data.value);
    this.detaillPolicy(data.value);
  }

  /**
  * @desc on KTP selected function
  */
  onIdentityCardFileSelect(event: any, id: any) {
    this.filenumber = id;
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          if ('11' == this.filenumber) {
            this.reqRequest.idRefBene11 = identityCardPhoto;
            this.identityCardFileName11 = this.newFileName;
          }
          if ('12' == this.filenumber) {
            this.reqRequest.idRefBene12 = identityCardPhoto;
            this.identityCardFileName12 = this.newFileName;
          }
          if ('13' == this.filenumber) {
            this.reqRequest.idRefBene13 = identityCardPhoto;
            this.identityCardFileName13 = this.newFileName;
          }
          if ('14' == this.filenumber) {
            this.reqRequest.idRefBene14 = identityCardPhoto;
            this.identityCardFileName14 = this.newFileName;
          }
          if ('15' == this.filenumber) {
            this.reqRequest.idRefBene15 = identityCardPhoto;
            this.identityCardFileName15 = this.newFileName;
          }
          if ('16' == this.filenumber) {
            this.reqRequest.idRefBene16 = identityCardPhoto;
            this.identityCardFileName16 = this.newFileName;
          }
          if ('17' == this.filenumber) {
            this.reqRequest.idRefBene17 = identityCardPhoto;
            this.identityCardFileName17 = this.newFileName;
          }

          if ('21' == this.filenumber) {
            this.reqRequest.idRefBene21 = identityCardPhoto;
            this.identityCardFileName21 = this.newFileName;
          }
          if ('22' == this.filenumber) {
            this.reqRequest.idRefBene22 = identityCardPhoto;
            this.identityCardFileName22 = this.newFileName;
          }
          if ('23' == this.filenumber) {
            this.reqRequest.idRefBene23 = identityCardPhoto;
            this.identityCardFileName23 = this.newFileName;
          }
          if ('24' == this.filenumber) {
            this.reqRequest.idRefBene24 = identityCardPhoto;
            this.identityCardFileName24 = this.newFileName;
          }
          if ('25' == this.filenumber) {
            this.reqRequest.idRefBene25 = identityCardPhoto;
            this.identityCardFileName25 = this.newFileName;
          }
          if ('26' == this.filenumber) {
            this.reqRequest.idRefBene26 = identityCardPhoto;
            this.identityCardFileName26 = this.newFileName;
          }
          if ('27' == this.filenumber) {
            this.reqRequest.idRefBene27 = identityCardPhoto;
            this.identityCardFileName27 = this.newFileName;
          }

          if ('31' == this.filenumber) {
            this.reqRequest.idRefBene31 = identityCardPhoto;
            this.identityCardFileName31 = this.newFileName;
          }
          if ('32' == this.filenumber) {
            this.reqRequest.idRefBene32 = identityCardPhoto;
            this.identityCardFileName32 = this.newFileName;
          }
          if ('33' == this.filenumber) {
            this.reqRequest.idRefBene33 = identityCardPhoto;
            this.identityCardFileName33 = this.newFileName;
          }
          if ('34' == this.filenumber) {
            this.reqRequest.idRefBene34 = identityCardPhoto;
            this.identityCardFileName34 = this.newFileName;
          }
          if ('35' == this.filenumber) {
            this.reqRequest.idRefBene35 = identityCardPhoto;
            this.identityCardFileName35 = this.newFileName;
          }
          if ('36' == this.filenumber) {
            this.reqRequest.idRefBene36 = identityCardPhoto;
            this.identityCardFileName36 = this.newFileName;
          }
          if ('37' == this.filenumber) {
            this.reqRequest.idRefBene37 = identityCardPhoto;
            this.identityCardFileName37 = this.newFileName;
          }

          if ('41' == this.filenumber) {
            this.reqRequest.idRefBene41 = identityCardPhoto;
            this.identityCardFileName41 = this.newFileName;
          }
          if ('42' == this.filenumber) {
            this.reqRequest.idRefBene42 = identityCardPhoto;
            this.identityCardFileName42 = this.newFileName;
          }
          if ('43' == this.filenumber) {
            this.reqRequest.idRefBene43 = identityCardPhoto;
            this.identityCardFileName43 = this.newFileName;
          }
          if ('44' == this.filenumber) {
            this.reqRequest.idRefBene44 = identityCardPhoto;
            this.identityCardFileName44 = this.newFileName;
          }
          if ('45' == this.filenumber) {
            this.reqRequest.idRefBene45 = identityCardPhoto;
            this.identityCardFileName45 = this.newFileName;
          }
          if ('46' == this.filenumber) {
            this.reqRequest.idRefBene46 = identityCardPhoto;
            this.identityCardFileName46 = this.newFileName;
          }
          if ('47' == this.filenumber) {
            this.reqRequest.idRefBene47 = identityCardPhoto;
            this.identityCardFileName47 = this.newFileName;
          }

          if ('51' == this.filenumber) {
            this.reqRequest.idRefBene51 = identityCardPhoto;
            this.identityCardFileName51 = this.newFileName;
          }
          if ('52' == this.filenumber) {
            this.reqRequest.idRefBene52 = identityCardPhoto;
            this.identityCardFileName52 = this.newFileName;
          }
          if ('53' == this.filenumber) {
            this.reqRequest.idRefBene53 = identityCardPhoto;
            this.identityCardFileName53 = this.newFileName;
          }
          if ('54' == this.filenumber) {
            this.reqRequest.idRefBene54 = identityCardPhoto;
            this.identityCardFileName54 = this.newFileName;
          }
          if ('55' == this.filenumber) {
            this.reqRequest.idRefBene55 = identityCardPhoto;
            this.identityCardFileName55 = this.newFileName;
          }
          if ('56' == this.filenumber) {
            this.reqRequest.idRefBene56 = identityCardPhoto;
            this.identityCardFileName56 = this.newFileName;
          }
          if ('57' == this.filenumber) {
            this.reqRequest.idRefBene57 = identityCardPhoto;
            this.identityCardFileName57 = this.newFileName;
          }

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  /**
 * @desc create custom random char for file-name
 */
  makecustom(length: any) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  /**
   * @desc create new file with random name
   * @param event File event
   */
  makeCustomFileName(event: any) {
    const target = event.target.files[0];
    const date = new Date();
    const ext = target.type.split('/').pop();
    const dateFormat =
      date.getFullYear() +
      '' +
      (date.getMonth() + 1) +
      '' +
      date.getDate() +
      '' +
      date.getHours() +
      '' +
      date.getMinutes();
    const newFileName = this.makecustom(8) + dateFormat + '.' + ext;
    const file = new File([target], newFileName, { type: target.type });
    return file;
  }

  loadPolicyInvestmentData() {
    this.policyInvestment()
      .subscribe((res: any) => {
        if (res.body.status == 100) {
          let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
          this.policyInvestmentData = decryptData[0].policyInformation_fund_invest;
        }
      });
  }

  /**
   * @desc on disclaimer checbox change
   */
  changeCheck(event: any) {
    // reset form Error Counter
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;

    this.errorAllCounter(this.formGroup);

    const count = this.formCountError.count;

    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  /**
   * @desc fire validate form
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  policyInvestment() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(
    //     this.cnf.URLWSNonPublicArea + '/policy_information/fund_invest/policy/' + this.params.policyNo,
    //     paramsObject
    //   )
    //   .pipe(map((response: Response) => response));
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/fund_invest/policy/' + this.params.policyNo);
  }

  /**
   * @desc Check all error form input
   */
  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  policyDetail(policy: any) {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);

    // let paramsObject = this.cnf.convertPostData(params);

    // return this.http
    //   .post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + policy, paramsObject)
    //   .pipe(map((response: Response) => response));

    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/policy_information/detail/policy/' + policy);
  }



  /**
   * @desc Build formGroup
   */
  buildForm() {
    this.formGroup = this.formBuilder.group({

      mainLifeAssured: new FormControl({ value: null, disabled: true }, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      policyNo: new FormControl({ value: null, disabled: false }, Validators.required),
      email: new FormControl({ value: null, disabled: true }),
      dispatchType: new FormControl({ value: null, disabled: false }),
      identityCardPhoto1: new FormControl(null),
      identityCardPhoto2: new FormControl(null),
      identityCardPhoto3: new FormControl(null),
      identityCardPhoto4: new FormControl(null),
      identityCardPhoto5: new FormControl(null),
      address2: new FormControl({ value: null, disabled: false }),
      disclaimer: this.disclaimer,

      gender: new FormControl(null),
      gender2: new FormControl(null),
      gender3: new FormControl(null),
      gender4: new FormControl(null),
      gender5: new FormControl(null),

      beneDesignation: new FormControl(null),
      beneDesignation2: new FormControl(null),
      beneDesignation3: new FormControl(null),
      beneDesignation4: new FormControl(null),
      beneDesignation5: new FormControl(null),

      beneName: new FormControl(null),
      beneName2: new FormControl(null),
      beneName3: new FormControl(null),
      beneName4: new FormControl(null),
      beneName5: new FormControl(null),

      beneDob: new FormControl(null),
      beneDob2: new FormControl(null),
      beneDob3: new FormControl(null),
      beneDob4: new FormControl(null),
      beneDob5: new FormControl(null),

      shareRate: new FormControl(null),
      shareRate2: new FormControl(null),
      shareRate3: new FormControl(null),
      shareRate4: new FormControl(null),
      shareRate5: new FormControl(null),

    });
  }

  /**
 * @desc on submit 
 */
  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqpolis', captchaToken => {
        const formValue = this.formGroup.value;

        this.reqRequest.applicationDate = new Date();

        this.reqRequest.beneName = formValue.beneName;
        this.reqRequest.beneName2 = formValue.beneName2;
        this.reqRequest.beneName3 = formValue.beneName3;
        this.reqRequest.beneName4 = formValue.beneName4;
        this.reqRequest.beneName5 = formValue.beneName5;
        if (formValue.beneName != null) {
          if (this.beneTempDto[0].customer.fullName != formValue.beneName) {
            this.auditTrail.auditTrailBefore.beneficiaryName1 = this.beneTempDto[0].customer.fullName;
            this.auditTrail.auditTrailAfter.beneficiaryName1 = formValue.beneName;
          }
        }
        if (formValue.beneName2 != null) {
          if (this.beneTempDto[1].customer.fullName != formValue.beneName2) {
            this.auditTrail.auditTrailBefore.beneficiaryName2 = this.beneTempDto[1].customer.fullName;
            this.auditTrail.auditTrailAfter.beneficiaryName2 = formValue.beneName2;
          }
        }
        if (formValue.beneName3 != null) {
          if (this.beneTempDto[2].customer.fullName != formValue.beneName3) {
            this.auditTrail.auditTrailBefore.beneficiaryName3 = this.beneTempDto[2].customer.fullName;
            this.auditTrail.auditTrailAfter.beneficiaryName3 = formValue.beneName3;
          }
        }
        if (formValue.beneName4 != null) {
          if (this.beneTempDto[3].customer.fullName != formValue.beneName4) {
            this.auditTrail.auditTrailBefore.beneficiaryName4 = this.beneTempDto[3].customer.fullName;
            this.auditTrail.auditTrailAfter.beneficiaryName4 = formValue.beneName4;
          }
        }
        if (formValue.beneName5 != null) {
          if (this.beneTempDto[4].customer.fullName != formValue.beneName5) {
            this.auditTrail.auditTrailBefore.beneficiaryName5 = this.beneTempDto[4].customer.fullName;
            this.auditTrail.auditTrailAfter.beneficiaryName5 = formValue.beneName5;
          }
        }

        this.reqRequest.beneGender = formValue.gender;
        this.reqRequest.beneGender2 = formValue.gender2;
        this.reqRequest.beneGender3 = formValue.gender3;
        this.reqRequest.beneGender4 = formValue.gender4;
        this.reqRequest.beneGender5 = formValue.gender5;
        if (formValue.gender != null) {
          if (this.beneTempDto[0].customer.gender != formValue.gender) {
            this.auditTrail.auditTrailBefore.beneficiaryGender1 = this.beneTempDto[0].customer.gender;
            this.auditTrail.auditTrailAfter.beneficiaryGender1 = formValue.gender;
          }
        }
        if (formValue.gender2 != null) {
          if (this.beneTempDto[1].customer.gender != formValue.gender2) {
            this.auditTrail.auditTrailBefore.beneficiaryGender2 = this.beneTempDto[1].customer.gender;
            this.auditTrail.auditTrailAfter.beneficiaryGender2 = formValue.gender2;
          }
        }
        if (formValue.gender3 != null) {
          if (this.beneTempDto[2].customer.gender != formValue.gender3) {
            this.auditTrail.auditTrailBefore.beneficiaryGender3 = this.beneTempDto[2].customer.gender;
            this.auditTrail.auditTrailAfter.beneficiaryGender3 = formValue.gender3;
          }
        }
        if (formValue.gender4 != null) {
          if (this.beneTempDto[3].customer.gender != formValue.gender4) {
            this.auditTrail.auditTrailBefore.beneficiaryGender4 = this.beneTempDto[3].customer.gender;
            this.auditTrail.auditTrailAfter.beneficiaryGender4 = formValue.gender4;
          }
        }
        if (formValue.gender5 != null) {
          if (this.beneTempDto[4].customer.gender != formValue.gender5) {
            this.auditTrail.auditTrailBefore.beneficiaryGender5 = this.beneTempDto[4].customer.gender;
            this.auditTrail.auditTrailAfter.beneficiaryGender5 = formValue.gender5;
          }
        }

        this.reqRequest.beneDob = formValue.beneDob;
        this.reqRequest.beneDob2 = formValue.beneDob2;
        this.reqRequest.beneDob3 = formValue.beneDob3;
        this.reqRequest.beneDob4 = formValue.beneDob4;
        this.reqRequest.beneDob5 = formValue.beneDob5;
        if (formValue.beneDob != null) {
          const dob1 = this.getFormatDate(formValue.beneDob);
          if (this.beneTempDto[0].customer.birthday != dob1) {
            this.auditTrail.auditTrailBefore.beneficiaryDob1 = new Date(this.beneTempDto[0].customer.birthday);
            this.auditTrail.auditTrailAfter.beneficiaryDob1 = new Date(dob1);
          }
        }
        if (formValue.beneDob2 != null) {
          const dob2 = this.getFormatDate(formValue.beneDob2);
          if (this.beneTempDto[1].customer.birthday != dob2) {
            this.auditTrail.auditTrailBefore.beneficiaryDob2 = new Date(this.beneTempDto[1].customer.birthday);
            this.auditTrail.auditTrailAfter.beneficiaryDob2 = new Date(dob2);
          }
        }
        if (formValue.beneDob3 != null) {
          const dob3 = this.getFormatDate(formValue.beneDob3);
          if (this.beneTempDto[2].customer.birthday != dob3) {
            this.auditTrail.auditTrailBefore.beneficiaryDob3 = new Date(this.beneTempDto[2].customer.birthday);
            this.auditTrail.auditTrailAfter.beneficiaryDob3 = new Date(dob3);
          }
        }
        if (formValue.beneDob4 != null) {
          const dob4 = this.getFormatDate(formValue.beneDob4);
          if (this.beneTempDto[3].customer.birthday != dob4) {
            this.auditTrail.auditTrailBefore.beneficiaryDob4 = new Date(this.beneTempDto[3].customer.birthday);
            this.auditTrail.auditTrailAfter.beneficiaryDob4 = new Date(dob4);
          }
        }
        if (formValue.beneDob5 != null) {
          const dob5 = this.getFormatDate(formValue.beneDob5);
          if (this.beneTempDto[4].customer.birthday != dob5) {
            this.auditTrail.auditTrailBefore.beneficiaryDob5 = new Date(this.beneTempDto[4].customer.birthday);
            this.auditTrail.auditTrailAfter.beneficiaryDob5 = new Date(dob5);
          }
        }

        this.reqRequest.beneDesignation = formValue.beneDesignation;
        this.reqRequest.beneDesignation2 = formValue.beneDesignation2;
        this.reqRequest.beneDesignation3 = formValue.beneDesignation3;
        this.reqRequest.beneDesignation4 = formValue.beneDesignation4;
        this.reqRequest.beneDesignation5 = formValue.beneDesignation5;
        if (formValue.beneDesignation != null) {
          if (this.beneTempDto[0].tbeneDesignation.designationCode != formValue.beneDesignation) {
            this.auditTrail.auditTrailBefore.beneficiaryDesignation1 = this.beneTempDto[0].tbeneDesignation.designationDesc;
            this.auditTrail.auditTrailAfter.beneficiaryDesignation1 = this.getDesignationDesc(formValue.beneDesignation);
          }
        }
        if (formValue.beneDesignation2 != null) {
          if (this.beneTempDto[1].tbeneDesignation.designationCode != formValue.beneDesignation2) {
            this.auditTrail.auditTrailBefore.beneficiaryDesignation2 = this.beneTempDto[1].tbeneDesignation.designationDesc;
            this.auditTrail.auditTrailAfter.beneficiaryDesignation2 = this.getDesignationDesc(formValue.beneDesignation2);
          }
        }
        if (formValue.beneDesignation3 != null) {
          if (this.beneTempDto[2].tbeneDesignation.designationCode != formValue.beneDesignation3) {
            this.auditTrail.auditTrailBefore.beneficiaryDesignation3 = this.beneTempDto[2].tbeneDesignation.designationDesc;
            this.auditTrail.auditTrailAfter.beneficiaryDesignation3 = this.getDesignationDesc(formValue.beneDesignation3);
          }
        }
        if (formValue.beneDesignation4 != null) {
          if (this.beneTempDto[3].tbeneDesignation.designationCode != formValue.beneDesignation4) {
            this.auditTrail.auditTrailBefore.beneficiaryDesignation4 = this.beneTempDto[3].tbeneDesignation.designationDesc;
            this.auditTrail.auditTrailAfter.beneficiaryDesignation4 = this.getDesignationDesc(formValue.beneDesignation4);
          }
        }
        if (formValue.beneDesignation5 != null) {
          if (this.beneTempDto[4].tbeneDesignation.designationCode != formValue.beneDesignation5) {
            this.auditTrail.auditTrailBefore.beneficiaryDesignation5 = this.beneTempDto[4].tbeneDesignation.designationDesc;
            this.auditTrail.auditTrailAfter.beneficiaryDesignation5 = this.getDesignationDesc(formValue.beneDesignation5);
          }
        }

        this.reqRequest.beneShare = formValue.shareRate != null ? "" + (Number(formValue.shareRate) / 100) : null;
        this.reqRequest.beneShare2 = formValue.shareRate2 != null ? "" + (Number(formValue.shareRate2) / 100) : null;
        this.reqRequest.beneShare3 = formValue.shareRate3 != null ? "" + (Number(formValue.shareRate3) / 100) : null;
        this.reqRequest.beneShare4 = formValue.shareRate4 != null ? "" + (Number(formValue.shareRate4) / 100) : null;
        this.reqRequest.beneShare5 = formValue.shareRate5 != null ? "" + (Number(formValue.shareRate5) / 100) : null;

        if (formValue.shareRate != null) {
          if (this.beneTempDto[0].shareRate != formValue.shareRate) {
            this.auditTrail.auditTrailBefore.beneficiaryShare1 = "" + this.beneTempDto[0].shareRate;
            this.auditTrail.auditTrailAfter.beneficiaryShare1 = "" + formValue.shareRate;
          }
        }
        if (formValue.shareRate2 != null) {
          if (this.beneTempDto[1].shareRate != formValue.shareRate2) {
            this.auditTrail.auditTrailBefore.beneficiaryShare2 = "" + this.beneTempDto[1].shareRate;
            this.auditTrail.auditTrailAfter.beneficiaryShare2 = "" + formValue.shareRate2;
          }
        }
        if (formValue.shareRate3 != null) {
          if (this.beneTempDto[2].shareRate != formValue.shareRate3) {
            this.auditTrail.auditTrailBefore.beneficiaryShare3 = "" + this.beneTempDto[2].shareRate;
            this.auditTrail.auditTrailAfter.beneficiaryShare3 = "" + formValue.shareRate3;
          }
        }
        if (formValue.shareRate4 != null) {
          if (this.beneTempDto[3].shareRate != formValue.shareRate4) {
            this.auditTrail.auditTrailBefore.beneficiaryShare4 = "" + this.beneTempDto[3].shareRate;
            this.auditTrail.auditTrailAfter.beneficiaryShare4 = "" + formValue.shareRate4;
          }
        }
        if (formValue.shareRate5 != null) {
          if (this.beneTempDto[4].shareRate != formValue.shareRate5) {
            this.auditTrail.auditTrailBefore.beneficiaryShare5 = "" + this.beneTempDto[4].shareRate;
            this.auditTrail.auditTrailAfter.beneficiaryShare5 = "" + formValue.shareRate5;
          }
        }

        this.reqRequest.policyNumber = this.params.policyNo;
        this.reqRequest.touchPoint = 'Customer Corner';

        this.policyCode = formValue.policyNo;
        this.capthaTemp = captchaToken;

        this.user.requestOTP(this.userId, 'beneficiary', 'sms').subscribe((res: any) => {
          this.startOTP = (+res.datas.countotp) + 1;
          this.process = false;
          this.kodeOTP = null;
          if (this.startOTP <= 3) {
            if (res.datas.handphone != null) {
              this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
              this.responseHP = res.datas.handphone;
              this.perOTP = this.startOTP + '/' + this.endOTP;
              if (this.startOTP == this.endOTP) {
                this.maxOtp = true;
              }
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
            }
          } else {
            this.message = res.datas.msg;
            this.displayOTP = true;
            this.otpFail = true;
            let el: HTMLElement = this.myOpen.nativeElement;
            el.click();
          }
        }, err => {
          console.log(err.message);
        });
      });
    } else {
      this.cpSweetalertService.show('_error_submit_beneficiary', 'warning');
    }
  }

  /**
  * @desc Reset Form
  */
  resetForm() {
    this.reqBeneForm.resetForm();
  }

  index1: number = 0;
  index2: number = 0;
  index3: number = 0;
  index4: number = 0;
  index5: number = 0;

  showID(bene: any) {
    if (bene == 'bene1') {
      this.index1 = this.index1 + 1;
      if (this.index1 == 1) {
        this.showIDCard12 = true;
      }
      if (this.index1 == 2) {
        this.showIDCard13 = true;
      }
      if (this.index1 == 3) {
        this.showIDCard14 = true;
      }
      if (this.index1 == 4) {
        this.showIDCard15 = true;
      }
      if (this.index1 == 5) {
        this.showIDCard16 = true;
      }
      if (this.index1 == 6) {
        this.showIDCard17 = true;
        this.showIDCard11 = false;
      }
      if (this.index1 >= 7) {
        this.index1 = 6;
      }
    }

    if (bene == 'bene2') {
      this.index2 = this.index2 + 1;
      if (this.index2 == 1) {
        this.showIDCard22 = true;
      }
      if (this.index2 == 2) {
        this.showIDCard23 = true;
      }
      if (this.index2 == 3) {
        this.showIDCard24 = true;
      }
      if (this.index2 == 4) {
        this.showIDCard25 = true;
      }
      if (this.index2 == 5) {
        this.showIDCard26 = true;
      }
      if (this.index2 == 6) {
        this.showIDCard27 = true;
        this.showIDCard21 = false;
      }
      if (this.index2 >= 7) {
        this.index2 = 6;
      }
    }

    if (bene == 'bene3') {
      this.index3 = this.index3 + 1;
      if (this.index3 == 1) {
        this.showIDCard32 = true;
      }
      if (this.index3 == 2) {
        this.showIDCard33 = true;
      }
      if (this.index3 == 3) {
        this.showIDCard34 = true;
      }
      if (this.index3 == 4) {
        this.showIDCard35 = true;
      }
      if (this.index3 == 5) {
        this.showIDCard36 = true;
      }
      if (this.index3 == 6) {
        this.showIDCard37 = true;
        this.showIDCard31 = false;
      }
      if (this.index3 >= 7) {
        this.index3 = 6;
      }
    }

    if (bene == 'bene4') {
      this.index4 = this.index4 + 1;
      if (this.index4 == 1) {
        this.showIDCard42 = true;
      }
      if (this.index4 == 2) {
        this.showIDCard43 = true;
      }
      if (this.index4 == 3) {
        this.showIDCard44 = true;
      }
      if (this.index4 == 4) {
        this.showIDCard45 = true;
      }
      if (this.index4 == 5) {
        this.showIDCard46 = true;
      }
      if (this.index4 == 6) {
        this.showIDCard47 = true;
        this.showIDCard41 = false;
      }
      if (this.index4 >= 7) {
        this.index4 = 6;
      }
    }

    if (bene == 'bene5') {
      this.index5 = this.index5 + 1;
      if (this.index5 == 1) {
        this.showIDCard52 = true;
      }
      if (this.index5 == 2) {
        this.showIDCard53 = true;
      }
      if (this.index5 == 3) {
        this.showIDCard54 = true;
      }
      if (this.index5 == 4) {
        this.showIDCard55 = true;
      }
      if (this.index5 == 5) {
        this.showIDCard56 = true;
      }
      if (this.index5 == 6) {
        this.showIDCard57 = true;
        this.showIDCard51 = false;
      }
      if (this.index5 >= 7) {
        this.index5 = 6;
      }
    }
  }

  removeID(id: any, bene: any) {
    if (bene == 'bene1') {
      if (id == 2) {
        this.showIDCard12 = false;
        this.reqRequest.idRefBene12 = null;
        this.identityCardFileName12 = 'Cari Dokumen...';
      }
      if (id == 3) {
        this.showIDCard13 = false;
        this.reqRequest.idRefBene13 = null;
        this.identityCardFileName13 = 'Cari Dokumen...';
      }
      if (id == 4) {
        this.showIDCard14 = false;
        this.reqRequest.idRefBene14 = null;
        this.identityCardFileName14 = 'Cari Dokumen...';
      }
      if (id == 5) {
        this.showIDCard15 = false;
        this.reqRequest.idRefBene15 = null;
        this.identityCardFileName15 = 'Cari Dokumen...';
      }
      if (id == 6) {
        this.showIDCard16 = false;
        this.reqRequest.idRefBene16 = null;
        this.identityCardFileName16 = 'Cari Dokumen...';
      }
      if (id == 7) {
        this.showIDCard17 = false;
        this.reqRequest.idRefBene17 = null;
        this.identityCardFileName17 = 'Cari Dokumen...';
      }
      this.index1 = this.index1 - 1;

      if (this.index1 < 6) {
        this.showIDCard11 = true;
      }
    }

    if (bene == 'bene2') {
      if (id == 2) {
        this.showIDCard22 = false;
        this.reqRequest.idRefBene22 = null;
        this.identityCardFileName22 = 'Cari Dokumen...';
      }
      if (id == 3) {
        this.showIDCard23 = false;
        this.reqRequest.idRefBene23 = null;
        this.identityCardFileName23 = 'Cari Dokumen...';
      }
      if (id == 4) {
        this.showIDCard24 = false;
        this.reqRequest.idRefBene23 = null;
        this.identityCardFileName24 = 'Cari Dokumen...';
      }
      if (id == 5) {
        this.showIDCard25 = false;
        this.reqRequest.idRefBene25 = null;
        this.identityCardFileName25 = 'Cari Dokumen...';
      }
      if (id == 6) {
        this.showIDCard26 = false;
        this.reqRequest.idRefBene26 = null;
        this.identityCardFileName26 = 'Cari Dokumen...';
      }
      if (id == 7) {
        this.showIDCard27 = false;
        this.reqRequest.idRefBene27 = null;
        this.identityCardFileName27 = 'Cari Dokumen...';
      }
      this.index2 = this.index2 - 1;

      if (this.index2 < 6) {
        this.showIDCard21 = true;
      }
    }

    if (bene == 'bene3') {
      if (id == 2) {
        this.showIDCard32 = false;
        this.reqRequest.idRefBene32 = null;
        this.identityCardFileName32 = 'Cari Dokumen...';
      }
      if (id == 3) {
        this.showIDCard33 = false;
        this.reqRequest.idRefBene32 = null;
        this.identityCardFileName33 = 'Cari Dokumen...';
      }
      if (id == 4) {
        this.showIDCard34 = false;
        this.reqRequest.idRefBene33 = null;
        this.identityCardFileName34 = 'Cari Dokumen...';
      }
      if (id == 5) {
        this.showIDCard35 = false;
        this.reqRequest.idRefBene35 = null;
        this.identityCardFileName35 = 'Cari Dokumen...';
      }
      if (id == 6) {
        this.showIDCard36 = false;
        this.reqRequest.idRefBene36 = null;
        this.identityCardFileName36 = 'Cari Dokumen...';
      }
      if (id == 7) {
        this.showIDCard37 = false;
        this.reqRequest.idRefBene37 = null;
        this.identityCardFileName37 = 'Cari Dokumen...';
      }
      this.index3 = this.index3 - 1;

      if (this.index3 < 6) {
        this.showIDCard31 = true;
      }
    }

    if (bene == 'bene4') {
      if (id == 2) {
        this.showIDCard42 = false;
        this.reqRequest.idRefBene42 = null;
        this.identityCardFileName42 = 'Cari Dokumen...';
      }
      if (id == 3) {
        this.showIDCard43 = false;
        this.reqRequest.idRefBene42 = null;
        this.identityCardFileName43 = 'Cari Dokumen...';
      }
      if (id == 4) {
        this.showIDCard44 = false;
        this.reqRequest.idRefBene43 = null;
        this.identityCardFileName44 = 'Cari Dokumen...';
      }
      if (id == 5) {
        this.showIDCard45 = false;
        this.reqRequest.idRefBene45 = null;
        this.identityCardFileName45 = 'Cari Dokumen...';
      }
      if (id == 6) {
        this.showIDCard46 = false;
        this.reqRequest.idRefBene46 = null;
        this.identityCardFileName46 = 'Cari Dokumen...';
      }
      if (id == 7) {
        this.showIDCard47 = false;
        this.reqRequest.idRefBene47 = null;
        this.identityCardFileName47 = 'Cari Dokumen...';
      }
      this.index4 = this.index4 - 1;

      if (this.index4 < 6) {
        this.showIDCard41 = true;
      }
    }

    if (bene == 'bene5') {
      if (id == 2) {
        this.showIDCard52 = false;
        this.reqRequest.idRefBene52 = null;
        this.identityCardFileName52 = 'Cari Dokumen...';
      }
      if (id == 3) {
        this.showIDCard53 = false;
        this.reqRequest.idRefBene53 = null;
        this.identityCardFileName53 = 'Cari Dokumen...';
      }
      if (id == 4) {
        this.showIDCard54 = false;
        this.reqRequest.idRefBene54 = null;
        this.identityCardFileName54 = 'Cari Dokumen...';
      }
      if (id == 5) {
        this.showIDCard55 = false;
        this.reqRequest.idRefBene55 = null;
        this.identityCardFileName55 = 'Cari Dokumen...';
      }
      if (id == 6) {
        this.showIDCard56 = false;
        this.reqRequest.idRefBene56 = null;
        this.identityCardFileName56 = 'Cari Dokumen...';
      }
      if (id == 7) {
        this.showIDCard57 = false;
        this.reqRequest.idRefBene57 = null;
        this.identityCardFileName57 = 'Cari Dokumen...';
      }
      this.index5 = this.index5 - 1;

      if (this.index5 < 6) {
        this.showIDCard51 = true;
      }
    }
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'beneficiary', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
      } else {
        this.message = res.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'beneficiary', this.kodeOTP).subscribe((res: any) => {
        if (res.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();

            const req = new RESTRequest<ReqBene>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;

            this.minorAlterationService
              .submitReqUpdateBene(req, this.userId, this.policyCode, this.policyDetailData.policyHolderName)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('Terima kasih atas pengajuan Perubahan Penerima Manfaat Polis Anda. Kami akan memeriksa kembali kelengkapan pengajuan Perubahan Penerima Manfaat dan menyetujui apabila pengajuan telah lengkap sesuai Ketentuan yang berlaku', 'success');
                    this.resetForm();
                    this.auditTrail.saveLog('Change Beneficiary', 'Submit Success#' + this.policyCode);
                    this.identityCardFileName11 = 'Cari Dokumen...';
                    this.identityCardFileName12 = 'Cari Dokumen...';
                    this.identityCardFileName13 = 'Cari Dokumen...';
                    this.identityCardFileName14 = 'Cari Dokumen...';
                    this.identityCardFileName15 = 'Cari Dokumen...';
                    this.identityCardFileName16 = 'Cari Dokumen...';
                    this.identityCardFileName17 = 'Cari Dokumen...';

                    this.identityCardFileName21 = 'Cari Dokumen...';
                    this.identityCardFileName22 = 'Cari Dokumen...';
                    this.identityCardFileName23 = 'Cari Dokumen...';
                    this.identityCardFileName24 = 'Cari Dokumen...';
                    this.identityCardFileName25 = 'Cari Dokumen...';
                    this.identityCardFileName26 = 'Cari Dokumen...';
                    this.identityCardFileName27 = 'Cari Dokumen...';

                    this.identityCardFileName31 = 'Cari Dokumen...';
                    this.identityCardFileName32 = 'Cari Dokumen...';
                    this.identityCardFileName33 = 'Cari Dokumen...';
                    this.identityCardFileName34 = 'Cari Dokumen...';
                    this.identityCardFileName35 = 'Cari Dokumen...';
                    this.identityCardFileName36 = 'Cari Dokumen...';
                    this.identityCardFileName37 = 'Cari Dokumen...';

                    this.identityCardFileName41 = 'Cari Dokumen...';
                    this.identityCardFileName42 = 'Cari Dokumen...';
                    this.identityCardFileName43 = 'Cari Dokumen...';
                    this.identityCardFileName44 = 'Cari Dokumen...';
                    this.identityCardFileName45 = 'Cari Dokumen...';
                    this.identityCardFileName46 = 'Cari Dokumen...';
                    this.identityCardFileName47 = 'Cari Dokumen...';

                    this.identityCardFileName51 = 'Cari Dokumen...';
                    this.identityCardFileName52 = 'Cari Dokumen...';
                    this.identityCardFileName53 = 'Cari Dokumen...';
                    this.identityCardFileName54 = 'Cari Dokumen...';
                    this.identityCardFileName55 = 'Cari Dokumen...';
                    this.identityCardFileName56 = 'Cari Dokumen...';
                    this.identityCardFileName57 = 'Cari Dokumen...';

                    this.showIDCard11 = true;
                    this.showIDCard12 = false;
                    this.showIDCard13 = false;
                    this.showIDCard14 = false;
                    this.showIDCard15 = false;
                    this.showIDCard16 = false;
                    this.showIDCard17 = false;

                    this.showIDCard21 = true;
                    this.showIDCard22 = false;
                    this.showIDCard23 = false;
                    this.showIDCard24 = false;
                    this.showIDCard25 = false;
                    this.showIDCard26 = false;
                    this.showIDCard27 = false;

                    this.showIDCard31 = true;
                    this.showIDCard32 = false;
                    this.showIDCard33 = false;
                    this.showIDCard34 = false;
                    this.showIDCard35 = false;
                    this.showIDCard36 = false;
                    this.showIDCard37 = false;

                    this.showIDCard41 = true;
                    this.showIDCard42 = false;
                    this.showIDCard43 = false;
                    this.showIDCard44 = false;
                    this.showIDCard45 = false;
                    this.showIDCard46 = false;
                    this.showIDCard47 = false;

                    this.showIDCard51 = true;
                    this.showIDCard52 = false;
                    this.showIDCard53 = false;
                    this.showIDCard54 = false;
                    this.showIDCard55 = false;
                    this.showIDCard56 = false;
                    this.showIDCard57 = false;
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobile, this.policyCode, "Perubahan Penerimaan Manfaat");
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Change Beneficiary', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.auditTrail.saveLog('Change Beneficiary', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.cpSweetalertService.show('Update Beneficiary Error', 'error');
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });

            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

}
