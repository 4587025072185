export class Ipay88 {
  public MerchantCode: string;
  public PaymentId: number;
  public RefNo: string;
  public Amount: number;
  public Currency: string;
  public ProdDesc: string;
  public UserName: string;
  public UserEmail: string;
  public UserContact: string;
  public Remark: string;
  public Lang: string;
  public Signature: string;
  public ResponseURL: string;
  public BackendURL: string;
  public Ipay88Url: string;
  public xfield1: string;
}
