import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { CacheService } from '../../services/cache.service';
import { UserIdleService } from 'angular-user-idle';
import { map } from 'rxjs/operators';
import { LocalService } from '../../local.service';

@Component({
  selector: 'app-chat-us',
  templateUrl: './chat-us.component.html',
  styleUrls: ['./chat-us.component.scss']
})
export class ChatUsComponent implements OnInit {
  public whatsappNo :any = '';

  constructor(private cnf: Config,
    private http: HttpClient,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.cacheService.get(this.cnf.lang + '/contact', this.loadData()).subscribe((res: any) => {
      if (res.status == 100) {
        let data = JSON.parse(this.cnf.decryptData(res.datas));
        this.whatsappNo = data.whatsapp.replace(/ /g, '');
      }
    });
  }

  loadData() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);
    params = params.append('lang', this.cnf.lang);

    let paramsObject = this.cnf.convertPostData(params);

    return this.http.post(this.cnf.URLWS + '/contact/frontend/all', paramsObject)
      .pipe(map((response: Response) => response));
  }

}
