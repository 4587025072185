export class UserActivation {
    activationCode: string;
    userId: string;
    email: string;
    password: string;
    retypePassword: string;
    secretQuestions: string;
    secretAnswer: string;
    otpCode: string;
    captcha: string;
    tickTerms: string;
    type: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
