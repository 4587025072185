import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { ClaimService } from '../../services/claim.service';
import { Location } from '@angular/common';
import { APIResponse } from '../../models/apiresponse';
import { LocalService } from '../../local.service';
import { RESTResponse } from '../../models/restresponse';
import { IncompleteDocument } from '../../models/incomplete-document.model';

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.scss'],
  providers: [Config, AuditTrail, ClaimService],
  encapsulation: ViewEncapsulation.None
})
export class HistoryDetailComponent implements OnInit {
  params: HttpParams = new HttpParams();
  incDoc: IncompleteDocument[] = [];
  claimId: Params;
  public historyDetailData: any = [];
  public loadingData: boolean = false;
  public userId: any = '';

  constructor(
    private route: ActivatedRoute,
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private router: Router,
    private http: HttpClient,
    private cacheService: CacheService,
    private claim: ClaimService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private localService: LocalService
  ) {
    this.route.params.subscribe(params => {
      this.claimId = params['claimId'];
    });
  }

  ngOnInit() {
    this.params = this.params.set('appId', 'test');
    this.params = this.params.set('appKey', 'on');
    this.params = this.params.set('token', this.cnf.token);

    this.userId = this.localService.getJsonValue('userid');
    this.claim.loadNewDetail(this.claimId, null, this.params).subscribe(
      (res: any) => {
        const response: APIResponse<any> = res.body;
        if (response.status === 100) {
          for (let i in response.datas[0].claim_detail) {
            if (response.datas[0].claim_detail[i].claimId) {
              this.historyDetailData = response.datas[0].claim_detail[i];
              continue;
            }
          }

          this.loadingData = true;
        } else {
          this.router.navigate(['/404']);
        }
      },
      err => {
        console.log(err);
      }
    );
    this.auditTrail.saveLog('Claim History', 'History Detail Policy No#' + this.historyDetailData.policyNo + " Claim Number#" + this.historyDetailData.claimNumber);
    this.titleService.setTitle(this.cnf.prefixTitle + 'History Detail #' + this.claimId + this.cnf.postfixTitle);


    // load hospitalList
    this.claim.getIncDoc(this.claimId).subscribe(
      (res: any) => {
        const response: RESTResponse<IncompleteDocument[]> = res.body;
        if (response.success) {
          this.incDoc = response.data;
        } else {
          console.log(response.message);
        }
      },
      err => {
        console.log(err);
      }
    );

  }


  backClicked() {
    this._location.back();
  }
}
