import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

@Component({
  selector: 'app-cp-sweetalert',
  templateUrl: './cp-sweetalert.component.html',
  styleUrls: ['./cp-sweetalert.component.scss']
})
export class CpSweetalertComponent implements OnInit {
  // alert component with sweetalert
  @ViewChild('swal', { static: false }) private swal: SwalComponent;
  type: string = '';
  word: string = '';
  translate: boolean = true;
  constructor(private cpSweetalertService: CpSweetalertService) {
    this.cpSweetalertService.sweetalertEvent.subscribe(e => {
      if (e) {
        if (e.status === 'show') {
          this.word = e.word;
          this.type = e.type;
          this.translate = e.translate;
          setTimeout(() => {
            this.swal.fire();
          });
        }
      }
    });
  }

  ngOnInit() { }
}
