import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpParams } from '@angular/common/http';
import { APIService } from './api.service';

@Injectable()
export class AboutService {
  constructor(private cnf: Config, private api: APIService) {}

  post(params: HttpParams, url: string) {
    // url = this.cnf.URLWS + '/about/post' + url;
    const paramsObject = this.cnf.convertPostData(params);
    return this.api.post(url, paramsObject);
  }
}
