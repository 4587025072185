import { NgbDateFRParserFormatter } from './../../ngb-date-fr-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SelfRegistration } from '../../models/self-registration.model';
import { CacheService } from '../../services/cache.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [Config, AuditTrail, { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class RegistrationComponent implements OnInit {

  @ViewChild('successRegistrationSwal', {static: false}) private successRegistrationSwal: SwalComponent;
  @ViewChild('errorRegistrationSwal', {static: false}) private errorRegistrationSwal: SwalComponent;

  errorMessage: string;
  params: any;
  selfRegistration: SelfRegistration;
  loadingButton: boolean = false;
  handphone: any = '';
  policyNo: any = '';
  modelStart;
  birthDay: any = '';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public googleRecaptchaKey: any = '';
  termOfUseLink: any = '';
  otpResponse: any = {};
  public swalData: any = {};
  phonePattern: any = '[0-9]+';


  registration: string = '';
  returnUrl: string = '';

  maxDate: number;
  maxMonth: number;
  maxYear: number;
  successMessage: string;
  responseEmail: any;

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private route: ActivatedRoute,
    private http: HttpClient,
    private cacheService: CacheService,
    private router: Router,
    private translate: TranslateService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.route.params.subscribe(params => {
      this.params = params;
      this.registration = params['registration'];
    });
  }

  ngOnInit() {
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    let date = new Date();
    this.maxDate = date.getDate();
    this.maxMonth = date.getMonth() + 1;
    this.maxYear = date.getFullYear();

    this.initModel();
  }

  initModel() {
    this.selfRegistration = new SelfRegistration({
      policyNo: "",
      dateOfBirth: "",
      email: "",
      phone: "",
    });
  }

  resolved(captchaResponse: string) {
    this.selfRegistration.captcha = captchaResponse;
  }

  ngAfterViewInit() {

  }

  onInternalSubmit({ value, valid }: { value: SelfRegistration, valid: boolean }) {
    this.loadingButton = true;

    if (!this.selfRegistration.email || !this.selfRegistration.phone || !this.selfRegistration.policyNo ||
      this.modelStart) {
      let oops = this.translate.instant('_oops');
      this.swalData = {
        "title": oops,
        "message": 'Input form masih ada yang kosong',
        "type": "error"
      };
      this.loadingButton = false;
    }
  }

  submitRegistration() {
    this.loadingButton = true;
    this.birthDay = this.modelStart.year + '-' + ('0' + this.modelStart.month).slice(-2) + '-' + ('0' + this.modelStart.day).slice(-2);

    this.cnf.showRecaptchaBadge();
    this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'contact', (captchaToken) => {
      this.selfRegistration.captcha = captchaToken; 
      
      let params = new HttpParams();
      params = params.append('appId', this.cnf.appid);
      params = params.append('appKey', this.cnf.appkey);
      params = params.append('captcha', this.selfRegistration.captcha);
      let data = {
        'policyNo': this.selfRegistration.policyNo,
        'mobile': this.selfRegistration.phone.toString(),
        'email': this.selfRegistration.email,
        'birthDay': this.birthDay
      };
      let paramsObject = this.cnf.convertPostData(params, data);

      this.http.post(this.cnf.URLWSNonPublicArea + '/user_frontend/registration', paramsObject).subscribe((res: any) => {
        let message = this.translate.instant(res.message);
        if (res.status == 100) {
          this.responseEmail = res.datas.email;
          let message = 'Kami telah mengirimkan link aktivasi ke alamat email Anda ' + this.responseEmail + '.';
          this.showSuccessMessage(message);
          this.auditTrail.saveLog("Self Registration", "Save");
        } else {
          this.showErrorMessage(message);
          this.loadingButton = false;
        }
      });
    });
  }

  doGoHome() {
    this.router.navigate(['/']);
  }

  showSuccessMessage(message: string) {
    this.successMessage = message;
    setTimeout(() => {
      this.successRegistrationSwal.fire();
    }, 100);
  }

  showErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorRegistrationSwal.fire();
    }, 100);
  }

  ngOnDestroy(){
    this.cnf.removeRecaptchaBadge();
  }
}
