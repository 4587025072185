export class ChangePassword {
    currentPassword: string;
    newPassword: string;
    retypePassword: string;
    captcha: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
