import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Observable } from 'rxjs/Observable';

import { ClaimService } from '../../services/claim.service';
import { FileService } from '../../services/file.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

import { RESTResponse } from '../../models/restresponse';
import { Insured } from '../../models/insured.model';
import { MinorAlterationService } from '../../services/minor-alteration.service';

import { ReqModel } from '../../models/reqModel';
import { RESTRequest } from '../../models/restrequest.model';

import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, NgForm } from '@angular/forms';

import { FileProgress } from '../../models/file-progress';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';
import { CustomerService } from '../../services/customer.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-req-polis',
  templateUrl: './req-polis.component.html',
  styleUrls: ['./req-polis.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, FileService, MinorAlterationService]
})
export class ReqPolisComponent implements OnInit {
  fileClass: FileClass = new FileClass();
  fileClass2: FileClass = new FileClass();
  fileClass3: FileClass = new FileClass();
  private imageSrc: string = '';
  private imageSrc2: string = '';
  private imageSrc3: string = '';
  public data: any = {};
  public userId: any = '';
  errorMessage: string;
  params: any;
  loadingButton: boolean = false;

  public googleRecaptchaKey: any = '';
  public swalData: any = {};
  isChecked = false;

  public show: boolean = false;
  public show2: boolean = false;

  reqRequest: ReqModel = new ReqModel();
  reqData: ReqModel = new ReqModel();
  @ViewChild('reqPoliForm', { static: false }) reqPoliForm: NgForm;

  policies: any[] = [];
  filteredNames: Observable<Insured[]>;
  dispatchTypes: Array<{ value: string; label: string }> = [
    { value: '1', label: 'BUKU POLIS' },
    { value: '2', label: 'KARTU KESEHATAN' }
  ];
  reasons: Array<{ value: string; label: string }> = [
    { value: '1', label: 'BUKU POLIS HILANG' },
    { value: '2', label: 'BUKU POLIS RUSAK' }
  ];
  addresses: Array<{ value: string; label: string }> = [
    { value: '1', label: 'SESUAI ALAMAT KORESPONDENSI YANG TERDAFTAR PADA SYSTEM' },
    { value: '2', label: 'LAINNYA' }
  ];

  formStatus: {
    email: boolean;
    dispatchType: boolean;
    address: boolean;
    reason: boolean;
  } = {
      email: false,
      dispatchType: true,
      address: true,
      reason: true
    };

  disclaimer = new FormControl(false);

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  formGroup: FormGroup;
  successMessage: string;
  responseEmail: any;
  target: any;
  target2: any;
  target3: any;
  date: any;
  date2: any;
  date3: any;
  ext: any;
  ext2: any;
  ext3: any;
  dateFormat: any;
  newFileName: any;
  newFileName2: any;
  newFileName3: any;

  identityCardFileName: string = null;
  identityCardFileName2: string = null;
  identityCardFileName3: string = null;

  loadingReq = true;

  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress2: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress3: FileProgress = { loading: false, progress: 0 };
  
  feeReprint:any;

  constructor(
    private cnf: Config,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private router: Router,
    private claim: ClaimService,
    private formBuilder: FormBuilder,
    private file: FileService,
    private translate: TranslateService,
    private minorAlterationService: MinorAlterationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cpSweetalertService: CpSweetalertService,
    private localService: LocalService,
    private notif: NotifWaEmailSms,
    private customerService: CustomerService
  ) {
    // disable address form group at init form
    // build form
    this.buildForm();
  }

  ngOnInit() {
    this.auditTrail.saveLog('Reprint Policy Book', 'Open');
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Request Reprint Polis' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');

    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        this.loadingReq = false;
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          const dispatchType =
            this.reqData.dispatchType === 'BUKU KESEHATAN'
              ? 'BUKU KESEHATAN'
              : this.reqData.dispatchType === 'KARTU POLIS'
                ? 'KARTU POLIS'
                : null;
          this.reqData.dispatchType = dispatchType;
          this.setValueForm(this.reqData);
        }
      },
      error => {
        this.loadingReq = false;
        console.log('error', error);
      },
      () => {
        this.loadingReq = false;
      }
    );
  }

  /**
   * @desc Set formGroup value
   * @param data MinorAlterationModel
   */
  setValueForm(data: ReqModel) {
    // Patch formData
    this.formGroup.patchValue({
      policyHolderName: data.policyHolderName,
      dispatchType: data.dispatchType
    });

    // add policyNumber to policies
    if (data.policyNumbers.length > 0) {
      for (const policyNumber of data.policyNumbers) {
        const newPolicy = {
          value: policyNumber.policyCode,
          label: policyNumber.policyCode
        };
        this.policies.push(newPolicy);
      }
    }

  }

  /**
   * @desc Build formGroup
   */
  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyNumber: new FormControl(null, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      email: new FormControl({ value: null, disabled: true }),
      dispatchType: new FormControl({ value: null, disabled: false }),
      address: new FormControl(null, Validators.required),
      reason: new FormControl(null, Validators.required),
      identityCardPhoto: new FormControl(null, [Validators.required]),
      identityCardPhoto2: new FormControl(null, [Validators.required]),
      identityCardPhoto3: new FormControl({ value: null, disabled: false }),
      address2: new FormControl({ value: null, disabled: false }),
      disclaimer: this.disclaimer
    });
  }


  /**
   * @desc for validate form
   * @param formGroup
   * @param validator
   */
  validateAllFormGroupField(formGroup: FormGroup, validator: ValidatorFn | ValidatorFn[] = null) {
    for (const field in formGroup.controls) {
      const control = formGroup.controls[field];
      if (control instanceof FormControl) {
        (formGroup.controls[field] as FormControl).setValidators(validator);
      }
    }
  }

  /**
   * @desc Roolback data
   * @param type {address, homeTel, mobile, officeTel, email, dispatchType}
   */
  rollbackFormData(type: string) {
    const data = this.reqData;
    if (type === 'dispatchType') {
      (this.formGroup.controls.dispatchType as FormControl).setValue(data.dispatchType);
    }
  }

  /**
   * @desc on Policy Change
   */
  policyChange(event: any) {
    this.claim.getInsuredCustomers(event.value).subscribe((resp: any) => {
      const responses: RESTResponse<Insured[]> = resp.body;
      if (responses.success) {
      }
    });
  }

  /**
   * @desc on disclaimer checbox change
   */
  changeCheck(event: any) {
    // reset form Error Counter
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;

    this.errorAllCounter(this.formGroup);
    console.log(this.formCountError);

    const count = this.formCountError.count;
    console.log(count);
    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  /**
   * @desc Check all error form input
   */
  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  /**
   * @desc create custom random char for file-name
   */
  makecustom(length: any) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  /**
   * @desc on KTP selected function
   */
  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }


  }

  onIdentityCardFileSelect3(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target3 = event.target.files[0];
    const ext3 = target3.type.split('/').pop();

    if (target3.size < 10485760 && (ext3 == "jpeg" || ext3 == "pdf" || ext3 == "png" || ext3 == "xbm" || ext3 == "tif" || ext3 == "pjp" || ext3 == "webp" || ext3 == "jpg")) {
      this.target3 = event.target.files[0];
      this.date3 = new Date();
      this.ext3 = this.target3.type.split('/').pop();
      this.dateFormat =
        this.date3.getFullYear() +
        '' +
        (this.date3.getMonth() + 1) +
        '' +
        this.date3.getDate() +
        '' +
        this.date3.getHours() +
        '' +
        this.date3.getMinutes();
      this.newFileName3 = this.makecustom(8) + this.dateFormat + '.' + this.ext3;

      reader.onload = this._handleReaderLoaded3.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onIdentityCardFileSelect2(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target2 = event.target.files[0];
    const ext2 = target2.type.split('/').pop();

    if (target2.size < 10485760 && (ext2 == "jpeg" || ext2 == "pdf" || ext2 == "png" || ext2 == "xbm" || ext2 == "tif" || ext2 == "pjp" || ext2 == "webp" || ext2 == "jpg")) {
      this.target2 = event.target.files[0];
      this.date2 = new Date();
      this.ext2 = this.target2.type.split('/').pop();
      this.dateFormat =
        this.date2.getFullYear() +
        '' +
        (this.date2.getMonth() + 1) +
        '' +
        this.date2.getDate() +
        '' +
        this.date2.getHours() +
        '' +
        this.date2.getMinutes();
      this.newFileName2 = this.makecustom(8) + this.dateFormat + '.' + this.ext2;

      reader.onload = this._handleReaderLoaded2.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.identityCardPhoto = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  _handleReaderLoaded2(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc2 = reader2.result;
    this.fileClass2.fileDownloadUri = this.imageSrc2;
    this.fileClass2.fileName = this.newFileName2;
    this.fileClass2.fileType = this.ext2;
    this.fileClass2.size = this.target2.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass2).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto2 = response.data.fileDownloadUri;
          this.reqRequest.receipt = identityCardPhoto2;
          this.identityCardFileName2 = this.newFileName2;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto2').setValue(null);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      },
      () => {
        this.identityCardFileProgress2 = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto2').enable();
      }
    );
  }

  _handleReaderLoaded3(e: any, event: any) {
    let reader3 = event.target;
    this.imageSrc3 = reader3.result;
    this.fileClass3.fileDownloadUri = this.imageSrc3;
    this.fileClass3.fileName = this.newFileName3;
    this.fileClass3.fileType = this.ext3;
    this.fileClass3.size = this.target3.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass3).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto3 = response.data.fileDownloadUri;
          this.reqRequest.lostCertificate = identityCardPhoto3;
          this.identityCardFileName3 = this.newFileName3;

          this.cpSweetalertService.show('Upload File Berhasil.', 'success', false);
        }
      }
      , err => {
        console.log(err);
        this.formGroup.get('identityCardPhoto3').setValue(null);
        this.cpSweetalertService.show('Surat Keterangan Hilang', 'error');
      },
      () => {
        this.identityCardFileProgress3 = { loading: false, progress: 0 };
        this.formGroup.get('identityCardPhoto3').enable();
      }
    );
  }

  /**
   * @desc on submit 
   */
  onSubmit() {
    if (this.isChecked) {
      this.cnf.showRecaptchaBadge();
      this.loadingButton = true;
      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqpolis', captchaToken => {
        const formValue = this.formGroup.value;

        this.reqRequest.dispatchType = '1';
        this.reqRequest.address = formValue.address;
        this.reqRequest.address2 = formValue.address2;
        this.reqRequest.reason = formValue.reason;
        this.reqRequest.policyCode = formValue.policyNumber;
        this.reqRequest.policyHolderName = this.reqData.policyHolderName;
        this.reqRequest.subject = environment.production ? 'New Request Reprint Polis Book' : '[UAT] - New Request Reprint Polis Book';

        const req = new RESTRequest<ReqModel>();
        req.appid = this.cnf.appid;
        req.appkey = this.cnf.appkey;
        req.data = this.reqRequest;
        req.apptype = 'web';
        req.token = captchaToken;
        this.minorAlterationService
          .submitReqMed(req, this.userId)
          .subscribe(
            (res: any) => {
              const response = res.body;
              if (response.success) {
                this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                this.resetForm();
                this.auditTrail.saveLog('Reprint Policy Book', 'Submit #' + formValue.policyNumber);
                this.notif.sendNotification(this.reqData.email, this.reqData.mobile, formValue.policyNumber, "cetak ulang buku polis");
              }
            },
            err => {
              if (err.status === 400) {
                const msg = err.error;
                const message = this.translate.instant(msg.message);
                this.cpSweetalertService.show(message, 'error');
              } else {
                console.error(err);
                this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
              }
            }
          )
          .add(() => {
            this.loadingButton = false;
          });
      });
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  /**
   * @desc fire validate form
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * @desc get Option
   */
  getOptions(option) {
    if (option != null) {
      return option.fullName;
    }
    return '';
  }

  toggle() {
    const formValue = this.formGroup.value;
    // CHANGE THE NAME OF THE BUTTON.
    if (formValue.address == "2") {
      this.show = true;
      this.formGroup.controls['address2'].setValidators(Validators.required);
    }else {
      this.show = false;
      this.formGroup.controls['address2'].setValidators(null);
    }
    this.formGroup.controls['address2'].updateValueAndValidity();
  }
  toggle2() {
    const formValue = this.formGroup.value;
    // CHANGE THE NAME OF THE BUTTON.
    if (formValue.reason == "2") {
      this.show2 = false;
      this.formGroup.controls['identityCardPhoto3'].setValidators(null);
      this.cpSweetalertService.show('Harap Mengembalikan Buku Polis yang Rusak ke Kantor Pusat Chubb Life / Kantor Pemasaran Chubb Life terdekat', 'warning');
    }else {
      this.show2 = true;
      this.formGroup.controls['identityCardPhoto3'].setValidators(Validators.required);
      this.cpSweetalertService.show('Lampirkan Surat Keterangan Hilang dari Kantor Kepolisian Indonesia', 'warning');
    }
    this.formGroup.controls['identityCardPhoto3'].updateValueAndValidity();
  }

  /**
   * @desc Reset Form
   */
  resetForm() {
    this.reqPoliForm.resetForm();
    this.policies.length = 0;
    this.setValueForm(this.reqData);
    this.reqRequest = new ReqModel();
  }
  doGoHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }

  setFee(data:any){
    this.loadingReq = true;
    this.customerService.getFeeReprint(data.value, "polis").subscribe((res:any)=>{
      this.feeReprint = res.body.data;
    }).add(()=>{
      this.loadingReq = false;
    });
  }
}
