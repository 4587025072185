import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { Location } from '@angular/common';
import { LocalService } from '../../local.service';
import { APIService } from '../../services/api.service';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';

@Component({
  selector: 'app-fund-fact-sheet',
  templateUrl: './fund-fact-sheet.component.html',
  styleUrls: ['./fund-fact-sheet.component.scss'],
  providers: [Config, AuditTrail]
})

export class FundFactsheetComponent implements OnInit {
  page = 4;
  params: any;
  fundNameData: any;
  public loadingData: boolean = false;
  public userId: any = '';
  attoken: string = '';
  projectName: string = 'fund';

  constructor(
    private route: ActivatedRoute,
    private cnf: Config,
    private localService: LocalService,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private apiService: APIService,
    private cpSweetalertService: CpSweetalertService,
    private _location: Location) {
    this.route.params.subscribe(params => this.params = params);
  }

  decryptData(encryptData: any) {
    let decodeString = atob(encryptData);

    let key = CryptoJS.enc.Utf8.parse(this.cnf.yek.enc);
    let decrypted = CryptoJS.AES.decrypt(decodeString, key,
      {
        keySize: 128 / 8,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

    let decryptData = JSON.parse(decrypted);

    return decryptData;
  }

  ngOnInit() {
    this.auditTrail.saveLog("Fund Fund & Fact Sheet", "Open");
    this.titleService.setTitle(this.cnf.prefixTitle + "Fund Fact Sheet" + this.cnf.postfixTitle);

    this.userId = this.localService.getJsonValue('userid');
    this.cnf.getAttoken().subscribe((res: any) => {
      if (res.status == 100) {
        this.attoken = this.cnf.generateAttoken(this.projectName, res.datas);
      }

      this.loadFundName().subscribe((res: any) => {
        if (res.body.status == 100) {
          this.fundNameData = res.body.datas;
        }
      }).add(() => {
        this.loadingData = true;
      });
    });
  }

  loadFundName() {
    // let params = new HttpParams();
    // params = params.append('appId', 'test');
    // params = params.append('appKey', 'on');

    // params = params.append('token', this.cnf.token);
    // params = params.append('attoken', this.attoken);

    // let paramsObject = this.cnf.convertPostData(params);
    return this.apiService.post(this.cnf.URLWSNonPublicArea + '/fund/fund-name/');
  }

  doDownloadFile(url: any) {
    if (url != null) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();
      link.remove();
    } else {
      this.cpSweetalertService.show('Surat Fund Tidak ditemukan', 'error');
    }
  }

  backClicked() {
    this._location.back();
  }

}
