import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { AuditTrail } from '../../audit-trail';
import { Config } from '../../config';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute } from '@angular/router';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { RESTResponse } from '../../models/restresponse';
import { ReqModel } from '../../models/reqModel';
import { LocalService } from '../../local.service';
import { FileClass } from '../../models/fileClass';
import { FileService } from '../../services/file.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UserService } from '../../services/user.service';
import { RESTRequest } from '../../models/restrequest.model';
import { ChangeRequest } from '../../models/ChangeRequest';
import { FundCustomer } from '../../models/FundCustomer';
import { NotifWaEmailSms } from '../../services/NotifWaEmailSms.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-switching',
  templateUrl: './switching.component.html',
  styleUrls: ['./switching.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, MinorAlterationService, FileService, UserService]
})
export class SwitchingComponent implements OnInit {

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;
  formGroup: FormGroup;
  loading: boolean = false;
  loadingPolicy: boolean = false;
  loadingButton: boolean = false;
  policies: any = [];
  freqPaymentList: any = [];
  googleRecaptchaKey: any = '';
  userId: any = '';
  params: any;
  policyDetailData: any = {};
  reqData: ReqModel = new ReqModel();
  identityCardFileName: string = null;

  fileClass: FileClass = new FileClass();
  private imageSrc: string = '';
  target: any;
  date: any;
  ext: any;
  dateFormat: any;
  newFileName: any;

  disclaimer = new FormControl(false);
  isChecked = false;
  monthBefore: number = 0;
  loadingData: boolean = false;

  formCountError: { count: number; form: string[] } = {
    count: 0,
    form: []
  };

  note: any;

  otpPass: boolean = false;
  otpFail: boolean = false;
  process: boolean = false;
  otpNull: boolean = false;
  startOTP: number = 0;
  endOTP: number = 3;
  maxOtp: boolean = false;
  timeEnd: boolean = false;
  displayOTP: boolean = false;

  kodeOTP: any;
  capthaTemp: any;
  policyCode: any;
  perOTP: any;
  message = 'Kode OTP berhasil diverifikasi!';
  responseHP: any;
  activationCountdown: any;
  activationTime = 5;

  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, ignoreBackdropClick: true };
  @ViewChild('myOpen', { static: false }) myOpen: ElementRef<HTMLElement>;
  @ViewChild('closeDialog', { static: false }) closeDialog: ElementRef<HTMLElement>;
  @ViewChild('myOTP', { static: false }) myOTP: ElementRef<HTMLElement>;

  reqRequest: ChangeRequest = new ChangeRequest();

  fundList: any = [];
  fundListTemp: any = [];
  fundListChoice: any = [];
  fundCustomerList: any = [];
  limitFundCode: any = [{ 'code': 'AER3' }, { 'code': 'AMR' }, { 'code': 'AMMR' }, { 'code': 'AFR' }, { 'code': 'ASER1' }];

  constructor(
    private formBuilder: FormBuilder,
    private minorAlterationService: MinorAlterationService,
    private translate: TranslateService,
    private auditTrail: AuditTrail,
    private titleService: Title,
    private cpSweetalertService: CpSweetalertService,
    private customerService: CustomerService,
    private localService: LocalService,
    private route: ActivatedRoute,
    private cnf: Config,
    private file: FileService,
    private user: UserService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private notif: NotifWaEmailSms

  ) {
    this.route.params.subscribe(params => (this.params = params));
    this.buildForm()
  }

  ngOnInit() {
    this.loadingData = false;
    this.auditTrail.saveLog('Switching', 'Open');
    this.loadingPolicy = true;
    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Switching' + this.cnf.postfixTitle);
    this.userId = this.localService.getJsonValue('userid');
    this.minorAlterationService.getMinorAlterationData(this.userId).subscribe(
      (res: any) => {
        const response: RESTResponse<ReqModel> = res.body;
        if (response.success) {
          this.reqData = { ...response.data };
          if (this.reqData.policyNumbers.length > 0) {
            for (const policyNumber of this.reqData.policyNumbers) {
              const newPolicy = {
                value: policyNumber.policyCode,
                label: policyNumber.policyCode
              };
              this.policies.push(newPolicy);
            }
            this.formGroup.controls.policyNo.setValue(this.params.policyNo);
            this.detailPersonal(this.params.policyNo);
            this.loadingPolicy = false;
          }
        }
      }
    );
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      policyNo: new FormControl(null, Validators.required),
      policyHolderName: new FormControl({ value: null, disabled: true }, Validators.required),
      mainLifeAssured: new FormControl({ value: null, disabled: true }, Validators.required),
      identityCardPhoto: new FormControl(null, Validators.required),
      fundCustomerBefore0: new FormControl({ value: null, disabled: true }),
      fundCustomerBefore1: new FormControl({ value: null, disabled: true }),
      fundCustomerBefore2: new FormControl({ value: null, disabled: true }),
      fundCustomerBefore3: new FormControl({ value: null, disabled: true }),
      fundCustomerBefore4: new FormControl({ value: null, disabled: true }),
      fundCustomerAfter0: new FormControl(null),
      fundCustomerAfter1: new FormControl(null),
      fundCustomerAfter2: new FormControl(null),
      fundCustomerAfter3: new FormControl(null),
      fundCustomerAfter4: new FormControl(null),
      sumUnit0: new FormControl({ value: null, disabled: true }),
      sumUnit1: new FormControl({ value: null, disabled: true }),
      sumUnit2: new FormControl({ value: null, disabled: true }),
      sumUnit3: new FormControl({ value: null, disabled: true }),
      sumUnit4: new FormControl({ value: null, disabled: true }),
      alokasi0: new FormControl(null),
      alokasi1: new FormControl(null),
      alokasi2: new FormControl(null),
      alokasi3: new FormControl(null),
      alokasi4: new FormControl(null),
      disclaimer: this.disclaimer
    });
  }

  onSubmit() {
    if (this.isChecked) {
      const formValue = this.formGroup.value;
      this.policyCode = formValue.policyNo;
      let check = false;
      let tempList: FundCustomer[] = [];
      let i = 0;
      for (var obj of this.fundCustomerList) {
        let fund: FundCustomer = new FundCustomer();
        if (i == 0) {
          if (formValue.fundCustomerAfter0 != null && formValue.alokasi0 != null) {
            if (formValue.fundCustomerAfter0 != obj.fundName) {
              fund.fundNameBefore = obj.fundName;
              fund.sumFund = obj.sumUnit;
              fund.fundNameAfter = formValue.fundCustomerAfter0.split("#")[1];
              fund.alocation = formValue.alokasi0;
              check = true;
              this.auditTrail.auditTrailBefore.fund1 = obj.fundName;
              this.auditTrail.auditTrailAfter.fund1 = formValue.fundCustomerAfter0;

              this.auditTrail.auditTrailBefore.percen1 = obj.sumUnit;
              this.auditTrail.auditTrailAfter.percen1 = fund.alocation;
            }
          }
        }
        if (i == 1) {
          if (formValue.fundCustomerAfter1 != null && formValue.alokasi1 != null) {
            if (formValue.fundCustomerAfter1 != obj.fundName) {
              fund.fundNameBefore = obj.fundName;
              fund.sumFund = obj.sumUnit;
              fund.fundNameAfter = formValue.fundCustomerAfter1.split("#")[1];
              fund.alocation = formValue.alokasi1;
              check = true;
              this.auditTrail.auditTrailBefore.fund2 = obj.fundName;
              this.auditTrail.auditTrailAfter.fund2 = formValue.fundCustomerAfter1;

              this.auditTrail.auditTrailBefore.percen2 = obj.sumUnit;
              this.auditTrail.auditTrailAfter.percen2 = fund.alocation;
            }
          }
        }
        if (i == 2) {
          if (formValue.fundCustomerAfter2 != null && formValue.alokasi2 != null) {
            if (formValue.fundCustomerAfter2 != obj.fundName) {
              fund.fundNameBefore = obj.fundName;
              fund.sumFund = obj.sumUnit;
              fund.fundNameAfter = formValue.fundCustomerAfter2.split("#")[1];
              fund.alocation = formValue.alokasi2;
              check = true;
              this.auditTrail.auditTrailBefore.fund3 = obj.fundName;
              this.auditTrail.auditTrailAfter.fund3 = formValue.fundCustomerAfter2;

              this.auditTrail.auditTrailBefore.percen3 = obj.sumUnit;
              this.auditTrail.auditTrailAfter.percen3 = fund.alocation;
            }
          }
        }
        if (i == 3) {
          if (formValue.fundCustomerAfter3 != null && formValue.alokasi3 != null) {
            if (formValue.fundCustomerAfter3 != obj.fundName) {
              fund.fundNameBefore = obj.fundName;
              fund.sumFund = obj.sumUnit;
              fund.fundNameAfter = formValue.fundCustomerAfter3.split("#")[1];
              fund.alocation = formValue.alokasi3;
              check = true;
              this.auditTrail.auditTrailBefore.fund4 = obj.fundName;
              this.auditTrail.auditTrailAfter.fund4 = formValue.fundCustomerAfter3;

              this.auditTrail.auditTrailBefore.percen4 = obj.sumUnit;
              this.auditTrail.auditTrailAfter.percen4 = fund.alocation;
            }
          }
        }
        if (i == 4) {
          if (formValue.fundCustomerAfter4 != null && formValue.alokasi4 != null) {
            if (formValue.fundCustomerAfter4 != obj.fundName) {
              fund.fundNameBefore = obj.fundName;
              fund.sumFund = obj.sumUnit;
              fund.fundNameAfter = formValue.fundCustomerAfter4.split("#")[1];
              fund.alocation = formValue.alokasi4;
              check = true;
              this.auditTrail.auditTrailBefore.fund5 = obj.fundName;
              this.auditTrail.auditTrailAfter.fund5 = formValue.fundCustomerAfter4;

              this.auditTrail.auditTrailBefore.percen5 = obj.sumUnit;
              this.auditTrail.auditTrailAfter.percen5 = fund.alocation;
            }
          }
        }
        i++;
        tempList.push(fund);
      }

      if (tempList.length > 0) {
        this.reqRequest.listFund = tempList;
      }

      if (check) {
        this.cnf.showRecaptchaBadge();
        this.loadingButton = true;
        this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'reqchangeswitching', captchaToken => {
          this.capthaTemp = captchaToken;
          this.reqRequest.policyCode = this.policyCode;
          this.reqRequest.typeRequest = "switching";
          if (environment.production) {
            this.reqRequest.subject = "New Request Switching - " + this.policyCode + " - " + this.policyDetailData.policyHolderName;
          } else {
            this.reqRequest.subject = "[UAT] - New Request Switching - " + this.policyCode + " - " + this.policyDetailData.policyHolderName;
          }
          this.reqRequest.title = "You Have Received New Request Switching from Customer Corner"
          this.reqRequest.policyHolderBefore = this.policyDetailData.policyHolderName;
          this.reqRequest.mainLifeAssured = this.policyDetailData.mainLifeAssured;

          this.user.requestOTP(this.userId, 'switching', 'sms').subscribe((res: any) => {
            this.startOTP = (+res.datas.countotp) + 1;
            this.process = false;
            this.kodeOTP = null;
            if (this.startOTP <= 3) {
              if (res.datas.handphone != null) {
                this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + this.activationTime);
                this.responseHP = res.datas.handphone;
                this.perOTP = this.startOTP + '/' + this.endOTP;
                if (this.startOTP == this.endOTP) {
                  this.maxOtp = true;
                }
                let el: HTMLElement = this.myOpen.nativeElement;
                el.click();
                let ell: HTMLElement = this.myOTP.nativeElement;
                ell.focus();
              }
            } else {
              this.message = res.datas.msg;
              this.displayOTP = true;
              this.otpFail = true;
              let el: HTMLElement = this.myOpen.nativeElement;
              el.click();
            }
          }, err => {
            console.log(err.message);
          });
        });
      } else {
        this.cpSweetalertService.show('Silahkan lakukan perubahan jenis investasi dana', 'warning');
      }
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  choiceFund(data: any, before: any, index: any) {
    if (data != null) {
      var name = data.value.split("#")[1];
      var curr = data.value.split("#")[0];
      if (name == before.fundName) {
        this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi yang sama. Silahkan memilih Jenis Dana Investasi yang lain.', 'warning');
        this.resetFormFund(index);
      } else {
        var cek = false;
        for (var i = 0; i < 5; i++) {
          if (i != index) {
            var cundCus = this.formGroup.controls['fundCustomerAfter' + i].value;
            var fundCus = "";
            if (cundCus != null) {
              fundCus = cundCus.split("#")[1];
            }

            if (fundCus == name) {
              cek = true;
            }
          }
        }
        if (cek) {
          this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi yang sama. Silahkan memilih Jenis Dana Investasi yang lain.', 'warning');
          this.resetFormFund(index);
        } else {
          var syariahCurrent = (before.fundName.toLowerCase()).includes("syariah");
          var syariahChoice = (name.toLowerCase()).includes("syariah");
          if (syariahCurrent) {
            if (syariahChoice) {
              if (curr != before.currency) {
                this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi currency ' + curr + '. Silahkan memilih Jenis Dana Investasi dengan currency ' + before.currency, 'warning');
                this.resetFormFund(index);
              }
            } else {
              this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi Selain Syariah. Silahkan memilih Jenis Dana Investasi yang Syariah.', 'warning');
              this.resetFormFund(index);
            }
          } else {
            if (!syariahChoice) {
              if (curr != before.currency) {
                this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi currency ' + curr + '. Silahkan memilih Jenis Dana Investasi dengan currency ' + before.currency, 'warning');
                this.resetFormFund(index);
              }
            } else {
              this.cpSweetalertService.show('Anda memilih Jenis Dana Investasi Selain Konvensional. Silahkan memilih Jenis Dana Investasi yang Konvensional.', 'warning');
              this.resetFormFund(index);
            }
          }
        }
      }
    }
  }

  resetFormFund(index: any) {
    if (index == 0) {
      this.formGroup.controls.fundCustomerAfter0.setValue(null);
    }
    if (index == 1) {
      this.formGroup.controls.fundCustomerAfter1.setValue(null);
    }
    if (index == 2) {
      this.formGroup.controls.fundCustomerAfter2.setValue(null);
    }
    if (index == 3) {
      this.formGroup.controls.fundCustomerAfter3.setValue(null);
    }
    if (index == 4) {
      this.formGroup.controls.fundCustomerAfter4.setValue(null);
    }
  }

  choicePolicy(data: any) {
    this.detailPersonal(data.value);
  }

  detailPersonal(policyNo: any) {
    this.customerService.getPolicyInformation(policyNo, this.cnf.token).subscribe((res: any) => {
      if (res.body.status == 100) {
        let decryptData = JSON.parse(this.cnf.decryptData(res.body.datas));
        this.policyDetailData = decryptData[0].policyInformation_detail[0];
        this.formGroup.controls.policyHolderName.setValue(this.policyDetailData.policyHolderName);
        this.formGroup.controls.mainLifeAssured.setValue(this.policyDetailData.mainLifeAssured);
        this.getFund();
        this.getFundCustomer(policyNo);
      }
    });
  }

  getFund() {
    this.fundListTemp = [];
    this.fundList = [];
    this.fundListChoice = [];
    this.customerService.getFund().subscribe((res: any) => {
      if (res.status == 200) {
        this.fundListTemp = res.body.data;
        this.fundList = this.fundListTemp;
        for (var list of this.fundListTemp) {
          for (var con of this.limitFundCode) {
            if (list.fundCode == con.code) {
              this.fundListChoice.push(list);
            }
          }
        }
      }
    });
  }

  getFundCustomer(policyNo: any) {
    this.customerService.getCustomerFund(policyNo).subscribe((res: any) => {
      if (res.status == 200) {
        this.fundCustomerList = res.body.data;
      }
      this.loadingData = true;
      let i = 0;
      setTimeout(() => {
        for (var obj of this.fundCustomerList) {
          if (i == 0) {
            this.formGroup.controls.fundCustomerBefore0.setValue(obj.fundName);
          }
          if (i == 1) {
            this.formGroup.controls.fundCustomerBefore1.setValue(obj.fundName);
          }
          if (i == 2) {
            this.formGroup.controls.fundCustomerBefore2.setValue(obj.fundName);
          }
          if (i == 3) {
            this.formGroup.controls.fundCustomerBefore3.setValue(obj.fundName);
          }
          if (i == 4) {
            this.formGroup.controls.fundCustomerBefore4.setValue(obj.fundName);
          }
          i++;
        }
      }, 500);
    });
  }

  onIdentityCardFileSelect(event: any) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();
    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file);
    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  _handleReaderLoaded(e: any, event: any) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;
    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.ext;
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          const identityCardPhoto = response.data.fileDownloadUri;
          this.reqRequest.idCard = identityCardPhoto;
          this.identityCardFileName = this.newFileName;

          this.cpSweetalertService.show('Unggah Dokumen Berhasil', 'success', false);
        }
      }
      , (err: any) => {
        console.log(err);
        this.formGroup.get('identityCardPhoto').setValue(null);
        this.cpSweetalertService.show('Gagal Upload Dokumen', 'error');
      },
      () => {
        this.formGroup.get('identityCardPhoto').enable();
      }
    );
  }

  makecustom(length: any) {
    var a = new Date();
    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);
    return id;
  }

  resetForm() {
    this.ngForm.reset();
    this.note = '';
  }

  changeCheck(event: any) {
    this.formCountError.count = 0;
    this.formCountError.form.length = 0;
    this.errorAllCounter(this.formGroup);
    const count = this.formCountError.count;
    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      if (this.formCountError.count === 1 && this.formCountError.form[0] === 'identityCardPhoto') {
        this.cpSweetalertService.show('_minor_identity_card_scan_warning', 'warning');
      } else {
        this.cpSweetalertService.show('Permintaan tidak dapat diproses, mohon lengkapi formulir dan mengunggah dokumen', 'warning');
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorAllCounter(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(_ => {
            this.formCountError.count++;
            this.formCountError.form.push(key);
          });
        }
      } else if (control instanceof FormGroup) {
        this.errorAllCounter(control);
      }
    });
    return this.formCountError;
  }

  activationTimeout() {
    this.timeEnd = true;
  }

  sendAgin() {
    this.kodeOTP = null;
    this.process = true;
    this.user.requestOTP(this.userId, 'switching', 'sms').subscribe((res: any) => {
      this.startOTP = (+res.datas.countotp) + 1;
      if (this.startOTP <= 3) {
        this.timeEnd = false;
        this.process = false;
        this.otpPass = true;
        this.message = 'Kode OTP berhasil dikirim ulang!';
        this.responseHP = res.datas.handphone;
        this.perOTP = this.startOTP + '/' + this.endOTP;
        if (this.startOTP == this.endOTP) {
          this.maxOtp = true;
        }
        this.activationCountdown = new Date().setMinutes(new Date().getMinutes() + 3);
        setTimeout(() => {
          this.otpPass = false;
        }, 200);
        let el: HTMLElement = this.myOTP.nativeElement;
        el.focus();
      } else {
        this.message = res.datas.msg;
        this.displayOTP = true;
        this.otpFail = true;
        let el: HTMLElement = this.myOpen.nativeElement;
        el.click();
      }
    }, (err: any) => {
      console.log(err.message);
    });
  }

  submitOTP() {
    if (this.kodeOTP != null) {
      this.user.checkOTP(this.userId, 'switching', this.kodeOTP).subscribe((res: any) => {
        if (res.datas.corret == "Y") {
          this.message = 'Kode OTP berhasil diverifikasi!';
          this.otpPass = true;
          this.process = true;
          setTimeout(() => {
            let el: HTMLElement = this.closeDialog.nativeElement;
            el.click();
            const req = new RESTRequest<ChangeRequest>();
            req.appid = this.cnf.appid;
            req.appkey = this.cnf.appkey;
            req.data = this.reqRequest;
            req.apptype = 'web';
            req.token = this.capthaTemp;
            this.customerService
              .changeRequest(req)
              .subscribe(
                (res: any) => {
                  const response = res.body;
                  if (response.success) {
                    this.cpSweetalertService.show('Terima kasih, permintaan anda sudah kami terima.', 'success');
                    this.notif.sendNotification(this.policyDetailData.email, this.policyDetailData.mobile, this.policyCode, "Switching");
                    this.resetForm();
                    this.auditTrail.saveLog('Switching', 'Submit Success#' + this.policyCode);
                  }
                },
                err => {
                  if (err.status === 400) {
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Switching', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  } else {
                    console.error(err);
                    const msg = err.error;
                    const message = this.translate.instant(msg.message);
                    this.cpSweetalertService.show(message, 'error');
                    this.auditTrail.saveLog('Switching', 'Submit Failed#' + this.policyCode + ' #' + msg.message);
                  }
                }
              )
              .add(() => {
                this.loadingButton = false;
                this.process = false;
              });
            this.otpPass = false;
          }, 500);
        } else {
          this.otpFail = true;
          this.message = 'Kode OTP yang anda masukkan tidak valid';
        }
      }, (err: any) => {
        console.log(err.message);
      });
    } else {
      this.otpNull = true;
    }
  }

  closeOTP() {
    this.loadingButton = false;
    let el: HTMLElement = this.closeDialog.nativeElement;
    el.click();
  }

  getTransalte(key: any) {
    var result = "";
    this.translate.get(this.policyDetailData.paymentFrequency).subscribe((res: any) => {
      result = res;
    });
    return result;
  }

}
