export class Contact {
    name: string;
    email: string;
    category: string;
    phone: string;
    subject: string;
    message: string;
    status: string;
    captcha: string;
    location: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
