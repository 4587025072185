import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../config';
import { HttpClient, HttpParams } from "@angular/common/http";
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuditTrail } from '../../audit-trail';

@Component({
  selector: 'menu-dashboard-horizontal',
  templateUrl: './menu-dashboard-horizontal.component.html',
  styleUrls: ['./menu-dashboard-horizontal.component.scss']
})
export class MenuDashboardHorizontalComponent implements OnInit {
  isCollapsed: boolean;
  constructor(private router: Router, private cnf: Config, private http: HttpClient, private auditTrail: AuditTrail) {
    this.isCollapsed = true;
  }
  ngOnInit() {
  }

  doLogout() {
    this.auditTrail.saveLog('Home', 'Logout');
    setTimeout(() => {
      let params = new HttpParams();
      params = params.append('appId', 'test');
      params = params.append('appKey', 'on');
      params = params.append('token', this.cnf.token);
      let paramsObject = this.cnf.convertPostData(params);
      var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
      this.http.post(url, paramsObject).subscribe(res => {
        this.cnf.clearLocalStorage();
        this.router.navigate(['/']);
        location.reload();
      });
    }, 100);
  }

  addCloseClass() {
    var x = document.getElementById("burger-bottom");
    x.classList.toggle("change");
  }

}
