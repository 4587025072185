export class RequestWithdrawal {

    public policyCode: string;
    public fundType: string;
    public amount: Number;
    public currency: string;
    public bankHolder: string;
    public bankName: string;
    public bankBranch: string;
    public bankNumber: string;
    public identityCard: string;
    public accountBook: string;
    public powerOfAttorney: string;
    public dispatchType: string;
}
