import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { ClaimService } from '../../services/claim.service';
import { FileService } from '../../services/file.service';
import { MinorAlterationService } from '../../services/minor-alteration.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-document-uncomplete',
  templateUrl: './document-uncomplete.component.html',
  styleUrls: ['./document-uncomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Config, AuditTrail, ClaimService, FileService, MinorAlterationService]
})
export class DocumentUncompleteComponent implements OnInit {
  params: any = '';
  listDataDocument: any = []
  listDataDocumentOutput: any = []
  DisplayDetail: any = {};
  isShownFile1: any = false;
  isShownFile2: any = false;
  isShownFile3: any = false;
  isShownFile4: any = false;
  isShownFile5: any = false;
  isShownFile6: any = false;
  isShownFile7: any = false;
  isShownFile8: any = false;
  isShownFile9: any = false;
  isShownFile10: any = false;

  loading: boolean = false;
  constructor(private route: ActivatedRoute, private customerService: CustomerService, private http: HttpClient, private cnf: Config) {
    this.route.params.subscribe(params => (this.params = params));
  }

  ngOnInit() {
    this.loading = true;
    this.detailDokumen().subscribe((res) => {

      this.listDataDocument = res;
      this.listDataDocumentOutput = this.listDataDocument.datas;
      this.loading = false;
    })

  }


  detailDokumen() {
    return this.http.get(this.cnf.URLWS + '/DescriptionMissingDocument/GetByPolicyNumber/' + this.params.policyNo);
  }


  detailDocumentByIdDocument(idDocument) {
    return this.http.get(this.cnf.URLWS + '/DescriptionMissingDocument/GetByIdDocument?idDocument=' + idDocument);
  }


  displayStyle = "none";
  openPopup(idDocument: any) {
    this.detailDocumentByIdDocument(idDocument).subscribe((res) => {

      this.DisplayDetail = res;
      this.fileOnDisplay()
    })
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
    this.isShownFile1 = false;
    this.isShownFile2 = false;
    this.isShownFile3 = false;
    this.isShownFile4 = false;
    this.isShownFile5 = false;
    this.isShownFile6 = false;
    this.isShownFile7 = false;
    this.isShownFile8 = false;
    this.isShownFile9 = false;
    this.isShownFile10 = false;
  }



  file1Up() {
    window.open(this.DisplayDetail.datas.file1)
  }


  file2() {
    window.open(this.DisplayDetail.datas.file2)
  }


  file3() {
    window.open(this.DisplayDetail.datas.file3)
  }


  file4() {
    window.open(this.DisplayDetail.datas.file4)
  }



  file5() {
    window.open(this.DisplayDetail.datas.file5)
  }


  file6() {
    window.open(this.DisplayDetail.datas.file6)
  }


  file7() {
    window.open(this.DisplayDetail.datas.file7)
  }


  file8() {
    window.open(this.DisplayDetail.datas.file8)
  }


  file9() {
    window.open(this.DisplayDetail.datas.file9)
  }


  file10() {
    window.open(this.DisplayDetail.datas.file10)
  }



  fileOnDisplay() {

    if (this.DisplayDetail.datas.file1 != null) {
      this.isShownFile1 = true;
    }


    if (this.DisplayDetail.datas.file2 != null) {
      this.isShownFile2 = true;
    }


    if (this.DisplayDetail.datas.file3 != null) {
      this.isShownFile3 = true;
    }



    if (this.DisplayDetail.datas.file4 != null) {
      this.isShownFile4 = true;
    }


    if (this.DisplayDetail.datas.file5 != null) {
      this.isShownFile5 = true;
    }



    if (this.DisplayDetail.datas.file6 != null) {
      this.isShownFile6 = true;
    }


    if (this.DisplayDetail.datas.file7 != null) {
      this.isShownFile7 = true;
    }


    if (this.DisplayDetail.datas.file8 != null) {
      this.isShownFile8 = true;
    }


    if (this.DisplayDetail.datas.file9 != null) {
      this.isShownFile9 = true;
    }


    if (this.DisplayDetail.datas.file10 != null) {
      this.isShownFile10 = true;
    }
  }
}
