import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../services/cache.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from '../../ngb-date-fr-parser-formatter';
import { UserForgot } from '../../models/user-forgot.model';
import { RESTRequest } from '../../models/restrequest.model';
import { RESTResponse } from '../../models/restresponse';
import { CustomerService } from '../../services/customer.service';
import { ForgetPasswordRequest } from '../../models/forget-password-request.model';

import * as moment from 'moment';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  providers: [
    Config,
    AuditTrail,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    CustomerService
  ]
})
export class ForgetPasswordComponent implements OnInit {
  @ViewChild('errorForgetPassword', { static: false }) private errorForgetPassword: SwalComponent;
  @ViewChild('successForgetPassword', { static: false }) private successForgetPassword: SwalComponent;
  @ViewChild('errorSwal', { static: false }) private errorSwal: SwalComponent;

  message: string;
  secretQuestionsData: any = [];
  isQuestion: boolean;
  loadingButton: boolean = false;
  userForgot: UserForgot;
  googleRecaptchaKey: string;
  responseEmail: String = '';
  maxDate: number;
  maxMonth: number;
  maxYear: number;
  modelStart;
  forgetPasswordRequest: ForgetPasswordRequest = new ForgetPasswordRequest();

  constructor(
    private cnf: Config,
    private titleService: Title,
    private http: HttpClient,
    private translate: TranslateService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    // this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'forgetpassword', captchaToken => {
    //   console.log(captchaToken);
    // });
    let date = new Date();
    this.maxDate = date.getDate();
    this.maxMonth = date.getMonth() + 1;
    this.maxYear = date.getFullYear();

    this.googleRecaptchaKey = this.cnf.googleRecaptchaKey;
    this.titleService.setTitle(this.cnf.prefixTitle + 'Forget Password' + this.cnf.postfixTitle);

    this.initializeModel();
  }

  initializeModel() {
    this.userForgot = new UserForgot({
      email: '',
      otpCode: '',
      captcha: '',
      birthday: ''
    });
    this.modelStart = {};
  }

  onInternalSubmit(form: any) {
    if (!this.userForgot.email) {
      this.showErrorMessage('Maaf, email yang Anda masukkan masih kosong');
    } else {
      this.loadingButton = true;
      this.userForgot.birthday =
        this.modelStart.year +
        '-' +
        ('0' + this.modelStart.month).slice(-2) +
        '-' +
        ('0' + this.modelStart.day).slice(-2);

      this.cnf.showRecaptchaBadge();
      const birthdayDate = moment
        .utc(this.userForgot.birthday)
        .toDate()
        .getTime();
      this.forgetPasswordRequest.birthday = birthdayDate;
      this.forgetPasswordRequest.email = this.userForgot.email;
      this.forgetPasswordRequest.dateBirthDay = this.userForgot.birthday;

      this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'forgetpassword', captchaToken => {
        let req = new RESTRequest<ForgetPasswordRequest>();
        req.appid = this.cnf.appid;
        req.appkey = this.cnf.appkey;
        req.data = this.forgetPasswordRequest;
        req.apptype = 'web';
        req.token = captchaToken;

        this.customerService
          .submitForgotPassword(req)
          .subscribe(
            (res: any) => {
              const response: RESTResponse<ForgetPasswordRequest> = res.body;
              if (response.success) {
                this.responseEmail = response.message;
                form.resetForm();
              } else {
                const message = this.translate.instant(res.message);
                this.showErrorMessage(message);

              }
            },
            err => {
              const msg = err.error;
              if (err.status === 400) {
                const message = this.translate.instant(msg.message);
                this.showErrorMessage(message);
              } else {
                this.showErrorMessage("Maaf, kami tidak menemukan kesesuain email dan atau tanggal lahir yang Anda masukkan. Mohon periksa dan coba kembali.");
              }
            }
          )
          .add(() => {
            this.loadingButton = false;
          });
      });
    }
  }

  resolved(captchaResponse: string) {
    this.userForgot.captcha = captchaResponse;
  }

  sendEmailValidation() {
    let params = new HttpParams();
    params = params.append('appId', this.cnf.appid);
    params = params.append('appKey', this.cnf.appkey);

    let data = {
      email: this.userForgot.email,
      birthday: this.userForgot.birthday,
      captcha: this.userForgot.captcha
    };
    let paramsObject = this.cnf.convertPostData(params, data);

    return this.http
      .post(this.cnf.URLWSNonPublicArea + '/user_frontend/forgotpassword', paramsObject)
      .pipe(map((response: Response) => response));
  }

  showSuccessMessage(message: string) {
    this.message = message;
    setTimeout(() => {
      this.successForgetPassword.fire();
    }, 100);
  }

  showErrorMessage(message: string) {
    this.message = message;
    setTimeout(() => {
      this.errorForgetPassword.fire();
    }, 100);
  }

  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
  }
}
