import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor(private cnf: Config) { }
  
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {

    let config = {
        'required': 'This field is required',
        'email': 'The ' + validatorName + ' must contain a valid email address',
        'invalidPassword': 'Password must be at least 6 characters long, and contain a number.',
        'minLength': `Minimum length ${validatorValue.requiredLength}`,
        'invalidMatch': 'The password and confirm password must match'

    };

    return config[validatorName];
  }
  static submitDate(controlName: string, matchingControlName: string) {
    
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ invalidMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
  }
  static match(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ invalidMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
  }
}
