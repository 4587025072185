import { ViewEncapsulation, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Config } from '../../config';
import { AuditTrail } from './../../audit-trail';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { PolicyService } from '../../services/policy.service';
import { ClaimService } from '../../services/claim.service';
import { RESTResponse } from '../../models/restresponse';
import { Hospital } from '../../models/hospital.model';
import { FileService } from '../../services/file.service';
import { FileResponse } from '../../models/file-response';
import { ClaimRequest } from '../../models/claim-request.model';
import { RESTRequest } from '../../models/restrequest.model';
import { PolicyList } from '../../models/policy-list.model';
import { Customer } from '../../models/customer.model';
import { Insured } from '../../models/insured.model';
import * as moment from 'moment';

import { CpSweetalertService } from '../../services/cp-sweetalert.service';
import { FileProgress } from '../../models/file-progress';
import { NgForm } from '@angular/forms';
import { LocalService } from '../../local.service';
import { FileClass } from '../..//models/fileClass';


@Component({
  selector: 'app-eclaim',
  templateUrl: './eclaim.component.html',
  styleUrls: ['./eclaim.component.scss'],
  providers: [Config, AuditTrail, PolicyService, ClaimService, FileService],
  encapsulation: ViewEncapsulation.None
})
export class EclaimComponent implements OnInit {
  fileClass: FileClass = new FileClass();
  public errors: any[] = [];
  formGroup: FormGroup;

  public preview: any = '';
  public token: any = '';
  public userId: any = '';
  public customerId: any = '';
  public lang: any;
  isChecked = false;
  date: any;
  ext: any;
  fileUploadSuccessMessage: string;
  submitSuccessMessage: string;
  submitWarningMessage: string;
  disclaimerWarningMessage: string;
  notCompleteWarningMessage: string;
  maxWarningMessage: string;
  nameWarningMessage: string;
  notEarlyWarningMessage: string;
  errorMessage: string;
  warningMessage: string;
  disclaimerText: string;
  maxClaim: number = this.cnf.maxClaim;
  minClaim: number = 1;
  maxSubmit: number = this.cnf.maxSubmit;
  policyNumber: string;
  currentDate = new Date();

  insuredName = new FormControl(null, Validators.required);
  policyId = new FormControl(null, Validators.required);
  entryDate = new FormControl(null, Validators.required);
  exitDate = new FormControl(null, Validators.required);
  cost = new FormControl(null, [Validators.required, Validators.max(this.maxClaim), Validators.min(this.minClaim)]);
  hospitalId = new FormControl(null, Validators.required);
  disclaimerBox = new FormControl(null, Validators.required);
  claimSubmissionPhoto = new FormControl(null, Validators.required);
  claimSubmissionPhoto2 = new FormControl(null);
  healthReferencePhoto = new FormControl(null, Validators.required);
  healthReferencePhoto2 = new FormControl(null);
  receiptPhoto = new FormControl(null, Validators.required);
  receiptPhoto2 = new FormControl(null);
  receiptPhoto3 = new FormControl(null);
  receiptPhoto4 = new FormControl(null);
  receiptPhoto5 = new FormControl(null);
  receiptPhoto6 = new FormControl(null);
  receiptPhoto7 = new FormControl(null);
  receiptPhoto8 = new FormControl(null);
  receiptPhoto9 = new FormControl(null);
  receiptPhoto10 = new FormControl(null);
  identityCardPhoto = new FormControl(null, Validators.required);
  identityCardPhoto2 = new FormControl(null);
  costDetailPhoto = new FormControl(null, Validators.required);
  costDetailPhoto2 = new FormControl(null);
  costDetailPhoto3 = new FormControl(null);
  costDetailPhoto4 = new FormControl(null);
  costDetailPhoto5 = new FormControl(null);
  costDetailPhoto6 = new FormControl(null);
  costDetailPhoto7 = new FormControl(null);
  costDetailPhoto8 = new FormControl(null);
  costDetailPhoto9 = new FormControl(null);
  costDetailPhoto10 = new FormControl(null);
  costDetailPhoto11 = new FormControl(null);
  costDetailPhoto12 = new FormControl(null);
  costDetailPhoto13 = new FormControl(null);
  costDetailPhoto14 = new FormControl(null);
  costDetailPhoto15 = new FormControl(null);
  labCheckupPhoto = new FormControl(null);
  labCheckupPhoto2 = new FormControl(null);
  labCheckupPhoto3 = new FormControl(null);
  labCheckupPhoto4 = new FormControl(null);
  labCheckupPhoto5 = new FormControl(null);
  disclaimer = new FormControl(false);

  private imageSrc: string = '';
  dateFormat: any;
  newFileName: any;
  target: any;
  targetSubmission: string = "";

  entryErrors: string[] = [];
  exitErrors: string[] = [];
  submitErrors: string[] = [];

  loadingButton: boolean = false;

  hospitals: Hospital[] = [];
  insuredNames: Insured[] = [];
  policies: any[] = [];

  filteredHospitals: Observable<Hospital[]>;
  filteredNames: Observable<Insured[]>;

  eclaim: ClaimRequest = new ClaimRequest();


  claimSubmissionFileProgress: FileProgress = { loading: false, progress: 0 };
  claimSubmissionFileProgress2: FileProgress = { loading: false, progress: 0 };
  healthReferenceFileProgress: FileProgress = { loading: false, progress: 0 };
  healthReferenceFileProgress2: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress2: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress3: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress4: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress5: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress6: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress7: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress8: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress9: FileProgress = { loading: false, progress: 0 };
  receiptFileProgress10: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress: FileProgress = { loading: false, progress: 0 };
  identityCardFileProgress2: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress2: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress3: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress4: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress5: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress6: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress7: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress8: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress9: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress10: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress11: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress12: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress13: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress14: FileProgress = { loading: false, progress: 0 };
  costDetailFileProgress15: FileProgress = { loading: false, progress: 0 };
  labCheckupFileProgress: FileProgress = { loading: false, progress: 0 };
  labCheckupFileProgress2: FileProgress = { loading: false, progress: 0 };
  labCheckupFileProgress3: FileProgress = { loading: false, progress: 0 };
  labCheckupFileProgress4: FileProgress = { loading: false, progress: 0 };
  labCheckupFileProgress5: FileProgress = { loading: false, progress: 0 };

  private subscriptionClaimSubmission: Subscription;
  private subscriptionClaimSubmission2: Subscription;
  private subscriptionHealthReference: Subscription;
  private subscriptionHealthReference2: Subscription;
  private subscriptionReceipt: Subscription;
  private subscriptionReceipt2: Subscription;
  private subscriptionReceipt3: Subscription;
  private subscriptionReceipt4: Subscription;
  private subscriptionReceipt5: Subscription;
  private subscriptionReceipt6: Subscription;
  private subscriptionReceipt7: Subscription;
  private subscriptionReceipt8: Subscription;
  private subscriptionReceipt9: Subscription;
  private subscriptionReceipt10: Subscription;
  private subscriptionidentityCard: Subscription;
  private subscriptionidentityCard2: Subscription;
  private subscriptionCostDetailFile: Subscription;
  private subscriptionCostDetailFile2: Subscription;
  private subscriptionCostDetailFile3: Subscription;
  private subscriptionCostDetailFile4: Subscription;
  private subscriptionCostDetailFile5: Subscription;
  private subscriptionCostDetailFile6: Subscription;
  private subscriptionCostDetailFile7: Subscription;
  private subscriptionCostDetailFile8: Subscription;
  private subscriptionCostDetailFile9: Subscription;
  private subscriptionCostDetailFile10: Subscription;
  private subscriptionCostDetailFile11: Subscription;
  private subscriptionCostDetailFile12: Subscription;
  private subscriptionCostDetailFile13: Subscription;
  private subscriptionCostDetailFile14: Subscription;
  private subscriptionCostDetailFile15: Subscription;
  private subscriptionLabCheckupFile: Subscription;
  private subscriptionLabCheckupFile2: Subscription;
  private subscriptionLabCheckupFile3: Subscription;
  private subscriptionLabCheckupFile4: Subscription;
  private subscriptionLabCheckupFile5: Subscription;

  @ViewChild('eclaimForm', { static: false }) eclaimForm: NgForm;

  constructor(
    private cnf: Config,
    private route: ActivatedRoute,
    private claim: ClaimService,
    private file: FileService,
    private router: Router,
    private translate: TranslateService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: FormBuilder,
    private cpSweetalertService: CpSweetalertService,
    private localService: LocalService,
    private auditTrail: AuditTrail
  ) {
    this.route.queryParams.subscribe(params => {
      this.preview = params['preview'];
      this.token = params['token'];
    });
    this.formGroup = this.formBuilder.group({
      insuredName: this.insuredName,
      policyId: this.policyId,
      cost: this.cost,
      entryDate: this.entryDate,
      exitDate: this.exitDate,
      disclaimerBox: this.disclaimerBox,
      hospitalId: this.hospitalId,
      claimSubmissionPhoto: this.claimSubmissionPhoto,
      claimSubmissionPhoto2: this.claimSubmissionPhoto2,
      healthReferencePhoto: this.healthReferencePhoto,
      healthReferencePhoto2: this.healthReferencePhoto2,
      receiptPhoto: this.receiptPhoto,
      receiptPhoto2: this.receiptPhoto2,
      receiptPhoto3: this.receiptPhoto3,
      receiptPhoto4: this.receiptPhoto4,
      receiptPhoto5: this.receiptPhoto5,
      receiptPhoto6: this.receiptPhoto6,
      receiptPhoto7: this.receiptPhoto7,
      receiptPhoto8: this.receiptPhoto8,
      receiptPhoto9: this.receiptPhoto9,
      receiptPhoto10: this.receiptPhoto10,
      identityCardPhoto: this.identityCardPhoto,
      identityCardPhoto2: this.identityCardPhoto2,
      costDetailPhoto: this.costDetailPhoto,
      costDetailPhoto2: this.costDetailPhoto2,
      costDetailPhoto3: this.costDetailPhoto3,
      costDetailPhoto4: this.costDetailPhoto4,
      costDetailPhoto5: this.costDetailPhoto5,
      costDetailPhoto6: this.costDetailPhoto6,
      costDetailPhoto7: this.costDetailPhoto7,
      costDetailPhoto8: this.costDetailPhoto8,
      costDetailPhoto9: this.costDetailPhoto9,
      costDetailPhoto10: this.costDetailPhoto10,
      costDetailPhoto11: this.costDetailPhoto11,
      costDetailPhoto12: this.costDetailPhoto12,
      costDetailPhoto13: this.costDetailPhoto13,
      costDetailPhoto14: this.costDetailPhoto14,
      costDetailPhoto15: this.costDetailPhoto15,
      labCheckupPhoto: this.labCheckupPhoto,
      labCheckupPhoto2: this.labCheckupPhoto2,
      labCheckupPhoto3: this.labCheckupPhoto3,
      labCheckupPhoto4: this.labCheckupPhoto4,
      labCheckupPhoto5: this.labCheckupPhoto5,
      disclaimer: this.disclaimer
    });
  }

  ngOnInit() {
    this.auditTrail.saveLog('Claim', 'Open');
    // watch if hospitalId change
    this.filteredHospitals = this.hospitalId.valueChanges.pipe(
      startWith(''),
      map(value => (value ? (typeof value === 'string' ? value : value.name) : null)),
      map(name => (name ? this._hospitalFilter(name) : this.hospitals.slice()))
    );

    // watch if insuredName change
    this.filteredNames = this.insuredName.valueChanges.pipe(
      startWith(''),
      map(value => (value ? (typeof value === 'string' ? value : value.name) : null)),
      map(name => (name ? this._nameFilter(name) : this.insuredNames.slice()))
    );
    this.userId = this.localService.getJsonValue('userid');




    this.insuredNames.length = 0;

    // load hospitalList
    this.claim.getHospitals().subscribe(

      (res: any) => {
        const response: RESTResponse<Hospital[]> = res.body;
        if (response.success) {
          this.hospitals = response.data;
        } else {
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        }
      },
      err => {
        console.log(err);
        this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
      }
    );

    // load nomor polis based on userId
    if (this.userId != null) {
      this.claim.getPolicies(this.userId).subscribe(
        (res: any) => {
          const response: RESTResponse<PolicyList[]> = res.body;
          if (response.success) {
            for (let entry of response.data) {
              let newPolicy = {
                value: entry.policyCode,
                label: entry.policyCode
              };
              this.policies.push(newPolicy);
            }
          } else {
            console.error(response.message);
          }
        },
        err => {
          console.error(err);
        }
      );

      // get customer based on userId
      this.claim.getCustomer(this.userId).subscribe(
        (res: any) => {
          const response: RESTResponse<Customer> = res.body;
          if (response.success) {
            this.customerId = response.data.customerId;
          } else {
            console.error(response.message);
          }
        },
        err => {
          console.error(err);
        }
      );
    }

    this.maxWarningMessage = 'Tidak boleh lebih dari ' + this.maxSubmit + ' hari';
    this.nameWarningMessage = 'Nama harus sesuai dengan data';
    this.notEarlyWarningMessage = 'Tidak boleh lebih kecil dari tanggal masuk';

    this.fileUploadSuccessMessage = 'Upload File Berhasil.';
  }

  public show: boolean = false;
  public buttonName: any = 'Show';
  public lblClm: String = 'Add Another';

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = "Hide";
      this.lblClm = "Remove";
    }
    else {
      this.buttonName = "Show";
      this.lblClm = "Add Another";
    }

  }

  public show2: boolean = false;
  public buttonName2: any = 'Show';
  public lblHlt: String = 'Add Another';

  toggle2() {
    this.show2 = !this.show2;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show2) {
      this.buttonName2 = "Hide";
      this.lblHlt = "Remove";
    }
    else {
      this.buttonName2 = "Show";
      this.lblHlt = "Add Another";
    }
  }

  public show3: boolean = false;
  public buttonName3: any = 'Show';
  public lblRcp: String = 'Add Another';

  toggle3() {
    this.show3 = !this.show3;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show3) {
      this.buttonName3 = "Hide";
      this.lblRcp = "Remove";
    }
    else {
      this.buttonName3 = "Show";
      this.lblRcp = "Add Another";
    }
  }

  public show4: boolean = false;
  public buttonName4: any = 'Show';
  public lblRcp2: String = 'Add Another';

  toggle4() {
    this.show4 = !this.show4;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show4) {
      this.buttonName4 = "Hide";
      this.lblRcp2 = "Remove";
    }
    else {
      this.buttonName4 = "Show";
      this.lblRcp2 = "Add Another";
    }
  }

  public show5: boolean = false;
  public buttonName5: any = 'Show';
  public lblRcp3: String = 'Add Another';

  toggle5() {
    this.show5 = !this.show5;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show5) {
      this.buttonName5 = "Hide";
      this.lblRcp3 = "Remove";
    }
    else {
      this.buttonName5 = "Show";
      this.lblRcp3 = "Add Another";
    }
  }

  public show6: boolean = false;
  public buttonName6: any = 'Show';
  public lblRcp4: String = 'Add Another';

  toggle6() {
    this.show6 = !this.show6;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show6) {
      this.buttonName6 = "Hide";
      this.lblRcp4 = "Remove";
    }
    else {
      this.buttonName6 = "Show";
      this.lblRcp4 = "Add Another";
    }
  }

  public show7: boolean = false;
  public buttonName7: any = 'Show';
  public lblRcp5: String = 'Add Another';

  toggle7() {
    this.show7 = !this.show7;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show7) {
      this.buttonName7 = "Hide";
      this.lblRcp5 = "Remove";
    }
    else {
      this.buttonName7 = "Show";
      this.lblRcp5 = "Add Another";
    }
  }

  public show8: boolean = false;
  public buttonName8: any = 'Show';
  public lblRcp6: String = 'Add Another';

  toggle8() {
    this.show8 = !this.show8;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show8) {
      this.buttonName8 = "Hide";
      this.lblRcp6 = "Remove";
    }
    else {
      this.buttonName8 = "Show";
      this.lblRcp6 = "Add Another";
    }
  }

  public show9: boolean = false;
  public buttonName9: any = 'Show';
  public lblRcp7: String = 'Add Another';

  toggle9() {
    this.show9 = !this.show9;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show9) {
      this.buttonName9 = "Hide";
      this.lblRcp7 = "Remove";
    }
    else {
      this.buttonName9 = "Show";
      this.lblRcp7 = "Add Another";
    }
  }

  public show10: boolean = false;
  public buttonName10: any = 'Show';
  public lblRcp8: String = 'Add Another';

  toggle10() {
    this.show10 = !this.show10;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show10) {
      this.buttonName10 = "Hide";
      this.lblRcp8 = "Remove";
    }
    else {
      this.buttonName10 = "Show";
      this.lblRcp8 = "Add Another";
    }
  }

  public show11: boolean = false;
  public buttonName11: any = 'Show';
  public lblRcp9: String = 'Add Another';

  toggle11() {
    this.show11 = !this.show11;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show11) {
      this.buttonName11 = "Hide";
      this.lblRcp9 = "Remove";
    }
    else {
      this.buttonName11 = "Show";
      this.lblRcp9 = "Add Another";
    }
  }

  public show12: boolean = false;
  public buttonName12: any = 'Show';
  public lblId: String = 'Add Another';

  toggle12() {
    this.show12 = !this.show12;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show12) {
      this.buttonName12 = "Hide";
      this.lblId = "Remove";
    }
    else {
      this.buttonName12 = "Show";
      this.lblId = "Add Another";
    }
  }

  public show13: boolean = false;
  public buttonName13: any = 'Show';
  public lblCost: String = 'Add Another';

  toggle13() {
    this.show13 = !this.show13;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show13) {
      this.buttonName13 = "Hide";
      this.lblCost = "Remove";
    }
    else {
      this.buttonName13 = "Show";
      this.lblCost = "Add Another";
    }
  }

  public show14: boolean = false;
  public buttonName14: any = 'Show';
  public lblCost2: String = 'Add Another';

  toggle14() {
    this.show14 = !this.show14;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show14) {
      this.buttonName14 = "Hide";
      this.lblCost2 = "Remove";
    }
    else {
      this.buttonName14 = "Show";
      this.lblCost2 = "Add Another";
    }
  }

  public show15: boolean = false;
  public buttonName15: any = 'Show';
  public lblCost3: String = 'Add Another';

  toggle15() {
    this.show15 = !this.show15;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show15) {
      this.buttonName15 = "Hide";
      this.lblCost3 = "Remove";
    }
    else {
      this.buttonName15 = "Show";
      this.lblCost3 = "Add Another";
    }
  }

  public show16: boolean = false;
  public buttonName16: any = 'Show';
  public lblCost4: String = 'Add Another';

  toggle16() {
    this.show16 = !this.show16;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show16) {
      this.buttonName16 = "Hide";
      this.lblCost4 = "Remove";
    }
    else {
      this.buttonName16 = "Show";
      this.lblCost4 = "Add Another";
    }
  }

  public show17: boolean = false;
  public buttonName17: any = 'Show';
  public lblCost5: String = 'Add Another';

  toggle17() {
    this.show17 = !this.show17;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show17) {
      this.buttonName17 = "Hide";
      this.lblCost5 = "Remove";
    }
    else {
      this.buttonName17 = "Show";
      this.lblCost5 = "Add Another";
    }
  }

  public show18: boolean = false;
  public buttonName18: any = 'Show';
  public lblCost6: String = 'Add Another';

  toggle18() {
    this.show18 = !this.show18;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show18) {
      this.buttonName18 = "Hide";
      this.lblCost6 = "Remove";
    }
    else {
      this.buttonName18 = "Show";
      this.lblCost6 = "Add Another";
    }
  }

  public show19: boolean = false;
  public buttonName19: any = 'Show';
  public lblCost7: String = 'Add Another';

  toggle19() {
    this.show19 = !this.show19;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show19) {
      this.buttonName19 = "Hide";
      this.lblCost7 = "Remove";
    }
    else {
      this.buttonName19 = "Show";
      this.lblCost7 = "Add Another";
    }
  }

  public show20: boolean = false;
  public buttonName20: any = 'Show';
  public lblCost8: String = 'Add Another';

  toggle20() {
    this.show20 = !this.show20;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show20) {
      this.buttonName20 = "Hide";
      this.lblCost8 = "Remove";
    }
    else {
      this.buttonName20 = "Show";
      this.lblCost8 = "Add Another";
    }
  }

  public show21: boolean = false;
  public buttonName21: any = 'Show';
  public lblCost9: String = 'Add Another';

  toggle21() {
    this.show21 = !this.show21;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show21) {
      this.buttonName21 = "Hide";
      this.lblCost9 = "Remove";
    }
    else {
      this.buttonName21 = "Show";
      this.lblCost9 = "Add Another";
    }
  }

  public show22: boolean = false;
  public buttonName22: any = 'Show';
  public lblCost10: String = 'Add Another';

  toggle22() {
    this.show22 = !this.show22;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show22) {
      this.buttonName22 = "Hide";
      this.lblCost10 = "Remove";
    }
    else {
      this.buttonName22 = "Show";
      this.lblCost10 = "Add Another";
    }
  }

  public show23: boolean = false;
  public buttonName23: any = 'Show';
  public lblCost11: String = 'Add Another';

  toggle23() {
    this.show23 = !this.show23;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show23) {
      this.buttonName23 = "Hide";
      this.lblCost11 = "Remove";
    }
    else {
      this.buttonName23 = "Show";
      this.lblCost11 = "Add Another";
    }
  }

  public show24: boolean = false;
  public buttonName24: any = 'Show';
  public lblCost12: String = 'Add Another';

  toggle24() {
    this.show24 = !this.show24;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show24) {
      this.buttonName24 = "Hide";
      this.lblCost12 = "Remove";
    }
    else {
      this.buttonName24 = "Show";
      this.lblCost12 = "Add Another";
    }
  }

  public show25: boolean = false;
  public buttonName25: any = 'Show';
  public lblCost13: String = 'Add Another';

  toggle25() {
    this.show25 = !this.show25;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show25) {
      this.buttonName25 = "Hide";
      this.lblCost13 = "Remove";
    }
    else {
      this.buttonName25 = "Show";
      this.lblCost13 = "Add Another";
    }
  }

  public show26: boolean = false;
  public buttonName26: any = 'Show';
  public lblCost14: String = 'Add Another';

  toggle26() {
    this.show26 = !this.show26;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show26) {
      this.buttonName26 = "Hide";
      this.lblCost14 = "Remove";
    }
    else {
      this.buttonName26 = "Show";
      this.lblCost14 = "Add Another";
    }
  }

  public show27: boolean = false;
  public buttonName27: any = 'Show';
  public lblLab: String = 'Add Another';

  toggle27() {
    this.show27 = !this.show27;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show27) {
      this.buttonName27 = "Hide";
      this.lblLab = "Remove";
    }
    else {
      this.buttonName27 = "Show";
      this.lblLab = "Add Another";
    }
  }

  public show28: boolean = false;
  public buttonName28: any = 'Show';
  public lblLab2: String = 'Add Another';

  toggle28() {
    this.show28 = !this.show28;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show28) {
      this.buttonName28 = "Hide";
      this.lblLab2 = "Remove";
    }
    else {
      this.buttonName28 = "Show";
      this.lblLab2 = "Add Another";
    }
  }

  public show29: boolean = false;
  public buttonName29: any = 'Show';
  public lblLab3: String = 'Add Another';

  toggle29() {
    this.show29 = !this.show29;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show29) {
      this.buttonName29 = "Hide";
      this.lblLab3 = "Remove";
    }
    else {
      this.buttonName29 = "Show";
      this.lblLab3 = "Add Another";
    }
  }

  public show30: boolean = false;
  public buttonName30: any = 'Show';
  public lblLab4: String = 'Add Another';

  toggle30() {
    this.show30 = !this.show30;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show30) {
      this.buttonName30 = "Hide";
      this.lblLab4 = "Remove";
    }
    else {
      this.buttonName30 = "Show";
      this.lblLab4 = "Add Another";
    }
  }




  /**
   * @desc filter hospital by name
   * @param name
   */
  private _hospitalFilter(name: string): Hospital[] {
    const filterValue = name.toLowerCase();
    return this.hospitals.filter(option => option.hospitalName.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @desc filter insuredName by name
   * @param name
   */
  private _nameFilter(name: string): Insured[] {
    const filterValue = name.toLowerCase();
    return this.insuredNames.filter(option => option.fullName.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @desc event when policy change
   * @param event
   */
  policyChange(event: any) {
    this.claim.getInsuredCustomers(event.value).subscribe((resp: any) => {
      const responses: RESTResponse<Insured[]> = resp.body;
      if (responses.success) {
        this.insuredNames.length = 0;
        for (const insured of responses.data) {
          let found = false;
          if (this.insuredNames != null && this.insuredNames.length > 1) {
            for (let i = 0; i < this.insuredNames.length; i++) {
              if (this.insuredNames[i].fullName === insured.fullName) {
                found = true;
                break;
              }
            }
            if (!found) {
              this.insuredNames.push(insured);
            }
          } else {
            this.insuredNames.push(insured);
          }
        }
      }
    });
  }

  getOptionText(option) {
    if (option != null) {
      return option.hospitalName;
    }
    return '';
  }

  getOptions(option) {
    if (option != null) {
      return option.fullName;
    }
    return '';
  }

  /**
   * @desc event when datepicker get closed
   * @param event
   */
  onExitDate(event): void {
    let exit = moment(this.exitDate.value).startOf('day');
    if (this.entryDate.value) {
      let entry = moment(this.entryDate.value).startOf('day');
      if (entry > exit) {
        this.cpSweetalertService.show(this.notEarlyWarningMessage, 'warning', false);
      }
    }
  }

  /**
   * @desc event when datepicker get picked
   * @param event
   */
  onEntryDate(event): void {
    let entry = moment(this.entryDate.value).startOf('day');
    if (this.exitDate.value) {
      let exit = moment(this.exitDate.value).startOf('day');
      if (entry > exit) {
        this.cpSweetalertService.show(this.notEarlyWarningMessage, 'warning', false);
      }
    }
  }

  // /**
  //  * @desc event when date is submit
  //  * @param event
  //  */
  // onSubmitDate(event): void {
  //   let today = moment(new Date()).startOf('day');
  //   let submit = moment(this.submitDate.value).startOf('day');
  //   if (this.entryDate.value) {
  //     let entry = moment(this.entryDate.value).startOf('day');
  //     if (entry > submit) {
  //       this.cpSweetalertService.show(this.notEarlyWarningMessage, 'warning', false);
  //     } else {
  //       let diff = moment.duration(entry.diff(submit)).asDays();
  //       console.log(diff);
  //       if (Math.abs(diff) > this.maxSubmit) {
  //         this.cpSweetalertService.show(this.maxWarningMessage, 'warning', false);
  //       }
  //     }
  //   } else {
  //     if (submit > today) {
  //       this.cpSweetalertService.show(this.notEarlyWarningMessage, 'warning', false);
  //     }
  //   }
  // }

  /**
   * @desc create custom random char for file-name
   */
  makecustom(length) {
    var a = new Date();

    var uuid = require("uuid");
    var id = btoa(a + '').slice(-7, -2) + '' + btoa(uuid.v4()).slice(-7, -2);

    return id;
  }

  /**
   * @desc create new file with random name
   * @param event File event
   */
  makeCustomFileName(event) {
    const target = event.target.files[0];
    const date = new Date();
    const ext = target.type.split('/').pop();
    const dateFormat =
      date.getFullYear() +
      '' +
      (date.getMonth() + 1) +
      '' +
      date.getDate() +
      '' +
      date.getHours() +
      '' +
      date.getMinutes();
    const newFileName = this.makecustom(8) + dateFormat + '.' + ext;
    const file = new File([target], newFileName, { type: target.type });
    return file;
  }


  /**
   * @desc event when input file submission get selected
   * @param event
   */
  onClaimSubmissionFileSelect(event) {
    this.targetSubmission = "onClaimSubmissionFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }


  }

  /**
   * @desc event when input file submission get selected
   * @param event
   */
  onClaimSubmissionFileSelect2(event: any) {
    this.targetSubmission = "onClaimSubmissionFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input file HealthReferenceFile got selected
   * @param event
   */
  onHealthReferenceFileSelect(event) {
    this.targetSubmission = "onHealthReferenceFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input file HealthReferenceFile got selected
   * @param event
   */
  onHealthReferenceFileSelect2(event) {
    this.targetSubmission = "onHealthReferenceFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input file onReceiptFileSelect got selected
   * @param event
   */
  onReceiptFileSelect(event) {
    this.targetSubmission = "onReceiptFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }

  }

  onReceiptFileSelect2(event) {
    this.targetSubmission = "onReceiptFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect3(event) {
    this.targetSubmission = "onReceiptFileSelect3";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect4(event) {
    this.targetSubmission = "onReceiptFileSelect4";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect5(event) {
    this.targetSubmission = "onReceiptFileSelect5";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect6(event) {
    this.targetSubmission = "onReceiptFileSelect6";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect7(event) {
    this.targetSubmission = "onReceiptFileSelect7";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect8(event) {
    this.targetSubmission = "onReceiptFileSelect8";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect9(event) {
    this.targetSubmission = "onReceiptFileSelect9";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onReceiptFileSelect10(event) {
    this.targetSubmission = "onReceiptFileSelect10";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input file IdentityCardFile got selected
   * @param event
   */
  onIdentityCardFileSelect(event) {
    this.targetSubmission = "onIdentityCardFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }

  }

  onIdentityCardFileSelect2(event) {
    this.targetSubmission = "onIdentityCardFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input fie CostDetail got selected
   * @param event
   */
  onCostDetailFileSelect(event: any) {
    this.targetSubmission = "onCostDetailFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect2(event: any) {
    this.targetSubmission = "onCostDetailFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect3(event: any) {
    this.targetSubmission = "onCostDetailFileSelect3";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect4(event: any) {
    this.targetSubmission = "onCostDetailFileSelect4";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect5(event: any) {
    this.targetSubmission = "onCostDetailFileSelect5";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect6(event: any) {
    this.targetSubmission = "onCostDetailFileSelect6";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect7(event: any) {
    this.targetSubmission = "onCostDetailFileSelect7";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect8(event: any) {
    this.targetSubmission = "onCostDetailFileSelect8";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect9(event: any) {
    this.targetSubmission = "onCostDetailFileSelect9";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect10(event: any) {
    this.targetSubmission = "onCostDetailFileSelect10";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect11(event: any) {
    this.targetSubmission = "onCostDetailFileSelect11";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect12(event: any) {
    this.targetSubmission = "onCostDetailFileSelect12";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect13(event: any) {
    this.targetSubmission = "onCostDetailFileSelect13";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect14(event: any) {
    this.targetSubmission = "onCostDetailFileSelect14";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  onCostDetailFileSelect15(event: any) {
    this.targetSubmission = "onCostDetailFileSelect15";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc event when input file CheckupFile got selected
   * @param event
   */
  onLabCheckupFileSelect(event) {
    this.targetSubmission = "onLabCheckupFileSelect";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }

  }

  onLabCheckupFileSelect2(event) {
    this.targetSubmission = "onLabCheckupFileSelect2";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }
  onLabCheckupFileSelect3(event) {
    this.targetSubmission = "onLabCheckupFileSelect3";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }
  onLabCheckupFileSelect4(event) {
    this.targetSubmission = "onLabCheckupFileSelect4";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }
  onLabCheckupFileSelect5(event) {
    this.targetSubmission = "onLabCheckupFileSelect5";
    var file2 = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var reader = new FileReader();
    const target = event.target.files[0];
    const ext = target.type.split('/').pop();

    if (target.size < 10485760 && (ext == "jpeg" || ext == "pdf" || ext == "png" || ext == "jpg")) {
      this.target = event.target.files[0];
      this.date = new Date();
      this.ext = this.target.type.split('/').pop();
      this.dateFormat =
        this.date.getFullYear() +
        '' +
        (this.date.getMonth() + 1) +
        '' +
        this.date.getDate() +
        '' +
        this.date.getHours() +
        '' +
        this.date.getMinutes();
      this.newFileName = this.makecustom(8) + this.dateFormat + '.' + this.ext;

      this.target = event.target.files[0];
      reader.onload = this._handleReaderLoaded.bind(this, event);
      reader.readAsDataURL(file2);

    } else {
      this.cpSweetalertService.show('Max upload size 10 mb, File type only image and PDF', 'error', false);
    }
  }

  /**
   * @desc Validate all FORM input
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * @desc Event disclaimer checkbox
   * @param event
   */
  changeCheck(event: any) {
    let count = 0;
    this.isChecked = false;
    Object.keys(this.formGroup.controls).forEach(key => {
      let found = false;
      if (key === 'insuredName') {
        const control = this.formGroup.get(key);
        if (control instanceof FormControl) {
          if (this.insuredNames != null && this.insuredNames.length > 0) {
            for (let i = 0; i < this.insuredNames.length; i++) {
              if (
                this.insuredName.value != null &&
                this.insuredName.value.fullName != null &&
                this.insuredName.value.fullName.length > 0
              ) {
                if (this.insuredNames[i].fullName.toLowerCase === this.insuredName.value.fullName.toLowerCase) {
                  found = true;
                  break;
                }
              }
            }
          }
        }
        if (!found) {
          // if (control.errors) {
          //   this.errors = [control.errors];
          // }
          // this.errors.push({'match': true});
          control.setErrors({ match: true });
        }
      }

      const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
      if (controlErrors != null) {
        //this.formGroup.get(key).updateValueAndValidity();
        Object.keys(controlErrors).forEach(keyError => {
          count = count + 1;
        });
      }
    });

    if (count === 0) {
      this.isChecked = true;
      this.disclaimer.setValue(true);
    } else {
      this.isChecked = false;
      this.disclaimer.setValue(false);
      this.validateAllFormFields(this.formGroup);
      this.cpSweetalertService.show('_eclaim_not_complete_warning', 'warning');
    }
  }


  /**
   * @desc Event disclaimer checkbox
   * @param event
   */
  changeCost() {
    if (this.cost.value == 0) {
      this.cpSweetalertService.show('Total Cost cannot be 0', 'warning');
    }

  }


  /**
   * @desc Event on submit
   */
  submita() {
    if (this.isChecked) {
      this.eclaim.cost = +this.cost.value;
      let found = false;
      if (this.insuredNames != null && this.insuredNames.length > 1) {
        for (var i = 0; i < this.insuredNames.length; i++) {
          if (this.insuredNames[i].fullName.toLowerCase === this.insuredName.value.fullName.toLowerCase) {
            found = true;
            break;
          }
        }
        if (found) {
          this.eclaim.insuredName = this.insuredName.value.fullName;
        } else {
          Object.keys(this.formGroup.controls).forEach(field => {
            if (field === 'insuredName') {
              const control = this.formGroup.get(field);
              if (control instanceof FormControl) {
                control.setErrors(['match']);
                control.markAsTouched({ onlySelf: true });
              } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
              }
            }
          });
        }
      }
      this.eclaim.entryDate = moment(this.entryDate.value)
        .local()
        .toDate()
        .getTime();
      this.eclaim.exitDate = moment(this.exitDate.value)
        .local()
        .toDate()
        .getTime();
      this.eclaim.customerId = +this.customerId;
      this.eclaim.policyId = this.policyId.value;
      this.eclaim.hospitalId = this.hospitalId.value.hospitalName;

      if (this.customerId != null && this.customerId != '') {
        this.cnf.showRecaptchaBadge();
        this.loadingButton = true;
        this.reCaptchaV3Service.execute(this.cnf.googleRecaptchaKeyv3, 'eclaim', captchaToken => {
          let req = new RESTRequest<ClaimRequest>();
          req.appid = this.cnf.appid;
          req.appkey = this.cnf.appkey;
          req.data = this.eclaim;
          req.apptype = 'web';
          req.token = captchaToken;
          this.claim.submitEClaim(req).subscribe(
            (res: any) => {
              const response = res.body;
              if (response.success) {
                this.cpSweetalertService.show('_eclaim_submitted_warning', 'success');
                this.resetForm();
                this.auditTrail.saveLog('Claim', 'Submit Success#' + this.eclaim.policyId);
              }
            },
            err => {
              if (err.error instanceof Error) {
                console.error(err.error.message);
                this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
                this.auditTrail.saveLog('Claim', 'Submit Failed#' + this.eclaim.policyId + " #" + err.error.message);
                this.router.navigate(['claim/history']);
              } else {
                console.error(err);
                this.auditTrail.saveLog('Claim', 'Submit Failed#' + this.eclaim.policyId + " #" + err);
                this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
              }
              return;
            },
            () => {
              this.loadingButton = false;
            }
          );
        });
      } else {
        this.cpSweetalertService.show('Customer ID Anda tidak ditemukan, silahkan lakukan reload halaman ini dan coba masukan data kembali', 'warning');
      }
    } else {
      this.cpSweetalertService.show('_eclaim_disclaimer_warning', 'warning');
    }
  }

  /**
   * @desc Reset Form
   */
  resetForm() {
    this.eclaimForm.resetForm();
    document.getElementById('lblLabCheckup').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblLabCheckup2') != null) {
      document.getElementById('lblLabCheckup2').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblLabCheckup3') != null) {
      document.getElementById('lblLabCheckup3').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblLabCheckup4') != null) {
      document.getElementById('lblLabCheckup4').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblLabCheckup5') != null) {
      document.getElementById('lblLabCheckup5').innerText = this.translate.instant('_choose_file') + '...';
    }

    document.getElementById('lblCostDetail').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblCostDetail2') != null) {
      document.getElementById('lblCostDetail2').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail3') != null) {
      document.getElementById('lblCostDetail3').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail4') != null) {
      document.getElementById('lblCostDetail4').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail5') != null) {
      document.getElementById('lblCostDetail5').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail6') != null) {
      document.getElementById('lblCostDetail6').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail7') != null) {
      document.getElementById('lblCostDetail7').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail8') != null) {
      document.getElementById('lblCostDetail8').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail9') != null) {
      document.getElementById('lblCostDetail9').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail10') != null) {
      document.getElementById('lblCostDetail10').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail11') != null) {
      document.getElementById('lblCostDetail11').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail12') != null) {
      document.getElementById('lblCostDetail12').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail13') != null) {
      document.getElementById('lblCostDetail13').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail14') != null) {
      document.getElementById('lblCostDetail14').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblCostDetail15') != null) {
      document.getElementById('lblCostDetail15').innerText = this.translate.instant('_choose_file') + '...';
    }

    document.getElementById('lblClaimSubmission').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblClaimSubmission2') != null) {
      document.getElementById('lblClaimSubmission2').innerText = this.translate.instant('_choose_file') + '...';
    }

    document.getElementById('lblHealthReference').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblHealthReference2') != null) {
      document.getElementById('lblHealthReference2').innerText = this.translate.instant('_choose_file') + '...';
    }

    document.getElementById('lblReceipt').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblReceipt2') != null) {
      document.getElementById('lblReceipt2').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt3') != null) {
      document.getElementById('lblReceipt3').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt4') != null) {
      document.getElementById('lblReceipt4').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt5') != null) {
      document.getElementById('lblReceipt5').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt6') != null) {
      document.getElementById('lblReceipt6').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt7') != null) {
      document.getElementById('lblReceipt7').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt8') != null) {
      document.getElementById('lblReceipt8').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt9') != null) {
      document.getElementById('lblReceipt9').innerText = this.translate.instant('_choose_file') + '...';
    }
    if (document.getElementById('lblReceipt10') != null) {
      document.getElementById('lblReceipt10').innerText = this.translate.instant('_choose_file') + '...';
    }

    document.getElementById('lblIdentityCard').innerText = this.translate.instant('_choose_file') + '...';
    if (document.getElementById('lblIdentityCard2') != null) {
      document.getElementById('lblIdentityCard2').innerText = this.translate.instant('_choose_file') + '...';
    }

  }

  _handleReaderLoaded(e, event) {
    let reader2 = event.target;
    this.imageSrc = reader2.result;

    this.fileClass.fileDownloadUri = this.imageSrc;
    this.fileClass.fileName = this.newFileName;
    this.fileClass.fileType = this.target.type.split('/').pop();
    this.fileClass.size = this.target.size;
    this.fileClass.userId = this.userId;
    this.file.uploadWithNProgress(this.fileClass).subscribe(
      (res: any) => {
        const response = res.body;
        if (response.success) {
          if (this.targetSubmission == "onClaimSubmissionFileSelect") {
            const submissionPhoto = response.data.fileDownloadUri;
            this.eclaim.claimSubmissionPhoto = submissionPhoto;
            document.getElementById('lblClaimSubmission').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onClaimSubmissionFileSelect2") {
            const submissionPhoto = response.data.fileDownloadUri;
            this.eclaim.claimSubmissionPhoto2 = submissionPhoto;
            document.getElementById('lblClaimSubmission2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onHealthReferenceFileSelect") {
            const referencePhoto = response.data.fileDownloadUri;
            this.eclaim.healthReferencePhoto = referencePhoto;
            document.getElementById('lblHealthReference').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onHealthReferenceFileSelect2") {
            const referencePhoto2 = response.data.fileDownloadUri;
            this.eclaim.healthReferencePhoto2 = referencePhoto2;
            document.getElementById('lblHealthReference2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect") {
            const receiptPhoto = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto = receiptPhoto;
            document.getElementById('lblReceipt').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect2") {
            const receiptPhoto2 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto2 = receiptPhoto2;
            document.getElementById('lblReceipt2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect3") {
            const receiptPhoto3 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto3 = receiptPhoto3;
            document.getElementById('lblReceipt3').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect4") {
            const receiptPhoto4 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto4 = receiptPhoto4;
            document.getElementById('lblReceipt4').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect5") {
            const receiptPhoto5 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto5 = receiptPhoto5;
            document.getElementById('lblReceipt5').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect6") {
            const receiptPhoto6 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto6 = receiptPhoto6;
            document.getElementById('lblReceipt6').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect7") {
            const receiptPhoto7 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto7 = receiptPhoto7;
            document.getElementById('lblReceipt7').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect8") {
            const receiptPhoto8 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto8 = receiptPhoto8;
            document.getElementById('lblReceipt8').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect9") {
            const receiptPhoto9 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto9 = receiptPhoto9;
            document.getElementById('lblReceipt9').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onReceiptFileSelect10") {
            const receiptPhoto10 = response.data.fileDownloadUri;
            this.eclaim.receiptPhoto10 = receiptPhoto10;
            document.getElementById('lblReceipt10').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onIdentityCardFileSelect") {
            const identityCardPhoto = response.data.fileDownloadUri;
            this.eclaim.identityCardPhoto = identityCardPhoto;
            document.getElementById('lblIdentityCard').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onIdentityCardFileSelect2") {
            const identityCardPhoto2 = response.data.fileDownloadUri;
            this.eclaim.identityCardPhoto2 = identityCardPhoto2;
            document.getElementById('lblIdentityCard2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect") {
            const costDetailPhoto = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto = costDetailPhoto;
            document.getElementById('lblCostDetail').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect2") {
            const costDetailPhoto2 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto2 = costDetailPhoto2;
            document.getElementById('lblCostDetail2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect3") {
            const costDetailPhoto3 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto3 = costDetailPhoto3;
            document.getElementById('lblCostDetail3').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect4") {
            const costDetailPhoto4 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto4 = costDetailPhoto4;
            document.getElementById('lblCostDetail4').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect5") {
            const costDetailPhoto5 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto5 = costDetailPhoto5;
            document.getElementById('lblCostDetail5').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect6") {
            const costDetailPhoto6 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto6 = costDetailPhoto6;
            document.getElementById('lblCostDetail6').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect7") {
            const costDetailPhoto7 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto7 = costDetailPhoto7;
            document.getElementById('lblCostDetail7').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect8") {
            const costDetailPhoto8 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto8 = costDetailPhoto8;
            document.getElementById('lblCostDetail8').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect9") {
            const costDetailPhoto9 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto9 = costDetailPhoto9;
            document.getElementById('lblCostDetail9').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect10") {
            const costDetailPhoto10 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto10 = costDetailPhoto10;
            document.getElementById('lblCostDetail10').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect11") {
            const costDetailPhoto11 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto11 = costDetailPhoto11;
            document.getElementById('lblCostDetail11').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect12") {
            const costDetailPhoto12 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto12 = costDetailPhoto12;
            document.getElementById('lblCostDetail12').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect13") {
            const costDetailPhoto13 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto13 = costDetailPhoto13;
            document.getElementById('lblCostDetail13').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect14") {
            const costDetailPhoto14 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto14 = costDetailPhoto14;
            document.getElementById('lblCostDetail14').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onCostDetailFileSelect15") {
            const costDetailPhoto15 = response.data.fileDownloadUri;
            this.eclaim.costDetailPhoto15 = costDetailPhoto15;
            document.getElementById('lblCostDetail15').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onLabCheckupFileSelect") {
            const labCheckupPhoto = response.data.fileDownloadUri;
            this.eclaim.labCheckupPhoto = labCheckupPhoto;
            document.getElementById('lblLabCheckup').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onLabCheckupFileSelect2") {
            const labCheckupPhoto2 = response.data.fileDownloadUri;
            this.eclaim.labCheckupPhoto2 = labCheckupPhoto2;
            document.getElementById('lblLabCheckup2').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onLabCheckupFileSelect3") {
            const labCheckupPhoto3 = response.data.fileDownloadUri;
            this.eclaim.labCheckupPhoto3 = labCheckupPhoto3;
            document.getElementById('lblLabCheckup3').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onLabCheckupFileSelect4") {
            const labCheckupPhoto4 = response.data.fileDownloadUri;
            this.eclaim.labCheckupPhoto4 = labCheckupPhoto4;
            document.getElementById('lblLabCheckup4').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          } else if (this.targetSubmission == "onLabCheckupFileSelect5") {
            const labCheckupPhoto5 = response.data.fileDownloadUri;
            this.eclaim.labCheckupPhoto5 = labCheckupPhoto5;
            document.getElementById('lblLabCheckup5').innerText = response.data.fileName;
            this.cpSweetalertService.show(this.fileUploadSuccessMessage, 'success', false);
          }


        }
      }
      , err => {
        if (this.targetSubmission == "onClaimSubmissionFileSelect") {
          console.log(err);
          this.formGroup.get('claimSubmissionPhoto').setValue(null);
          document.getElementById('lblClaimSubmission').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.claimSubmissionPhoto = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onClaimSubmissionFileSelect2") {
          console.log(err);
          this.formGroup.get('claimSubmissionPhoto2').setValue(null);
          document.getElementById('lblClaimSubmission2').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.claimSubmissionPhoto2 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onHealthReferenceFileSelect") {
          console.log(err);
          this.formGroup.get('healthReferencePhoto').setValue(null);
          document.getElementById('lblHealthReference').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.healthReferencePhoto = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onHealthReferenceFileSelect2") {
          console.log(err);
          this.formGroup.get('healthReferencePhoto2').setValue(null);
          document.getElementById('lblHealthReference2').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.healthReferencePhoto2 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect") {
          console.log(err);
          this.formGroup.get('receiptPhoto').setValue(null);
          document.getElementById('lblReceipt').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect2") {
          console.log(err);
          this.formGroup.get('receiptPhoto2').setValue(null);
          document.getElementById('lblReceipt2').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto2 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect3") {
          console.log(err);
          this.formGroup.get('receiptPhoto3').setValue(null);
          document.getElementById('lblReceipt3').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto3 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect4") {
          console.log(err);
          this.formGroup.get('receiptPhoto4').setValue(null);
          document.getElementById('lblReceipt4').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto4 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect5") {
          console.log(err);
          this.formGroup.get('receiptPhoto5').setValue(null);
          document.getElementById('lblReceipt5').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto5 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect6") {
          console.log(err);
          this.formGroup.get('receiptPhoto6').setValue(null);
          document.getElementById('lblReceipt6').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto6 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect7") {
          console.log(err);
          this.formGroup.get('receiptPhoto7').setValue(null);
          document.getElementById('lblReceipt7').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto7 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect8") {
          console.log(err);
          this.formGroup.get('receiptPhoto8').setValue(null);
          document.getElementById('lblReceipt8').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto8 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect9") {
          console.log(err);
          this.formGroup.get('receiptPhoto9').setValue(null);
          document.getElementById('lblReceipt9').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto9 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onReceiptFileSelect10") {
          console.log(err);
          this.formGroup.get('receiptPhoto10').setValue(null);
          document.getElementById('lblReceipt10').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.receiptPhoto10 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onIdentityCardFileSelect") {
          console.log(err);
          this.formGroup.get('identityCardPhoto').setValue(null);
          document.getElementById('lblIdentityCard').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.identityCardPhoto = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onIdentityCardFileSelect2") {
          console.log(err);
          this.formGroup.get('identityCardPhoto2').setValue(null);
          document.getElementById('lblIdentityCard2').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.identityCardPhoto2 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect") {
          console.log(err);
          this.formGroup.get('costDetailPhoto').setValue(null);
          document.getElementById('lblCostDetail').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect2") {
          console.log(err);
          this.formGroup.get('costDetailPhoto2').setValue(null);
          document.getElementById('lblCostDetail2').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto2 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect3") {
          console.log(err);
          this.formGroup.get('costDetailPhoto3').setValue(null);
          document.getElementById('lblCostDetail3').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto3 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect4") {
          console.log(err);
          this.formGroup.get('costDetailPhoto4').setValue(null);
          document.getElementById('lblCostDetail4').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto4 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect5") {
          console.log(err);
          this.formGroup.get('costDetailPhoto5').setValue(null);
          document.getElementById('lblCostDetail5').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto5 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect6") {
          console.log(err);
          this.formGroup.get('costDetailPhoto6').setValue(null);
          document.getElementById('lblCostDetail6').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto6 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect7") {
          console.log(err);
          this.formGroup.get('costDetailPhoto7').setValue(null);
          document.getElementById('lblCostDetail7').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto7 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect8") {
          console.log(err);
          this.formGroup.get('costDetailPhoto8').setValue(null);
          document.getElementById('lblCostDetail8').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto8 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect9") {
          console.log(err);
          this.formGroup.get('costDetailPhoto9').setValue(null);
          document.getElementById('lblCostDetail9').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto9 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect10") {
          console.log(err);
          this.formGroup.get('costDetailPhoto10').setValue(null);
          document.getElementById('lblCostDetail10').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto10 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect11") {
          console.log(err);
          this.formGroup.get('costDetailPhoto11').setValue(null);
          document.getElementById('lblCostDetail11').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto11 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect12") {
          console.log(err);
          this.formGroup.get('costDetailPhoto12').setValue(null);
          document.getElementById('lblCostDetail12').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto12 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect13") {
          console.log(err);
          this.formGroup.get('costDetailPhoto13').setValue(null);
          document.getElementById('lblCostDetail13').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto13 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect14") {
          console.log(err);
          this.formGroup.get('costDetailPhoto14').setValue(null);
          document.getElementById('lblCostDetail14').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto14 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        } else if (this.targetSubmission == "onCostDetailFileSelect15") {
          console.log(err);
          this.formGroup.get('costDetailPhoto15').setValue(null);
          document.getElementById('lblCostDetail15').innerText = this.translate.instant('_choose_file') + '...';
          this.eclaim.costDetailPhoto15 = null;
          this.cpSweetalertService.show('_eclaim_error_occurred', 'error');
        }
      },
      () => {
      }
    );
  }

  // tslint:disable-next-line: use-lifecycle-interface
  /**
   * @desc On Destroy
   */
  ngOnDestroy() {
    this.cnf.removeRecaptchaBadge();
    if (this.subscriptionClaimSubmission) {
      this.subscriptionClaimSubmission.unsubscribe();
    }
    if (this.subscriptionClaimSubmission2) {
      this.subscriptionClaimSubmission2.unsubscribe();
    }
    if (this.subscriptionHealthReference) {
      this.subscriptionHealthReference.unsubscribe();
    }
    if (this.subscriptionHealthReference2) {
      this.subscriptionHealthReference2.unsubscribe();
    }
    if (this.subscriptionReceipt) {
      this.subscriptionReceipt.unsubscribe();
    }
    if (this.subscriptionReceipt2) {
      this.subscriptionReceipt2.unsubscribe();
    }
    if (this.subscriptionReceipt3) {
      this.subscriptionReceipt3.unsubscribe();
    }
    if (this.subscriptionReceipt4) {
      this.subscriptionReceipt4.unsubscribe();
    }
    if (this.subscriptionReceipt5) {
      this.subscriptionReceipt5.unsubscribe();
    }
    if (this.subscriptionReceipt5) {
      this.subscriptionReceipt5.unsubscribe();
    }
    if (this.subscriptionReceipt5) {
      this.subscriptionReceipt5.unsubscribe();
    }
    if (this.subscriptionReceipt6) {
      this.subscriptionReceipt6.unsubscribe();
    }
    if (this.subscriptionReceipt6) {
      this.subscriptionReceipt6.unsubscribe();
    }
    if (this.subscriptionReceipt7) {
      this.subscriptionReceipt7.unsubscribe();
    }
    if (this.subscriptionReceipt8) {
      this.subscriptionReceipt8.unsubscribe();
    }
    if (this.subscriptionReceipt9) {
      this.subscriptionReceipt9.unsubscribe();
    }
    if (this.subscriptionReceipt10) {
      this.subscriptionReceipt10.unsubscribe();
    }
    if (this.subscriptionidentityCard) {
      this.subscriptionidentityCard.unsubscribe();
    }
    if (this.subscriptionidentityCard2) {
      this.subscriptionidentityCard2.unsubscribe();
    }
    if (this.subscriptionCostDetailFile) {
      this.subscriptionCostDetailFile.unsubscribe();
    }
    if (this.subscriptionCostDetailFile2) {
      this.subscriptionCostDetailFile2.unsubscribe();
    }
    if (this.subscriptionCostDetailFile3) {
      this.subscriptionCostDetailFile3.unsubscribe();
    }
    if (this.subscriptionCostDetailFile4) {
      this.subscriptionCostDetailFile4.unsubscribe();
    }
    if (this.subscriptionCostDetailFile5) {
      this.subscriptionCostDetailFile5.unsubscribe();
    }
    if (this.subscriptionCostDetailFile6) {
      this.subscriptionCostDetailFile6.unsubscribe();
    }
    if (this.subscriptionCostDetailFile7) {
      this.subscriptionCostDetailFile7.unsubscribe();
    }
    if (this.subscriptionCostDetailFile8) {
      this.subscriptionCostDetailFile8.unsubscribe();
    }
    if (this.subscriptionCostDetailFile9) {
      this.subscriptionCostDetailFile9.unsubscribe();
    }
    if (this.subscriptionCostDetailFile10) {
      this.subscriptionCostDetailFile10.unsubscribe();
    }
    if (this.subscriptionCostDetailFile11) {
      this.subscriptionCostDetailFile11.unsubscribe();
    }
    if (this.subscriptionCostDetailFile12) {
      this.subscriptionCostDetailFile12.unsubscribe();
    }
    if (this.subscriptionCostDetailFile13) {
      this.subscriptionCostDetailFile13.unsubscribe();
    }
    if (this.subscriptionCostDetailFile14) {
      this.subscriptionCostDetailFile14.unsubscribe();
    }
    if (this.subscriptionCostDetailFile15) {
      this.subscriptionCostDetailFile15.unsubscribe();
    }
    if (this.subscriptionLabCheckupFile) {
      this.subscriptionLabCheckupFile.unsubscribe();
    }
    if (this.subscriptionLabCheckupFile2) {
      this.subscriptionLabCheckupFile2.unsubscribe();
    }
    if (this.subscriptionLabCheckupFile3) {
      this.subscriptionLabCheckupFile3.unsubscribe();
    }
    if (this.subscriptionLabCheckupFile4) {
      this.subscriptionLabCheckupFile4.unsubscribe();
    }
    if (this.subscriptionLabCheckupFile5) {
      this.subscriptionLabCheckupFile5.unsubscribe();
    }
  }
}
