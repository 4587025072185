export class ClaimRequest {
    public customerId: Number;
    public insuredName: string;
    public policyId: String;
    public entryDate: Number;
    public exitDate: Number;
    public submitDate: Number;
    public cost: Number;
    public hospitalId: String;
    public claimSubmissionPhoto: string;
    public claimSubmissionPhoto2: string;
    public healthReferencePhoto: string;
    public healthReferencePhoto2: string;
    public receiptPhoto: string;
    public receiptPhoto2: string;
    public receiptPhoto3: string;
    public receiptPhoto4: string;
    public receiptPhoto5: string;
    public receiptPhoto6: string;
    public receiptPhoto7: string;
    public receiptPhoto8: string;
    public receiptPhoto9: string;
    public receiptPhoto10: string;
    public identityCardPhoto: string;
    public identityCardPhoto2: string;
    public costDetailPhoto: string;
    public costDetailPhoto2: string;
    public costDetailPhoto3: string;
    public costDetailPhoto4: string;
    public costDetailPhoto5: string;
    public costDetailPhoto6: string;
    public costDetailPhoto7: string;
    public costDetailPhoto8: string;
    public costDetailPhoto9: string;
    public costDetailPhoto10: string;
    public costDetailPhoto11: string;
    public costDetailPhoto12: string;
    public costDetailPhoto13: string;
    public costDetailPhoto14: string;
    public costDetailPhoto15: string;
    public labCheckupPhoto: string;
    public labCheckupPhoto2: string;
    public labCheckupPhoto3: string;
    public labCheckupPhoto4: string;
    public labCheckupPhoto5: string;
}
