import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../config';
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuditTrail } from '../../audit-trail';

@Component({
  selector: 'menu-dashboard-vertical',
  templateUrl: './menu-dashboard-vertical.component.html',
  styleUrls: ['./menu-dashboard-vertical.component.scss']
})
export class MenuDashboardVerticalComponent implements OnInit {

  constructor(private router: Router, private cnf: Config, private auditTrail: AuditTrail, private http: HttpClient) {
  }

  ngOnInit() {
  }

  doLogout() {
    this.auditTrail.saveLog('Home', 'Logout');
    setTimeout(() => {
      let params = new HttpParams();
      params = params.append('appId', 'test');
      params = params.append('appKey', 'on');
      params = params.append('token', this.cnf.token);
      let paramsObject = this.cnf.convertPostData(params);
      var url = this.cnf.URLWSNonPublicArea + '/user_frontend/logout';
      this.http.post(url, paramsObject).subscribe(res => {
        this.cnf.clearLocalStorage();
        this.router.navigate(['/']);
        location.reload();
      });
    }, 100);
  }

}
