import { environment } from './../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpParams } from '../../node_modules/@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { LocalService } from './local.service';
import * as process from './ZW52aXJvbm1lbnQ=.json';
const cf = JSON.parse(atob(process.process));

@Injectable()
export class Config {
  constructor(private http: HttpClient, private localService: LocalService, @Inject(DOCUMENT) document) { }

  URLWS: string = environment.apiEndpoint;
  URLWSNonPublicArea: string = environment.apiEndpoint;
  URLMicroServices = environment.serviceEndpoint;
  URLClaimService = environment.serviceEndpoint + '/claim';
  URLFileService = environment.fileEndpoint + '/file';
  URLCustomerService = environment.serviceEndpoint + '/customer';
  URLPolicyService = environment.serviceEndpoint + '/policy';
  URLCpService = environment.cpEndpoint + '/cp';
  lang: string =
    this.localService.getJsonValue('lang') && this.localService.getJsonValue('lang') != 'null' ? this.localService.getJsonValue('lang') : 'id';
  token: string = this.localService.getJsonValue('token') ? this.localService.getJsonValue('token') : '';
  userId: string = this.localService.getJsonValue('userid') ? this.localService.getJsonValue('userid') : '';
  appid = cf.ene;
  appkey = cf.eni;
  apptype = cf.ent;
  googleRecaptchaKey: string = cf.eng;
  googleRecaptchaKeyv3: string = cf.enj;
  maxWithdrawal = 10000000;
  minWithdrawal = 1000001;
  maxClaim = 4000000;
  maxSubmit = 180;
  prefixTitle = 'Chubb Customer Corner ';
  postfixTitle = '';
  enm = cf.enm;
  enl = cf.enl;
  ena = cf.ena;
  enp = cf.enp;
  yek = cf;

  decryptData(encryptData:any) {
    let decodeString = atob(encryptData);

    let key = CryptoJS.enc.Utf8.parse(cf.enc);
    let decrypted = CryptoJS.AES.decrypt(decodeString, key, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted;
  }

  encryptData(decryptData:any) {
    let key = CryptoJS.enc.Utf8.parse(cf.enc);
    let encrypted = CryptoJS.AES.encrypt(decryptData, key, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    let encryptData = btoa(encrypted);

    return encryptData;
  }

  md5(plainText: any) {
    let output = CryptoJS.MD5(plainText).toString();

    return output;
  }

  htmlEntities(str:any) {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
  }

  strip_tags(_html, args:any) {
    var _tags = [],
      _tag = '';
    for (var _a = 1; _a < args.length; _a++) {
      _tag = args[_a].replace(/<|>/g, '').trim();
      if (args[_a].length > 0) _tags.push(_tag, '/' + _tag);
    }

    if (!(typeof _html == 'string') && !(_html instanceof String)) return '';
    else if (_tags.length == 0) return _html.replace(/<(\s*\/?)[^>]+>/g, '');
    else {
      var _re = new RegExp('<(?!(' + _tags.join('|') + ')s*/?)[^>]+>', 'g');
      return _html.replace(_re, '');
    }
  }

  getAttoken() {
    let params = new HttpParams();
    params = params.append('appId', this.appid);
    params = params.append('appKey', this.appkey);
    params = params.append('apptype', this.apptype);

    let paramsObject = this.convertPostData(params);

    return this.http
      .post(this.URLWSNonPublicArea + '/user_frontend/getATToken', paramsObject)
      .pipe(map((response: Response) => response));
  }

  generateAttoken(projectName:any, datas:any) {
    let decrypt = this.decryptData(datas);
    let attoken = this.encryptData(projectName + '|' + decrypt);

    return attoken;
  }

  clearLocalStorage() {
    let array = {
      hideBrowserInformation: this.localService.getJsonValue('hideBrowserInformation'),
      userGuide: this.localService.getJsonValue('userGuide')
    };
    let ackImg = localStorage.getItem('img')
    localStorage.clear();
    for (let i in array) {
      if (array[i]) {
        this.localService.setJsonValue(i, array[i]);
      }
    }
    localStorage.setItem('img', ackImg);
  }

  convertPostData(params, data = {}) {
    let paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});

    paramsObject = Object.assign({ data: data }, paramsObject);

    return paramsObject;
  }

  removeRecaptchaBadge() {
    let elements: any = document.getElementsByClassName('grecaptcha-badge');
    if (elements.length > 0) {
      elements[0].style.display = 'none';
    }
  }

  showRecaptchaBadge() {
    let elements: any = document.getElementsByClassName('grecaptcha-badge');
    if (elements.length > 0) {
      elements[0].style.display = 'block';
    }
  }
}
